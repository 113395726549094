





















































import { Component, PropSync, Vue } from 'vue-property-decorator';

import PauseTrackingViewModel from
  '@/vue-app/view-models/components/goals-dashboard/linked-goals/pause-tracking-view-model';

@Component({ name: 'PauseTrackingDialog' })
export default class PauseTrackingDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  view_model = Vue.observable(
    new PauseTrackingViewModel(this),
  );

  cancel() {
    this.synced_is_open = false;
  }
}
