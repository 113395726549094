
























































































































































































































import {
  Component, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import LoadingDataCard from '@/vue-app/components/home-dashboard/LoadingDataCard.vue';
import LinkedCustomInvestorGoalCardViewModel from '@/vue-app/view-models/components/goals-dashboard/linked-custom-investor-goal-card-view-model';

// Domain
import {
  CustomInvestorGoalEntity,
} from '@/modules/flagship/custom-investor-goal/domain/entities/custom-investor-goal-entity';

@Component({
  name: 'LinkedCustomInvestorGoalCard',
  components: {
    LoadingDataCard,
    LinkedCustomInvestorGoalMenuCard: () => import('@/vue-app/components/goals-dashboard/linked-goals/LinkedCustomInvestorGoalMenuCard.vue'),
    LinkedCustomInvestorGoalBodyCard: () => import('@/vue-app/components/goals-dashboard/linked-goals/LinkedCustomInvestorGoalBodyCard.vue'),
    LinkedCustomInvestorGoalBudgetBodyCard: () => import('@/vue-app/components/goals-dashboard/linked-goals/LinkedCustomInvestorGoalBudgetBodyCard.vue'),
    EditNameCustomizedGoalModal: () => import('@/vue-app/components/goals-dashboard/edit-goals/EditNameCustomizedGoalModal.vue'),
    EditCustomGoalPlanDialog: () => import('@/vue-app/components/goals-dashboard/edit-plan-goals/edit-custom-goal-plan/EditCustomGoalPlanDialog.vue'),
    ChooseActionEditGoalDialog: () => import('@/vue-app/components/goals-dashboard/edit-goals/ChooseActionEditGoalDialog.vue'),
    DeleteCustomInvestorGoalModal: () => import('@/vue-app/components/goals-dashboard/delete-goals/DeleteCustomInvestorGoalModal.vue'),
    DetailsCustomModal: () => import('./details-goals/DetailsCustomModal.vue'),
    RecurringDepositDialog: () => import('@/vue-app/components/allianz-dashboard/deposits/recurring_deposits/RecurringDepositDialog.vue'),
    LinkStrategyModerateWealthDialog: () => import('@/vue-app/components/strategy-moderate-wealth/link-goal/LinkStrategyModerateWealthDialog.vue'),
    GoalTrackingTag: () => import('@/vue-app/components/goals-dashboard/GoalTrackingTag.vue'),
    DepositAccountInfo: () => import('@/vue-app/components/kuspit-dashboard/deposits/DepositsAccountInfo.vue'),
    WealthContributionDialog: () => import('@/vue-app/components/goals-dashboard/contribute-goals/wealth/WealthContributionDialog.vue'),
    ContractingStatusModerateDialog: () => import('@/vue-app/components/strategy-moderate-wealth/contracting-status-moderate/ContractingStatusModerateDialog.vue'),
    LinkStatusModerateDialog: () => import('@/vue-app/components/strategy-moderate-wealth/link-status-moderate/LinkStatusModerateDialog.vue'),
    CustomProgressBar: () => import('@/vue-app/components/goals-dashboard/details-goals/CustomProgressBar.vue'),
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class LinkedCustomInvestorGoalCard extends Vue {
  @PropSync('customInvestorGoal', { type: Object, required: true })
  custom_investor_goal!: CustomInvestorGoalEntity;

  view_model = Vue.observable(new LinkedCustomInvestorGoalCardViewModel(this));

  @Watch('view_model.manage_tracking_investor_goal_state.show_pause_tracking_reason_dialog')
  handleShowPauseTrackingReasonDialog(show: boolean) {
    if (show) {
      this.view_model.handleShowPauseTrackingReasonDialog();
    }
  }

  @Watch('view_model.manage_tracking_investor_goal_state.show_tracking_resumed_dialog')
  handleShowTrackingResumedDialog(show: boolean) {
    if (show) {
      this.view_model.handleShowTrackingResumedDialog();
    }
  }

  created() {
    this.view_model.initialize(this.custom_investor_goal);
  }
}
