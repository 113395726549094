import TYPES from '@/types';

import Vue from 'vue';

// Application
import AccessMoreStrategiesStateManagerService
  from '@/modules/my-investment/allianz/access-more-strategies/application/services/access-more-strategies-state-manager-service';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class BucketShortTimeCreatedSuccessfullyViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.ACCESS_MORE_STRATEGIES_STATE_MANAGER_SERVICE)
  private readonly manager_service!: AccessMoreStrategiesStateManagerService;

  readonly i18n_namespace = 'components.flagship.flagship-goals.create-goal.bucket.short-time-created-successfully';

  readonly view!: Vue;

  more_strategies_state = this.manager_service.getAccessMoreStrategiesState();

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  public constructor(view: Vue) {
    this.view = view;
  }

  get is_bucket_moderate() {
    return this.more_strategies_state.strategy.label === 'SWSMOD';
  }

  goToDashboardGoals = () => {
    window.location.href = '/goals-dashboard';
  }

  goToWealth = () => {
    window.location.href = '/sowos-wealth';
  }
}
