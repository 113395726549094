import TYPES from '@/types';

import Vue from 'vue';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class BucketInsufficientBalanceViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.flagship.flagship-goals.create-goal.bucket.insufficient-balance';

  readonly view!: Vue;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  public constructor(view: Vue) {
    this.view = view;
  }

  contributeToMyAccount = () => {
    this.view.$emit('contributeToMyAccount');
  }

  back = () => {
    this.view.$emit('prevStep');
  }
}
