import TYPES from '../../types';

// Domain
import {
  InvestorGoalPauseTrackingReasonDto,
} from '../../domain/dto/investor-goal-pause-tracking-reason-dto';
import {
  InvestorGoalPauseTrackingReasonRepository,
} from '../../domain/repositories/investor-goal-pause-tracking-reason-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CreateInvestorGoalPauseTrackingReasonCommand
  extends Command<InvestorGoalPauseTrackingReasonDto> {
  @Inject(TYPES.INVESTOR_GOAL_PAUSE_TRACKING_REASON_REPOSITORY)
  private readonly repository!: InvestorGoalPauseTrackingReasonRepository;

  internalExecute(payload: InvestorGoalPauseTrackingReasonDto):
    Promise<void> {
    return this.repository.create(payload);
  }
}
