import TYPES from '@/modules/shared/types';

// Domain
import { GoalTrackingRepository } from '../../domain/repositories/goal-tracking-repository';
import { GoalTrackingEntity } from '../../domain/entities/goal-tracking-entity';
import { SetInvestorGoalTrackingDto } from '../../domain/dtos/set-investor-goal-tracking-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class GoalTrackingHttpRepository implements GoalTrackingRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/investor-goal/goal-tracking/{investor_goal_id}/`;

  get(investor_goal_id: string): Promise<GoalTrackingEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint.replace('{investor_goal_id}', investor_goal_id))
        .then((response) => {
          response.json()
            .then(({ success, data, error }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(error));
              }
            });
        })
        .catch(reject);
    });
  }

  setTracking(payload: SetInvestorGoalTrackingDto): Promise<void> {
    return new Promise((resolve, reject) => {
      const { investor_goal_id } = payload;
      this.http_connector.execute(
        this.endpoint.replace('{investor_goal_id}', investor_goal_id),
        'PATCH',
        { tracking_enabled: payload.tracking_enabled },
      )
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => {
                reject(new Error(message));
              },
            );
          }
        })
        .catch(reject);
    });
  }
}
