import Vue from 'vue';
import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';
import { maxValueRule, minValueRule, requiredRule } from '@/vue-app/utils/form-rules';
import { parseCurrencyToNumber } from '@/vue-app/utils/parse-currency-to-number';

// Application
import GetCustomInvestorGoalCalculatorQuery
  from '@/modules/flagship/custom-investor-goal-calculator/application/queries/get-custom-investor-goal-calculator-query';
import CalculateCustomGoalMaximumMonthsQuery
  from '@/modules/flagship/custom-investor-goal-calculator/application/queries/calculate-custom-goal-maximum-months-query';

// Domain
import {
  CreateInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/create-investor-goal-state-manager';
import {
  InvestmentProductsStateManager,
} from '@/modules/flagship/catalogs/investment-products/domain/state/investment-products-state-manager';
import { CustomInvestorGoalCalculatorDto }
  from '@/modules/flagship/custom-investor-goal-calculator/domain/dtos/custom-investor-goal-calculator-dto';
import {
  CustomInvestorGoalCalculatorEntity,
} from '@/modules/flagship/custom-investor-goal-calculator/domain/entities/custom-investor-goal-calculator-entity';
import {
  CustomGoalMaximumMonthsCalculationDto,
} from '@/modules/flagship/custom-investor-goal-calculator/domain/dtos/custom-goal-maximum-months-dto';
import {
  EmergencyFundInvestorGoalCalculatorDto,
} from '@/modules/flagship/emergency-fund-investor-goal-calculator/domain/dtos/emergency-fund-investor-goal-calculator-dto';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class CustomContributionsViewModel {
  @Inject(TYPES.CALCULATE_CUSTOM_GOAL_MAXIMUM_MONTHS_QUERY)
  private readonly calculate_custom_goal_maximum_months_query!:
    CalculateCustomGoalMaximumMonthsQuery;

  @Inject(TYPES.GET_CUSTOM_INVESTOR_GOAL_CALCULATOR_QUERY)
  private readonly get_custom_investor_goal_calculator_query!:
    GetCustomInvestorGoalCalculatorQuery;

  @Inject(TYPES.INVESTMENT_PRODUCTS_STATE_MANAGER)
  private readonly investment_products_state_manager!: InvestmentProductsStateManager;

  @Inject(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
  readonly create_investor_goal_state_manager!: CreateInvestorGoalStateManager;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.flagship.flagship-goals.create-goal.custom.contributions';

  readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  investor_goal_state = this.create_investor_goal_state_manager.state;

  timer?: NodeJS.Timer;

  min_months_to_reach_goal = 1;

  max_months_to_reach_goal = 11;

  max_contribution = 0;

  calculated_maximum_months = 0;

  is_valid_form = true;

  is_loading = false;

  show_wants_years = false;

  input_rules = {
    contribution: [requiredRule,
      (value: string) => value && minValueRule(value.replace(/[^0-9.-]/g, ''), '$1.00 MXN'),
      (value: string) => maxValueRule(
        value && value.replace(/[^0-9.-]/g, ''),
        `${this.max_contribution} MXN`,
        this.max_contribution,
      ),
    ],
  };

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async () => {
    await this.calculateMaximumMonths();
    await this.calculateMaximumMonthlyAmount();
    await this.setInitialValues();
  }

  get you_must_save() {
    return (this.investor_goal_state.is_defined_by_contribution)
      ? this.investor_goal_state.custom_investor_goal.months_to_reach_goal
      : this.investor_goal_state.custom_investor_goal.contribution;
  }

  get is_continue_btn_disabled() {
    return !this.is_valid_form || this.is_loading || this.investor_goal_state.is_loading;
  }

  get btn_years_text() {
    return this.translate(this.investor_goal_state.wants_years ? 'less_than_one_year' : 'more_than_one_year');
  }

  getCustomClassOpacity = (is_option_by_time: boolean) => {
    if (!this.investor_goal_state.is_defined_by_contribution && !is_option_by_time) return 'opacity';
    if (this.investor_goal_state.is_defined_by_contribution && is_option_by_time) return 'opacity';
    return '';
  }

  getAmountFormatted(amount: number) {
    return currencyFormat(amount);
  }

  getFixedTimeAdjusted = () => {
    if (!this.investor_goal_state.is_defined_by_contribution) {
      return this.calculateMonths();
    }
    return this.investor_goal_state.custom_investor_goal.months_to_reach_goal;
  }

  setState = () => {
    this.investor_goal_state.investor_goal.fixed_time_adjusted = this.getFixedTimeAdjusted();
    this.investor_goal_state.investor_goal.monthly_required_amount = parseCurrencyToNumber(
      this.investor_goal_state.custom_investor_goal.contribution.toString(),
    );
    this.investor_goal_state.investor_goal.accumulated_amount = this
      .investor_goal_state.custom_investor_goal.accumulated_amount;
  };

  setInitialValues = async () => {
    if (this.investor_goal_state.wants_years) {
      this.setAssociatedProduct('sowos_wealth');
    } else {
      this.setAssociatedProduct('sowos_pocket');
    }
    await this.monthlyPaymentCalculation();
  }

  calculateMonths = () => (this.investor_goal_state.wants_years ? this
    .investor_goal_state.custom_investor_goal.months_to_reach_goal * 12
    : this.investor_goal_state.custom_investor_goal.months_to_reach_goal)

  getDefinedBy = () => (this.investor_goal_state.is_defined_by_contribution
    ? 'contribution' : 'time');

  getMonthlyRequiredAmount = () => (this.investor_goal_state.is_defined_by_contribution
    ? parseCurrencyToNumber(this.investor_goal_state.custom_investor_goal.contribution) : 0);

  setInputs = async (calculation: CustomInvestorGoalCalculatorEntity) => {
    const { custom_investor_goal_calculation } = calculation;
    const {
      accumulated_amount,
      monthly_required_amount,
      fixed_time_adjusted,
      associated_product_id,
    } = custom_investor_goal_calculation;
    if (this.investor_goal_state.is_defined_by_contribution) {
      if (this.investor_goal_state.associated_product_id !== associated_product_id) {
        this.setAssociatedProductById(associated_product_id);
      }
      this.investor_goal_state.custom_investor_goal.months_to_reach_goal = Math
        .ceil(fixed_time_adjusted);
    } else {
      this.investor_goal_state.custom_investor_goal.contribution = Math.ceil(
        monthly_required_amount,
      ).toString();
    }
    this.investor_goal_state.custom_investor_goal.accumulated_amount = Math.ceil(
      accumulated_amount,
    );
  }

  monthlyPaymentCalculation = async () => {
    try {
      this.is_loading = true;
      const months = this.calculateMonths();
      this.investor_goal_state.investor_goal.fixed_time_adjusted = months;
      if (this.is_valid_form) {
        const payload: CustomInvestorGoalCalculatorDto = {
          associated_product_id: this.investor_goal_state.associated_product_id,
          defined_by: this.getDefinedBy(),
          desired_amount: this.investor_goal_state.investor_goal.desired_amount,
          initial_amount: this.investor_goal_state.investor_goal.initial_amount,
          monthly_required_amount: this.getMonthlyRequiredAmount(),
          fixed_time_adjusted: (this.investor_goal_state.is_defined_by_contribution) ? 0 : months,
          set_associated_product: true,
        };

        const calculation = await this
          .get_custom_investor_goal_calculator_query.internalExecute(payload);

        await this.setInputs(calculation);
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_monthly_payment'));
    } finally {
      this.is_loading = false;
    }
  }

  delay = () => {
    this.is_loading = true;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.timer = setTimeout(async () => {
      await this.monthlyPaymentCalculation();
    }, 2000);
  }

  setAssociatedProduct = (product_name: string) => {
    const investment_product = this.investment_products_state_manager.state.items.find(
      (product) => product.name === product_name,
    );
    if (investment_product) {
      this.investor_goal_state.associated_product_id = investment_product.id;
      this.investor_goal_state.associated_product_interest_rate = investment_product.interest_rate;
      this.investor_goal_state.associated_product = investment_product.label;
    }
  }

  setAssociatedProductById = (investment_product_id: string) => {
    const investment_product = this.investment_products_state_manager.state.items.find(
      (product) => product.id === investment_product_id,
    );
    if (investment_product) {
      this.investor_goal_state.associated_product_id = investment_product.id;
      this.investor_goal_state.associated_product_interest_rate = investment_product.interest_rate;
      this.investor_goal_state.associated_product = investment_product.label;
    }
  }

  calculateMaximumMonths = async () => {
    try {
      this.investor_goal_state.is_loading = true;
      if (this.investor_goal_state.investor_goal.initial_amount > 0) {
        const payload: CustomGoalMaximumMonthsCalculationDto = {
          monthly_required_amount: 1,
          initial_amount: this.investor_goal_state.investor_goal.initial_amount,
          desired_amount: this.investor_goal_state.investor_goal.desired_amount,
          interest_rate: this.investor_goal_state.associated_product_interest_rate / 100,
        };
        const calculation = await this
          .calculate_custom_goal_maximum_months_query.internalExecute(payload);
        this.calculated_maximum_months = Math.floor(parseFloat(calculation.maximum_months));
        this.show_wants_years = this.calculated_maximum_months >= 12;
      } else {
        this.show_wants_years = true;
      }
      this.setMaximumTimeValue();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_minimum_accumulated_amount'));
    } finally {
      this.investor_goal_state.is_loading = false;
    }
  }

  setMaximumTimeValue = () => {
    if (this.investor_goal_state.wants_years) {
      this.setAssociatedProduct('sowos_wealth');
      if (this.calculated_maximum_months > 0 && this.calculated_maximum_months < (20 * 12)) {
        this.max_months_to_reach_goal = Math.floor(this.calculated_maximum_months / 12);
      } else {
        this.max_months_to_reach_goal = 20;
      }
    } else {
      this.setAssociatedProduct('sowos_pocket');
      if (this.calculated_maximum_months > 0 && this.calculated_maximum_months <= 11) {
        this.max_months_to_reach_goal = this.calculated_maximum_months;
      } else {
        this.max_months_to_reach_goal = 11;
      }
    }
  }

  calculateMaximumMonthlyAmount = async () => {
    try {
      const pocket_product = this.investment_products_state_manager.state.items.find(
        (product) => product.name === 'sowos_pocket',
      );
      this.investor_goal_state.is_loading = true;
      const payload: EmergencyFundInvestorGoalCalculatorDto = {
        associated_product_id: pocket_product ? pocket_product.id : '',
        defined_by: 'time',
        desired_amount: this.investor_goal_state.investor_goal.desired_amount,
        initial_amount: this.investor_goal_state.investor_goal.initial_amount,
        monthly_required_amount: 1,
        fixed_time_adjusted: 1,
      };
      const calculated_data = await this
        .get_custom_investor_goal_calculator_query.execute(payload);
      this.max_contribution = calculated_data
        .custom_investor_goal_calculation.monthly_required_amount;
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.view.$refs.form.validate();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_monthly_payment'));
    } finally {
      this.investor_goal_state.is_loading = false;
    }
  }

  toggleWantsYears = async () => {
    this.investor_goal_state.custom_investor_goal.months_to_reach_goal = 1;
    this.investor_goal_state.wants_years = !this.investor_goal_state.wants_years;
    this.investor_goal_state.investor_goal.fixed_time_adjusted = this.calculateMonths();
    this.setMaximumTimeValue();
    await this.monthlyPaymentCalculation();
  }

  updateDefinedBy = async () => {
    this.investor_goal_state.custom_investor_goal.defined_by = this.getDefinedBy();
    if (!this.investor_goal_state.is_defined_by_contribution) {
      if (this.investor_goal_state.custom_investor_goal.months_to_reach_goal > 11) {
        this.investor_goal_state.wants_years = true;
        this.investor_goal_state.custom_investor_goal.months_to_reach_goal = Math.floor(
          this.investor_goal_state.custom_investor_goal.months_to_reach_goal / 12,
        );
      } else {
        this.investor_goal_state.wants_years = false;
      }
    }
    await this.monthlyPaymentCalculation();
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = async () => {
    this.investor_goal_state.is_loading = true;
    this.setState();
    this.investor_goal_state.is_loading = false;
    this.view.$emit('nextStep');
  }
}
