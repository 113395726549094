import { Container } from 'inversify-props';
import TYPES from './types';

// infrastructure
import GoalsDashboardVueStateManager from './infrastructure/state/goals-dashboard-progress-vue-state-manager';

// Domain
import { GoalsDashboardProgressStateManager as StateManager } from './domain/state/goals-dashboard-progress-state-manager';

export default function setupGoalsDashboardProgressStatus(container: Container) {
  container
    .bind<StateManager>(TYPES.GOALS_DASHBOARD_PROGRESS_STATE_MANAGER)
    .to(GoalsDashboardVueStateManager)
    .inSingletonScope();
}
