import TYPES from '@/types';

import Vue from 'vue';
import { v4 } from 'uuid';

// Application
import GetPauseTrackingReasonsQuery
  from '@/modules/flagship/catalogs/pause-tracking-reason/application/queries/get-pause-tracking-reasons-query';
import CreateInvestorGoalPauseTrackingReasonCommand
  from '@/modules/flagship/investor-goal-pause-tracking-reason/application/commands/create-investor-goal-pause-tracking-reason-command';

// Domain
import {
  ManageTrackingInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/manage-tracking-investor-goal-state-manager';
import {
  PauseTrackingReasonEntity,
} from '@/modules/flagship/catalogs/pause-tracking-reason/domain/entities/pause-tracking-reason-entity';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class PauseTrackingReasonViewModel {
  @Inject(TYPES.GET_PAUSE_TRACKING_REASONS_QUERY)
  readonly get_pause_tracking_reasons_query!: GetPauseTrackingReasonsQuery;

  @Inject(TYPES.CREATE_INVESTOR_GOAL_PAUSE_TRACKING_REASON_COMMAND)
  readonly create_goal_pause_tracking_reason!: CreateInvestorGoalPauseTrackingReasonCommand;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.MANAGE_TRACKING_INVESTOR_GOAL_STATE_MANAGER)
  readonly manage_tracking_investor_goal_state_manager!: ManageTrackingInvestorGoalStateManager;

  readonly i18n_namespace = 'components.goals-dashboard.linked-goals.pause-tracking-reason';

  readonly view: Vue;

  is_loading = false;

  investor_goal_pause_tracking_reason_id = v4();

  options: Array<PauseTrackingReasonEntity> = [];

  selected_option = '';

  manage_tracking_investor_goal_state = this
    .manage_tracking_investor_goal_state_manager.state;

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get is_disabled() {
    return !this.selected_option;
  }

  sendReason = async () => {
    try {
      this.is_loading = true;
      await this.create_goal_pause_tracking_reason.execute({
        id: this.investor_goal_pause_tracking_reason_id,
        investor_goal_id: this.manage_tracking_investor_goal_state.investor_goal_id,
        pause_tracking_reason_id: this.selected_option,
      });
      this.manage_tracking_investor_goal_state.show_pause_tracking_reason_dialog = false;
    } catch {
      this.message_notifier.showErrorNotification(
        this.translate('errors.send_reason'),
      );
    } finally {
      this.is_loading = false;
    }
  }

  loadPauseTrackingReasons = async () => {
    try {
      this.is_loading = true;
      this.options = await this.get_pause_tracking_reasons_query.execute();
    } catch (error) {
      this.message_notifier.showErrorNotification(
        this.translate('errors.loading_reasons'),
      );
    } finally {
      this.is_loading = false;
    }
  }

  initialize = async () => {
    await this.loadPauseTrackingReasons();
  }
}
