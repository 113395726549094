








import {
  Component, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import { GoalTrackingTagViewModel } from '@/vue-app/view-models/components/goals-dashboard/goal-tracking-tag-view-model';

@Component({
  name: 'GoalTrackingTag',
})
export default class GoalTrackingTag extends Vue {
  @PropSync('percent', { type: Number, required: true })
  synced_percent!: number;

  @PropSync('trackingEnabled', { type: Boolean, required: true })
  tracking_enabled!: boolean;

  goal_tracking_tag_view_model = new GoalTrackingTagViewModel();

  @Watch('synced_percent')
  onPercentChange(synced_percent: number) {
    this.goal_tracking_tag_view_model.setTagProperties(synced_percent, this.tracking_enabled);
  }

  @Watch('tracking_enabled')
  onTrackingEnableChange(tracking_enabled: boolean) {
    this.goal_tracking_tag_view_model.setTagProperties(this.synced_percent, tracking_enabled);
  }

  mounted() {
    this.goal_tracking_tag_view_model.setTagProperties(this.synced_percent, this.tracking_enabled);
  }
}
