import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';
import { getGoalIconPath } from '@/vue-app/utils/goal-icon-path';

// Application
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';
import GetCustomerInvestorGoalBalanceQuery from '@/modules/flagship/customer-investor-goal-balance/application/query/get-customer-investor-goal-balance';
import GetInvestmentProductsQuery from '@/modules/flagship/catalogs/investment-products/application/queries/get-investment-products-query';
import GetSearchByCustomerQuery from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';

// Domain
import { SearchByCustomerDto } from '@/modules/flagship/investor-goal/search-by-customer/domain/dtos/search-by-customer-dto';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

type ArrayGoals = {
  is_active: boolean;
  status: string;
  name: string;
  icon: string;
  alt: string;
  active_amount: number;
  button_text: string;
  icon_path: string;
}

export default class LinkGoalsCardViewModel {
  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.GET_CUSTOMER_INVESTOR_GOAL_BALANCE_QUERY)
  private readonly get_customer_investor_goal_balance_query!: GetCustomerInvestorGoalBalanceQuery;

  @Inject(TYPES.GET_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_investment_products_query!: GetInvestmentProductsQuery;

  @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.kuspit-dashboard.goals.link-goals-card';

  is_loading = true;

  investor_profile_completed = false;

  show_flagship_link_pocket_wizard = false;

  show_modal_distribute_unassigned_balance = false;

  associated_product_id = '';

  associated_product_name = '';

  goals: Array<ArrayGoals> = [];

  has_at_least_one_goal_linked = false;

  unassigned_balance = 0;

  load_state_manager_info = true;

  private search_by_customer_dto: SearchByCustomerDto = {
    reload: true,
    associated_product_id: '',
    is_active: true,
  };

  constructor() {
    this.addPocketLInkedGoalEventListener();
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  getAmountFormatted(amount: number) {
    return currencyFormat(amount);
  }

  getIconMaxWidth = (icon_goal_name: string) => ((icon_goal_name === 'icon-add.svg') ? '35px' : '25px');

  getIconCustomClass = (icon_goal_name: string) => ((icon_goal_name === 'icon-add.svg') ? 'mt-2 custom-uploaded-img' : 'mt-3');

  linkGoals = () => {
    this.show_flagship_link_pocket_wizard = true;
  }

  goToDashboardGoals = () => {
    window.location.href = '/goals-dashboard';
  }

  verifyAction = (is_goal_active: boolean, index: number) => {
    if (is_goal_active) {
      if (this.goals[index].status === 'unassigned_amount') {
        this.show_modal_distribute_unassigned_balance = true;
      } else {
        this.goToDashboardGoals();
      }
    } else {
      this.linkGoals();
    }
  }

  addPocketLInkedGoalEventListener = () => {
    window.addEventListener('pocket.linked.goal', this.reloadGoals);
  }

  loadInvestorProfile = async () => {
    try {
      const investor_profile = await this.get_investor_profile_query.execute();
      if (investor_profile) {
        this.investor_profile_completed = investor_profile.is_completed;
        if (this.investor_profile_completed) {
          await this.loadInvestmentProducts();
          await this.loadSearchByCustomerActiveGoal();
          await this.loadSearchByCustomerInactiveGoals();
          this.has_at_least_one_goal_linked = (this.goals.find(
            (item) => item.is_active,
          ) !== undefined);
          if (this.has_at_least_one_goal_linked) {
            await this.loadCustomerInvestorGoalBalance();
          }
          this.load_state_manager_info = true;
        }
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.obtain_investor_profile'));
    }
  }

  loadCustomerInvestorGoalBalance = async () => {
    try {
      const custom_investor_goal_balance = await this.get_customer_investor_goal_balance_query
        .execute({ investment_product_id: this.associated_product_id });
      if (custom_investor_goal_balance.unassigned_balance) {
        this.unassigned_balance = custom_investor_goal_balance.unassigned_balance;
        this.goals.push({
          is_active: true,
          status: 'unassigned_amount',
          active_amount: this.unassigned_balance,
          name: this.translate('unassigned_amount'),
          icon: 'noun-coins.svg',
          alt: 'alts.unassigned',
          button_text: this.translate('distribute'),
          icon_path: getGoalIconPath('', 'noun-coins.svg', ''),
        });
      }
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_customer_investor_goal_balance'));
      }
    }
  }

  loadInvestmentProducts = async () => {
    try {
      const investment_products = await this.get_investment_products_query.execute();
      const investment_product = (investment_products.find(
        (item) => item.name === 'sowos_pocket',
      ));
      this.search_by_customer_dto.associated_product_id = investment_product!.id;
      this.associated_product_id = investment_product!.id;
      this.associated_product_name = investment_product?.label || '';
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_investment_products_query'));
    }
  }

  loadSearchByCustomerActiveGoal = async () => {
    try {
      this.search_by_customer_dto.is_active = true;
      // eslint-disable-next-line max-len
      const active_goals = await this.get_search_by_customer_query.execute(this.search_by_customer_dto);

      Object.entries(active_goals).forEach((goal) => {
        this.goals.push({
          is_active: goal[1].is_active,
          status: (goal[1].is_active) ? '' : this.translate('unlinked'),
          active_amount: goal[1].active_goal_amount || 0,
          name: (goal[1].custom_investor_goal) ? goal[1].custom_investor_goal.goal_name
            : goal[1].investment_goal_type.label,
          icon: (goal[1].custom_investor_goal)
            ? goal[1].custom_investor_goal.custom_goal_type.icon_name : 'icon-fund.svg',
          alt: (goal[1].custom_investor_goal) ? 'alts.custom' : 'alts.emergency_fund',
          button_text: (goal[1].is_active) ? this.translate('go_to_goals') : this.translate('link_goal'),
          icon_path: getGoalIconPath(
            goal[1].investor_goal_id,
            goal[1].custom_investor_goal?.custom_goal_type?.icon_name || 'icon-fund.svg',
            goal[1].custom_investor_goal?.updated_at || '',
          ),
        });
      });
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_search_by_customer_active_goal'));
      }
    }
  }

  loadSearchByCustomerInactiveGoals = async () => {
    try {
      this.search_by_customer_dto.is_active = false;
      // eslint-disable-next-line max-len
      const inactive_goals = await this.get_search_by_customer_query.execute(this.search_by_customer_dto);

      Object.entries(inactive_goals).forEach((goal) => {
        this.goals.push({
          is_active: goal[1].is_active,
          status: (goal[1].is_active) ? '' : this.translate('unlinked'),
          active_amount: goal[1].active_goal_amount || 0,
          name: (goal[1].custom_investor_goal) ? goal[1].custom_investor_goal.goal_name
            : goal[1].investment_goal_type.label,
          icon: (goal[1].custom_investor_goal)
            ? goal[1].custom_investor_goal.custom_goal_type.icon_name : 'icon-fund.svg',
          alt: (goal[1].custom_investor_goal) ? 'alts.custom' : 'alts.emergency_fund',
          button_text: (goal[1].is_active) ? this.translate('go_to_goals') : this.translate('link_goal'),
          icon_path: getGoalIconPath(
            goal[1].investor_goal_id,
            goal[1].custom_investor_goal?.custom_goal_type?.icon_name || 'icon-fund.svg',
            goal[1].custom_investor_goal?.updated_at || '',
          ),
        });
      });
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_search_by_customer_inactive_goal'));
      }
    }
  }

  reloadGoals = async () => {
    this.load_state_manager_info = false;
    await this.initialize();
  }

  initialize = async () => {
    this.is_loading = true;
    this.goals.length = 0;
    await this.loadInvestorProfile();
    this.is_loading = false;
  }
}
