








































































































































































import { Component, Vue } from 'vue-property-decorator';
import AccountBalanceViewModel
  from '@/vue-app/view-models/allianz-dashboard/account-balance-view-model';

@Component({
  name: 'AccountBalance',
  components: {
    AllianzDeposit: () => import('@/vue-app/components/allianz-dashboard/deposits/AllianzDeposit.vue'),
    AllianzWithdrawal: () => import('@/vue-app/components/allianz-dashboard/withdrawals/AllianzWithdrawal.vue'),
    LoadingDataCard: () => import('@/vue-app/components/home-dashboard/LoadingDataCard.vue'),
    CalendlyModal: () => import('@/vue-app/components/allianz-dashboard/deposits/special_deposits/CalendlyModal.vue'),
    RecurrentContributionAmongLinkedGoalsDialog: () => import('@/vue-app/components/allianz-dashboard/RecurrentContributionAmongLinkedGoalsDialog.vue'),
    RecurringDepositDialog: () => import('@/vue-app/components/allianz-dashboard/deposits/recurring_deposits/RecurringDepositDialog.vue'),
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class AccountBalance extends Vue {
  account_balance_view_model = Vue.observable(new AccountBalanceViewModel());

  created() {
    this.account_balance_view_model.initialize();
  }
}
