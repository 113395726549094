













































































import {
  Component, PropSync, Vue, Watch,
} from 'vue-property-decorator';

import FlagshipLinkGoalsWizardLinkPocketViewModel from '@/vue-app/view-models/components/flagship/flagship-link-goals/flagship-link-goals-wizard-link-pocket-view-model';

@Component({
  name: 'FlagshipLinkGoalsWizardPocket',
  components: {
    StepChooseGoalsPocket: () => import('@/vue-app/components/flagship/flagship-link-goals/steps-pocket/StepChooseGoalsPocket.vue'),
    StepDistributeBalancePocket: () => import('@/vue-app/components/flagship/flagship-link-goals/steps-pocket/StepDistributeBalancePocket.vue'),
    StepScheduleReminderPocket: () => import('@/vue-app/components/flagship/flagship-link-goals/steps-pocket/StepScheduleReminderPocket.vue'),
  },
})
export default class FlagshipLinkGoalsWizardPocket extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  @PropSync('associatedProductId', { type: String, required: true })
  associated_product_id!: string;

  flagship_link_pocket_model = Vue.observable(
    new FlagshipLinkGoalsWizardLinkPocketViewModel(),
  );

  created() {
    this.flagship_link_pocket_model.initialize(this.associated_product_id);
  }

  closeModal() {
    this.synced_is_open = false;
  }

  endProcess() {
    this.$emit('goalLinkedToPocket');
    this.flagship_link_pocket_model.endProcess();
    this.synced_is_open = false;
  }
}
