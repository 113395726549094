import TYPES from '@/types';

import Vue from 'vue';

// Domain
import {
  CreateInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/create-investor-goal-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

type GoalType = {
  id: string;
  title: string;
  description: string;
  icon: string;
  active_class: string;
}

export default class GoalFlowSelectorViewModel {
  @Inject(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
  readonly create_investor_goal_state_manager!: CreateInvestorGoalStateManager;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly view!: Vue;

  readonly i18n_namespace = 'components.flagship.flagship-profiling.create-goals';

  selected_option = '';

  selected_index = -1;

  investor_goal_state = this.create_investor_goal_state_manager.state;

  public constructor(view: Vue) {
    this.view = view;
  }

  get disabled_btn_continue() {
    return !this.selected_option || this.investor_goal_state.is_loading;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  options: Array<GoalType> = [
    {
      id: 'unique',
      title: this.translate('create_goal'),
      description: this.translate('create_goal_description'),
      icon: 'icon-house-white.svg',
      active_class: 'unique',
    },
    {
      id: 'budget',
      title: this.translate('more_strategies'),
      description: this.translate('more_strategies_description'),
      icon: 'icon-fund-white.svg',
      active_class: 'budget',
    },
  ];

  selectOption = (index: number) => {
    if (this.selected_index !== -1) {
      this.options[this.selected_index]
        .icon = this.options[this.selected_index].icon.replace('-active.svg', '-white.svg');
    }
    this.selected_option = this.options[index].id;
    this.selected_index = index;
    this.options[index].icon = this.options[index].icon.replace('-white', '-active');
  }

  initialize = () => {
    if (!this.investor_goal_state.emergency_fund.already_created
      && !this.investor_goal_state.retirement_fund.already_created
      && !this.investor_goal_state.custom_investor_goal.already_created) {
      this.options.push(
        {
          id: 'we_support_you',
          title: this.translate('we_support_you'),
          description: this.translate('we_support_you_description'),
          icon: 'icon-retirement-white.svg',
          active_class: 'we-support-you',
        },
      );
    }
  }
}
