













































import { Component, PropSync, Vue } from 'vue-property-decorator';

import BucketInsufficientBalanceViewModel from
  '@/vue-app/view-models/components/goals-dashboard/create-goal/bucket/bucket-insufficient-balance-view-model';

@Component({ name: 'BucketInsufficientBalance' })
export default class BucketInsufficientBalance extends Vue {
  @PropSync('currentBalance', { type: String, required: true })
  synced_current_balance!: string;

  view_model = Vue.observable(
    new BucketInsufficientBalanceViewModel(this),
  );
}
