























































































































import { Component, Vue } from 'vue-property-decorator';
import RetirementFundVideoViewModel from '@/vue-app/view-models/components/goals-dashboard/create-goal/retirement-fund/retirement-fund-video-view-model';

@Component({
  name: 'RetirementFundVideo',
  components: {
    RetirementFundProjection: () => import('@/vue-app/components/goals-dashboard/create-goal/retirement-fund/RetirementFundProjection.vue'),
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class RetirementFundVideo extends Vue {
  view_model = Vue.observable(
    new RetirementFundVideoViewModel(this),
  );
}
