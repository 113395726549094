


































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import AllianzDepositRecurringDialogViewModel
  from '@/vue-app/view-models/allianz-dashboard/deposits/recurring_deposits/allianz-deposit-recurring-dialog-view-model';
import RecurringDeposit from '@/vue-app/components/allianz-dashboard/deposits/recurring_deposits/RecurringDeposit.vue';
import RecurringDepositConfirmation
  from '@/vue-app/components/allianz-dashboard/deposits/recurring_deposits/RecurringDepositConfirmation.vue';
import RecurringDepositGoalsDistribution
  from '@/vue-app/components/allianz-dashboard/deposits/recurring_deposits/RecurringDepositGoalsDistribution.vue';
import RecurringDepositResult
  from '@/vue-app/components/allianz-dashboard/deposits/recurring_deposits/RecurringDepositResult.vue';

// Domain
import {
  DepositRecurringDto,
} from '@/modules/my-investment/allianz/transaction/domain/dtos/deposit-recurring-dto';
import {
  DistributeRecurrentBalanceDto,
} from '@/modules/flagship/investor-goal/sowos-wealth/distribute-recurrent-balance/domain/dto/distribute-recurrent-balance-dto';

@Component({
  name: 'RecurringDepositDialog',
  components: {
    RecurringDeposit,
    RecurringDepositGoalsDistribution,
    RecurringDepositConfirmation,
    RecurringDepositResult,
    SuspendContributionConfirmation: () => import('@/vue-app/components/allianz-dashboard/deposits/recurring_deposits/SuspendContributionConfirmation.vue'),
  },
})
export default class RecurringDepositDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  edit_recurring_contributions_model = Vue.observable(
    new AllianzDepositRecurringDialogViewModel(this),
  );

  $refs!: {
    recurring_deposit: RecurringDeposit;
    recurring_deposit_confirmation: RecurringDepositConfirmation;
    recurring_deposit_goals_distribution: RecurringDepositGoalsDistribution;
    recurring_deposit_result: RecurringDepositResult;
  }

  updateRecurringDepositInformation(information: DepositRecurringDto) {
    this.$refs.recurring_deposit_confirmation.recurring_confirmation_view_model
      .setDepositInformation(information);
    this.$refs.recurring_deposit_goals_distribution
      .allianz_deposit_recurring_goals_distribution_view_model
      .setDepositAmount(information.new_amount);
    this.edit_recurring_contributions_model.updateRecurringDepositInformation(information);
  }

  showConfirmModal(payload: DistributeRecurrentBalanceDto) {
    this.$refs.recurring_deposit_confirmation.recurring_confirmation_view_model.number = 3;
    this.$refs.recurring_deposit_confirmation
      .recurring_confirmation_view_model.setPayloadToSaveDistribution(payload);
    this.edit_recurring_contributions_model.nextStep();
  }

  async recurringDepositConfirm(accreditation_date: number) {
    this.$refs.recurring_deposit_result.recurring_deposit_result_view_model.setCollectionDay(
      accreditation_date,
    );
    this.edit_recurring_contributions_model.nextStep();
  }

  endProcess() {
    this.$refs.recurring_deposit.deposit_recurring_view_model.cleanForm();
    this.edit_recurring_contributions_model.resetProperties();
    this.synced_is_open = false;
  }

  created() {
    this.edit_recurring_contributions_model.loadInvestmentProducts();
  }
}
