
































































import { Component, Vue } from 'vue-property-decorator';

import GoalFlowSelectorViewModel
  from
  '@/vue-app/view-models/components/goals-dashboard/create-goal/goal-flow-selector-view-model';

@Component({ name: 'GoalFlowSelector' })
export default class GoalFlowSelector extends Vue {
  view_model = Vue.observable(
    new GoalFlowSelectorViewModel(this),
  );

  nextStep = () => {
    this.$emit('goalFlowSelected', this.view_model.selected_option);
  }

  created() {
    this.view_model.initialize();
  }
}
