

























































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import LoadingDataCard from '@/vue-app/components/home-dashboard/LoadingDataCard.vue';
import LinkedEmergencyFundCardViewModel from '@/vue-app/view-models/components/goals-dashboard/linked-emergency-fund-card-view-model';
import DepositAccountInfo
  from '@/vue-app/components/kuspit-dashboard/deposits/DepositsAccountInfo.vue';

@Component({
  name: 'LinkedEmergencyFundCard',
  components: {
    LoadingDataCard,
    DetailsEmergencyFundModal: () => import('./details-goals/DetailsEmergencyFundModal.vue'),
    EditEmergencyFundGoalPlanDialog: () => import('./edit-plan-goals/edit-emergency-fund-goal-plan/EditEmergencyFundGoalPlanDialog.vue'),
    GoalTrackingTag: () => import('@/vue-app/components/goals-dashboard/GoalTrackingTag.vue'),
    DepositAccountInfo,
  },
})
export default class LinkedEmergencyFundCard extends Vue {
  linked_emergency_fund_view_model = Vue.observable(new LinkedEmergencyFundCardViewModel());

  @Watch('linked_emergency_fund_view_model.manage_tracking_investor_goal_state.show_pause_tracking_reason_dialog')
  handleShowPauseTrackingReasonDialog(show: boolean) {
    if (show) {
      this.linked_emergency_fund_view_model.handleShowPauseTrackingReasonDialog();
    }
  }

  @Watch('linked_emergency_fund_view_model.manage_tracking_investor_goal_state.show_tracking_resumed_dialog')
  handleShowTrackingResumedDialog(show: boolean) {
    if (show) {
      this.linked_emergency_fund_view_model.handleShowTrackingResumedDialog();
    }
  }

  @Watch('linked_emergency_fund_view_model.distribute_state.distributed')
  handleDistributeChange(loaded: boolean) {
    if (loaded) {
      this.linked_emergency_fund_view_model.handleDistributeChange();
    }
  }

  created() {
    this.linked_emergency_fund_view_model.initialize();
  }
}
