import TYPES from '../../types';

// Domain
import {
  PauseTrackingReasonRepository,
} from '../../domain/repositories/pause-tracking-reason-repository';
import {
  PauseTrackingReasonEntity,
} from '../../domain/entities/pause-tracking-reason-entity';
import {
  PauseTrackingReasonsStateManager,
} from '../../domain/state/pause-tracking-reasons-state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetPauseTrackingReasonsQuery
  extends Query<Promise<Array<PauseTrackingReasonEntity>>> {
  @Inject(TYPES.PAUSE_TRACKING_REASONS_REPOSITORY)
  private readonly repository!: PauseTrackingReasonRepository;

  @Inject(TYPES.PAUSE_TRACKING_REASONS_STATE_MANAGER)
  private readonly state_manager!: PauseTrackingReasonsStateManager;

  internalExecute():
    Promise<Array<PauseTrackingReasonEntity>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.items);
      } else {
        this.repository.all()
          .then((items) => {
            this.state_manager.patch({ items, loaded: true });
            resolve(items);
          })
          .catch(reject);
      }
    });
  }
}
