





















































import { Component, PropSync, Vue } from 'vue-property-decorator';

import ResumeTrackingViewModel from
  '@/vue-app/view-models/components/goals-dashboard/linked-goals/resume-tracking-view-model';

@Component({ name: 'ResumeTrackingDialog' })
export default class ResumeTrackingDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  view_model = Vue.observable(
    new ResumeTrackingViewModel(this),
  );

  cancel() {
    this.synced_is_open = false;
  }
}
