// Domain
import { GlobalTrackingEntity } from '../entities/global-tracking-entity';

export default class GlobalTrackingState {
  loaded = false;

  item: GlobalTrackingEntity = {
    global_tracking_percent: 0,
    current_global_balance: 0.0,
    expected_global_balance: 0.0,
    total_goals_with_tracking_enabled: 0,
  };
}
