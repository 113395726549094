import { Container } from 'inversify-props';
import TYPES from './types';

// infrastructure
import GoalsDashboardVueStateManager from './infrastructure/state/goals-dashboard-distribute-vue-state-manager';

// Domain
import { GoalsDashboardDistributeStateManager as StateManager } from './domain/state/goals-dashboard-distribute-state-manager';

export default function setupGoalsDashboardDistribute(container: Container) {
  container
    .bind<StateManager>(TYPES.GOALS_DASHBOARD_DISTRIBUTE_STATE_MANAGER)
    .to(GoalsDashboardVueStateManager)
    .inSingletonScope();
}
