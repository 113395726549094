


































































import { Component, PropSync, Vue } from 'vue-property-decorator';

import PauseTrackingReasonViewModel from
  '@/vue-app/view-models/components/goals-dashboard/linked-goals/pause-tracking-reason-view-model';

@Component({ name: 'PauseTrackingReasonDialog' })
export default class PauseTrackingReasonDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  view_model = Vue.observable(
    new PauseTrackingReasonViewModel(this),
  );

  omit() {
    this.synced_is_open = false;
  }

  created() {
    this.view_model.initialize();
  }
}
