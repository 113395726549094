import TYPES from '@/types';
import Vue from 'vue';
import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import GetAssociatedProductQuery from '@/modules/flagship/investor-goal/associated-product/application/queries/get-associated-product-query';
import {
  GetAllianzAccountQuery,
} from '@/modules/my-investment/allianz/allianz-account/application/queries';
import {
  SearchAllianzAccountReportQuery,
} from '@/modules/my-investment/allianz/allianz-account-report/application/queries';
import GetCustomerInvestorGoalBalanceQuery
  from '@/modules/flagship/customer-investor-goal-balance/application/query/get-customer-investor-goal-balance';
import GetSearchByCustomerQuery
  from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';

// Domain
import {
  AssociatedProductEntity,
} from '@/modules/flagship/investor-goal/associated-product/domain/entities/associated-product-entity';
import { InvestorGoalsDto } from '@/modules/flagship/investor-goal/link-sowos-wealth/domain/dtos/investor-goals-dto';
import { SearchByCustomerDto } from '@/modules/flagship/investor-goal/search-by-customer/domain/dtos/search-by-customer-dto';
import {
  CustomerInvestorGoalBalanceQueryEntity,
} from '@/modules/flagship/customer-investor-goal-balance/domain/entities/customer-investor-goal-balance-query-entity';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

type AssociatedProduct = {
  investor_goal_id: string;
  label: string;
  active_goal_amount: number;
  active_goal_amount_field: string;
}

export default class StepDistributeBalanceWealthViewModel {
  @Inject(TYPES.GET_ASSOCIATED_PRODUCT_QUERY)
  private readonly get_associated_product_query!: GetAssociatedProductQuery;

  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.SEARCH_ALLIANZ_ACCOUNT_REPORT_QUERY)
  private readonly search_allianz_account_report_query!: SearchAllianzAccountReportQuery;

  @Inject(TYPES.GET_CUSTOMER_INVESTOR_GOAL_BALANCE_QUERY)
  private readonly get_customer_investor_goal_balance_query!: GetCustomerInvestorGoalBalanceQuery;

  @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  private readonly view!: Vue;

  private readonly customer_id = sessionStorage.getItem('user_id');

  readonly i18n_namespace = 'components.flagship.flagship-link-goals.step-distribute-balance-wealth';

  private readonly error_allianz_account_not_exists = `Error customer_id:${this.customer_id}, not exists`;

  private investor_goals_dto: InvestorGoalsDto = {
    total_active_goals_balance: '0.00',
    price_by_unit: '0.00',
    unassigned_balance: '0',
    recurrent_unassigned_amount: '',
    investor_goals: [],
  };

  private current_investor_goal: InvestorGoalsDto = {
    total_active_goals_balance: '0.00',
    price_by_unit: '0.00',
    unassigned_balance: '0',
    recurrent_unassigned_amount: '',
    investor_goals: [],
  };

  private search_by_customer_dto: SearchByCustomerDto = {
    reload: false,
    associated_product_id: '',
    is_active: true,
  };

  private customer_investor_goal_balance: CustomerInvestorGoalBalanceQueryEntity = {
    investment_product_id: '',
    reload: true,
  };

  goals_selected: Array<string> = [];

  associated_product: Array<AssociatedProduct> = [];

  balance = 0;

  is_disabled = false;

  free_balance = 0;

  amount_step = 100;

  has_active_goals = false;

  public constructor(view: Vue) {
    this.view = view;
  }

  // eslint-disable-next-line max-len
  initialize = async (product_id: string, goals_selected: Array<string>, current_investor_goal: any) => {
    this.view.$emit('loadingStep', true);
    this.goals_selected = goals_selected;
    this.current_investor_goal = current_investor_goal;
    this.search_by_customer_dto.associated_product_id = product_id;
    this.customer_investor_goal_balance.investment_product_id = product_id;
    await this.loadCustomerInvestorGoalBalance();
    await this.getAssociatedProduct();
    this.view.$emit('loadingStep', false);
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get_amount_formatted(amount: number) {
    return currencyFormat(amount);
  }

  get balance_formatted() {
    return currencyFormat(this.balance);
  }

  get balance_fixed() {
    return Math.floor(this.balance);
  }

  get free_balance_formatted() {
    return currencyFormat(this.free_balance);
  }

  addAssociatedProduct = (product: AssociatedProductEntity) => {
    const active_goal_amount = this.getCurrentGoalAmount(product.investor_goal_id);
    this.associated_product.push({
      investor_goal_id: product.investor_goal_id,
      label: (product.custom_investor_goal) ? product.custom_investor_goal.goal_name
        : product.investment_goal_type.label,
      active_goal_amount,
      active_goal_amount_field: String(active_goal_amount),
    });
  }

  getAssociatedProduct = async () => {
    try {
      // eslint-disable-next-line max-len
      const associated_products = await this.get_associated_product_query.execute(this.search_by_customer_dto.associated_product_id);
      associated_products.forEach((product) => {
        if (this.goals_selected.find(
          (item) => item === product.investor_goal_id,
        )) {
          this.addAssociatedProduct(product);
        }
      });
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_associated_product'));
    }
  };

  getAllianzAccountData = async () => {
    try {
      const { was_issued } = await this.get_allianz_account_query.execute({
        customer_id: this.customer_id,
      });
      if (was_issued) {
        await this.getAllianzBalance();
      }
    } catch (error) {
      if (error.message !== this.error_allianz_account_not_exists) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_allianz_account_data'));
      }
    }
  };

  getAllianzBalance = async () => {
    try {
      const allianz_account_report_list = await this.search_allianz_account_report_query.execute(
        this.customer_id,
      );
      if (allianz_account_report_list.length) {
        allianz_account_report_list.forEach((position) => {
          if (position.found_code === 'SWSRFP') {
            this.balance = position.final_balance_mxn || 0;
            this.free_balance = position.final_balance_mxn || 0;
          }
        });
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_allianz_balance'));
    }
  }

  changeActiveGoalAmount = (index: number) => {
    let active_goal_amount = (this.associated_product[index].active_goal_amount_field)
      ? Number(parseFloat(this.associated_product[index].active_goal_amount_field.replaceAll(/[^\d.-]/g, '')).toFixed(2))
      : 0;
    active_goal_amount = (active_goal_amount < 0) ? 0 : active_goal_amount;
    this.associated_product[index].active_goal_amount = active_goal_amount < this.balance
      ? active_goal_amount : this.balance;
    this.associated_product[index].active_goal_amount_field = active_goal_amount < this.balance
      ? currencyFormat(active_goal_amount) : currencyFormat(this.balance);
    this.validateBalanceAndAmountSlider();
  }

  changeActiveGoalAmountSlider = (index: number) => {
    // eslint-disable-next-line max-len
    this.associated_product[index].active_goal_amount_field = this.associated_product[index].active_goal_amount < this.balance
      ? currencyFormat(this.associated_product[index].active_goal_amount)
      : currencyFormat(this.balance);
    this.validateBalanceAndAmountSlider();
  }

  validateBalanceAndAmountSlider = () => {
    const amount_sliders = this.associated_product.map((obj) => (
      Number(parseFloat(obj.active_goal_amount_field.replaceAll(/[^\d.-]/g, '')).toFixed(2))
    ));
    const total_amount_sliders = amount_sliders.reduce(
      (total, currentValue) => total + currentValue, 0,
    );
    if (total_amount_sliders > this.balance) {
      this.is_disabled = true;
      this.free_balance = total_amount_sliders - this.balance;
    } else {
      this.is_disabled = false;
      this.free_balance = this.balance - total_amount_sliders;
    }
  }

  loadCustomerInvestorGoalBalance = async () => {
    try {
      // eslint-disable-next-line max-len
      const { unassigned_balance } = await this.get_customer_investor_goal_balance_query.execute(this.customer_investor_goal_balance);
      this.balance = unassigned_balance;
      this.free_balance = this.balance;
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_customer_investor_goal_balance'));
      } else {
        await this.getAllianzAccountData();
      }
    }
  }

  getCurrentGoalAmount = (investor_goal_id: string) => {
    let active_goal_amount = 0;
    this.current_investor_goal.investor_goals.forEach((item: any) => {
      if (item.investor_goal_id === investor_goal_id) {
        active_goal_amount = item.active_goal_amount;
        this.free_balance -= active_goal_amount;
      }
    });
    return active_goal_amount;
  }

  sendInvestorGoals = () => {
    this.view.$emit('loadingStep', true);
    let total_active_goals_balance = 0;
    this.associated_product.forEach((product) => {
      this.investor_goals_dto.investor_goals.push({
        investor_goal_id: product.investor_goal_id,
        active_goal_amount: product.active_goal_amount_field.replaceAll(/[^\d.-]/g, ''),
        recurrent_assigned_amount: '',
      });
      total_active_goals_balance += Number(parseFloat(product.active_goal_amount_field.replaceAll(/[^\d.-]/g, '')).toFixed(2));
    });
    this.investor_goals_dto.unassigned_balance = this.free_balance.toString();
    this.investor_goals_dto.total_active_goals_balance = total_active_goals_balance.toString();
    this.view.$emit('loadingStep', false);
    this.view.$emit('changeInvestorGoals', this.investor_goals_dto);
    this.view.$emit('nextStep');
  }

  goToPrevStep = () => {
    this.view.$emit('prevStep');
  }
}
