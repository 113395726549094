






















import { Component, Vue } from 'vue-property-decorator';
import AddCustomInvestorGoalCardViewModel
  from '@/vue-app/view-models/components/goals-dashboard/add-custom-investor-goal-card-view-model';

@Component({
  name: 'AddCustomInvestorGoalCard',
  components: {},
})
export default class AddCustomInvestorGoalCard extends Vue {
  add_custom_goal_card_view_model = Vue.observable(new AddCustomInvestorGoalCardViewModel());

  createNewGoal() {
    this.$emit('createNewGoal');
  }
}
