






































import { Component, PropSync, Vue } from 'vue-property-decorator';

import TrackingResumedViewModel from
  '@/vue-app/view-models/components/goals-dashboard/linked-goals/tracking-resumed-view-model';

@Component({ name: 'TrackingResumedDialog' })
export default class TrackingResumedDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  view_model = Vue.observable(
    new TrackingResumedViewModel(),
  );

  cancel() {
    this.synced_is_open = false;
  }
}
