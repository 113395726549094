
















































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import LoadingDataCard from '@/vue-app/components/home-dashboard/LoadingDataCard.vue';
import ProgressCardViewModel from '@/vue-app/view-models/components/goals-dashboard/progress-card-view-model';

@Component({
  name: 'ProgressCard',
  components: {
    LoadingDataCard,
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class ProgressCard extends Vue {
  progress_card_view_model = Vue.observable(new ProgressCardViewModel());

  @Watch('progress_card_view_model.distribute_state.distributed')
  handleDistributeChange(loaded: boolean) {
    if (loaded) {
      this.progress_card_view_model.handleDistributeChange();
    }
  }

  @Watch('progress_card_view_model.manage_tracking_investor_goal_state.show_pause_tracking_reason_dialog')
  handleShowPauseTrackingReasonDialog(show: boolean) {
    if (show) {
      this.progress_card_view_model.handleShowPauseTrackingReasonDialog();
    }
  }

  @Watch('progress_card_view_model.manage_tracking_investor_goal_state.show_tracking_resumed_dialog')
  handleShowTrackingResumedDialog(show: boolean) {
    if (show) {
      this.progress_card_view_model.handleShowTrackingResumedDialog();
    }
  }

  created() {
    this.progress_card_view_model.initialize();
  }
}
