



























































































































import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';
import RecurrentContributionAmongLinkedGoalsDialogViewModel
  from '@/vue-app/view-models/allianz-dashboard/recurrent-contribution-among-linked-goals-dialog-view-model';

@Component({
  name: 'RecurrentContributionAmongLinkedGoalsDialog',
})
export default class RecurrentContributionAmongLinkedGoalsDialog extends Vue {
  @PropSync('isOpen', { type: Boolean })
  synced_is_open!: boolean;

  recurrent_contribution_dialog_model = Vue.observable(
    new RecurrentContributionAmongLinkedGoalsDialogViewModel(this),
  );

  async created() {
    await this.recurrent_contribution_dialog_model.initialize();
  }

  closeModal() {
    this.synced_is_open = false;
  }
}
