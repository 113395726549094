import TYPES from '@/types';

import Vue from 'vue';

// Application
import SetGoalTrackingCommand
  from '@/modules/flagship/investor-goal/goal-tracking/application/commands/set-goal-tracking-command';

// Domain
import {
  ManageTrackingInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/manage-tracking-investor-goal-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class PauseTrackingViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.SET_GOAL_TRACKING_COMMAND)
  readonly set_goal_tracking_command!: SetGoalTrackingCommand;

  @Inject(TYPES.MANAGE_TRACKING_INVESTOR_GOAL_STATE_MANAGER)
  readonly manage_tracking_investor_goal_state_manager!: ManageTrackingInvestorGoalStateManager;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.goals-dashboard.linked-goals.pause_tracking';

  readonly view: Vue;

  is_loading = false;

  manage_tracking_investor_goal_state = this
    .manage_tracking_investor_goal_state_manager.state;

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  pauseTracking = async () => {
    try {
      this.is_loading = true;
      await this.set_goal_tracking_command.execute({
        investor_goal_id: this.manage_tracking_investor_goal_state.investor_goal_id,
        tracking_enabled: false,
      });
      this.manage_tracking_investor_goal_state.show_pause_tracking_dialog = false;
      this.manage_tracking_investor_goal_state.show_pause_tracking_reason_dialog = true;
    } catch (error) {
      this.message_notifier.showErrorNotification(
        this.translate('errors.pause_tracking'),
      );
    } finally {
      this.is_loading = false;
    }
  }
}
