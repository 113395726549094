import Vue from 'vue';

// Domain
import { PauseTrackingReasonsStateManager } from '../../domain/state/pause-tracking-reasons-state-manager';
import PauseTrackingReasonsState from '../../domain/state/pause-tracking-reasons-state';
import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';

@Injectable()
export default class PauseTrackingReasonsVueStateManager implements
  PauseTrackingReasonsStateManager {
  // eslint-disable-next-line max-len
  private _state: PauseTrackingReasonsState = Vue.observable<PauseTrackingReasonsState>(new PauseTrackingReasonsState());

  private readonly observers: Observer[] = [];

  get state(): PauseTrackingReasonsState {
    return this._state;
  }

  set state(value: PauseTrackingReasonsState) {
    this._state = value;
    this.notifyAll();
  }

  patch(state: Partial<PauseTrackingReasonsState>) {
    type Keys = keyof PauseTrackingReasonsState;
    Object.entries(state).forEach(([key, value]) => {
      const accessor = key as Keys;

      if (value !== undefined) {
        // eslint-disable-next-line
        // @ts-ignore @typescript-eslint/ban-ts-ignore
        this.state[accessor] = value;
      }
    });
  }

  notifyAll() {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer) {
    this.observers.push(observer);
  }
}
