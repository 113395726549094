import {
  InvestorGoal,
} from '@/modules/flagship/investor-goal/distribute-unassigned-balance/domain/entities/distribute-unassigned-balance-entity';

export class GoalsDashboardDistributeState {
  is_loading = false;

  distributed = false;

  distributed_goals: Array<InvestorGoal> = [];
}
