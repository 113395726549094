import {
  EmergencyFundInvestorGoalEntity,
} from '@/modules/flagship/emergency-fund-investor-goal/domain/entities/emergency-fund-investor-goal-entity';

export default class EmergencyFundInvestorGoalState {
  loaded = false;

  item: EmergencyFundInvestorGoalEntity = {
    id: '',
    investor_goal_id: '',
    investor_goal: {
      is_active: false,
      active_goal_amount: 0,
      investment_goal_type_id: '',
      investor_profile_id: '',
      monthly_required_amount: 0,
      associated_product_id: '',
      accumulated_amount: 0,
      final_investment_date: '',
      initial_investment_date: '',
      customer_id: '',
      id: '',
      created_at: '',
      updated_at: '',
      initial_amount: 0,
      associated_product: {
        interest_rate: 0,
        description: '',
        created_at: '',
        id: '',
        updated_at: '',
        investment_provider: {
          description: '',
          created_at: '',
          id: '',
          updated_at: '',
          label: '',
          name: '',
        },
        investment_provider_id: '',
        label: '',
        name: '',
      },
      associated_contract: {},
      tracking_enabled: false,
    },
    desired_amount: 0,
    fixed_time_adjusted: 0,
    first_initial_investment_date: '',
    tracking_percent: 90.0011,
    created_at: '',
    updated_at: '',
  }
}
