import i18n from '@/vue-app/plugins/i18n';
import TYPES from '@/types';
import Vue from 'vue';
import { getGoalIconPath } from '@/vue-app/utils/goal-icon-path';

// Application
import GetAssociatedProductQuery from '@/modules/flagship/investor-goal/associated-product/application/queries/get-associated-product-query';

// Domain
import {
  AssociatedProductEntity,
} from '@/modules/flagship/investor-goal/associated-product/domain/entities/associated-product-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

type AssociatedProduct = {
  investor_goal_id: string;
  label: string;
  icon: string;
  icon_path: string;
}

export default class StepChooseGoalsWealthViewModel {
   @Inject(TYPES.GET_ASSOCIATED_PRODUCT_QUERY)
  private readonly get_associated_product_query!: GetAssociatedProductQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  private readonly view!: Vue;

  readonly i18n_namespace = 'components.flagship.flagship-link-goals.step-choose-goals-wealth';

  is_disabled = true;

  associated_product_id = '';

  associated_product_selected: Array<string> = [];

  associated_product: Array<AssociatedProduct> = [];

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (value: string) => (
    i18n.t(`${this.i18n_namespace}.${value}`) as string
  )

  initialize = async (product_id: string) => {
    this.view.$emit('loadingStep', true);
    this.associated_product_id = product_id;
    await this.getAssociatedProduct();
    this.view.$emit('loadingStep', false);
  }

  getIconMaxWidth = (icon_goal_name: string) => ((icon_goal_name === 'icon-add.svg') ? '28px' : '25px');

  associatedProductChange = () => {
    this.is_disabled = this.associated_product_selected.length === 0;
  }

  addAssociatedProduct = (product: AssociatedProductEntity) => {
    this.associated_product.push({
      investor_goal_id: product.investor_goal_id,
      label: (product.custom_investor_goal) ? product.custom_investor_goal.goal_name
        : product.investment_goal_type.label,
      // eslint-disable-next-line no-nested-ternary
      icon: (product.custom_investor_goal)
        ? product.custom_investor_goal.custom_goal_type.icon_name
        : (product.investment_goal_type.name === 'retirement')
          ? 'icon-retirement.svg' : `icon-${product.investment_goal_type.name}.svg`,
      icon_path: getGoalIconPath(
        product.investor_goal_id,
        product.custom_investor_goal?.custom_goal_type?.icon_name || 'icon-retirement.svg',
        product.custom_investor_goal?.updated_at || '',
      ),
    });
  }

  getAssociatedProduct = async () => {
    try {
      // eslint-disable-next-line max-len
      const associated_products = await this.get_associated_product_query.execute(this.associated_product_id);
      associated_products.forEach((product) => {
        if (!product.is_active) {
          this.addAssociatedProduct(product);
        }
      });
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_associated_product'));
    }
  };

  nextStep = () => {
    this.view.$emit('createInvestorGoalsDto');
    this.view.$emit('nextStep');
  }

  closeModal = () => {
    this.view.$emit('closeModal');
  }
}
