















































































import { Component, Vue } from 'vue-property-decorator';
import LinkGoalsCardViewModel
  from '@/vue-app/view-models/components/kuspit-dashboard/goals/link-goals-card-view-model';

@Component({
  name: 'LinkGoalsCard',
  components: {
    LoadingDataCard: () => import('@/vue-app/components/home-dashboard/LoadingDataCard.vue'),
    GoalsUndefinedCard: () => import('@/vue-app/components/allianz-dashboard/goals/GoalsUndefinedCard.vue'),
    FlagshipProfilingCard: () => import('@/vue-app/components/my-investment-dashboard-v2/FlagshipProfilingCard.vue'),
    FlagshipLinkGoalsWizardPocket: () => import('@/vue-app/components/flagship/flagship-link-goals/FlagshipLinkGoalsWizardPocket.vue'),
    DistributeBalanceUnallocatedModal: () => import('@/vue-app/components/goals-dashboard/unallocated-balance/DistributeBalanceUnallocatedModal.vue'),
  },
})
export default class LinkGoalsCard extends Vue {
  link_goals_view_model = Vue.observable(new LinkGoalsCardViewModel());

  async created() {
    await this.link_goals_view_model.initialize();
  }
}
