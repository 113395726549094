export default class ManageTrackingInvestorGoalState {
  is_loading = false;

  investor_goal_id = '';

  investor_goal_name = '';

  show_pause_tracking_dialog = false;

  show_pause_tracking_reason_dialog = false;

  show_resume_tracking_dialog = false;

  show_tracking_resumed_dialog = false;
}
