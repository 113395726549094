import TYPES from '@/types';

import Vue from 'vue';

// Application
import SearchInvestmentProductFundTypeByCustomerQuery
  from '@/modules/my-investment/allianz/investment-product-fund-type/application/queries/search-investment-product-fund-type-by-customer-query';
import GetCustomerInvestorGoalBalanceQuery
  from '@/modules/flagship/customer-investor-goal-balance/application/query/get-customer-investor-goal-balance';
import {
  SearchAllianzAccountReportQuery,
} from '@/modules/my-investment/allianz/allianz-account-report/application/queries';
import AccessMoreStrategiesStateManagerService
  from '@/modules/my-investment/allianz/access-more-strategies/application/services/access-more-strategies-state-manager-service';

// Domain
import {
  InvestmentProductFundTypeEntity,
} from '@/modules/flagship/catalogs/investment-product-fund-type/domain/entities/investment-product-fund-type-entity';
import AccessMoreStrategiesState
  from '@/modules/my-investment/allianz/access-more-strategies/domain/state/access-more-strategies-state';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class DefineInvestmentTermViewModel {
  @Inject(TYPES.SEARCH_INVESTMENT_PRODUCT_FUND_TYPE_BY_CUSTOMER_QUERY)
  private readonly search_investment_product_fund_type!:
    SearchInvestmentProductFundTypeByCustomerQuery;

  @Inject(TYPES.GET_CUSTOMER_INVESTOR_GOAL_BALANCE_QUERY)
  private readonly get_customer_investor_goal_balance_query!: GetCustomerInvestorGoalBalanceQuery;

  @Inject(TYPES.SEARCH_ALLIANZ_ACCOUNT_REPORT_QUERY)
  private readonly search_allianz_account_report_query!: SearchAllianzAccountReportQuery;

  @Inject(TYPES.ACCESS_MORE_STRATEGIES_STATE_MANAGER_SERVICE)
  private readonly manager_service!: AccessMoreStrategiesStateManagerService;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.allianz-dashboard.define-investment-term';

  private readonly customer_id = sessionStorage.getItem('user_id');

  readonly view: Vue;

  readonly minimum_term_to_show_other_strategies = 10;

  is_loading = false;

  is_valid_form = false;

  term_label = '';

  term_slider_min_value = 1;

  term_slider_max_value = 20;

  see_more_moderate = false;

  see_more_pesos = false;

  term_is_valid_for_access_other_strategies = false;

  available_balance = 0;

  strategy_options: Array<InvestmentProductFundTypeEntity> = [];

  state: AccessMoreStrategiesState;

  public constructor(view: Vue) {
    this.view = view;
    this.state = this.manager_service.getAccessMoreStrategiesState();
    this.updateTerm(this.state.investor_goal.term);
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get can_continue() {
    return (this.state.strategy.id && this.term_is_valid_for_access_other_strategies)
      || !this.term_is_valid_for_access_other_strategies;
  }

  get secondary_button() {
    return this.term_is_valid_for_access_other_strategies ? this
      .translate('continue') : this.translate('accept');
  }

  updateAvailableStrategies = (selected_term: number) => {
    this.strategy_options.forEach((option, index) => {
      if (selected_term < this.getMinYear(option.label)) {
        if (option.id === this.state.strategy.id) {
          this.state.strategy = {
            id: '',
            name: '',
            label: '',
            investment_product_id: '',
            description: '',
            investment_product: {
              id: '',
              name: '',
              label: '',
              investment_provider_id: '',
              interest_rate: 0,
              description: '',
            },
          };
        }
        this.strategy_options[index].available = false;
      } else {
        this.strategy_options[index].available = true;
      }
    });
  }

  updateTerm = (selected_term: number) => {
    this.term_is_valid_for_access_other_strategies = selected_term < (
      this.state.strategy.min_year || 0
    );
    this.updateAvailableStrategies(selected_term);
    if (selected_term === 1) {
      this.term_label = this.translate('year');
    } else {
      this.term_label = this.translate('years');
    }
  }

  back = () => {
    this.view.$emit('prevStep');
  }

  close = () => {
    this.view.$emit('close');
  }

  openDefineGoalsFlow = () => {
    this.view.$emit('openDefineGoalsFlow');
  }

  loadAllianzPosition = async () => {
    let position = 0;
    try {
      const account_reports = await this.search_allianz_account_report_query
        .execute(this.customer_id);
      const pesos_account_report = account_reports.find(
        (account_report) => account_report.found_code === 'SWSRFP',
      );
      if (pesos_account_report) {
        position = pesos_account_report.final_balance_mxn || 0;
      }
      return position;
    } catch {
      return position;
    }
  }

  // eslint-disable-next-line consistent-return
  getAvailableBalance = async (associated_product_id: string) => {
    try {
      const { unassigned_balance } = await this.get_customer_investor_goal_balance_query
        .execute({ investment_product_id: associated_product_id });
      return unassigned_balance;
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_customer_investor_goal_balance'));
      } else {
        // eslint-disable-next-line no-return-await
        return await this.loadAllianzPosition();
      }
      return 0;
    }
  }

  validateIfHasSufficientBalanceAvailable = async () => {
    if (this.state.strategy.label === 'SWSRFP') {
      return true;
    }
    if (this.state.strategy.label === 'SWSMOD') {
      this.available_balance = await this.getAvailableBalance(
        this.state.strategy.investment_product_id,
      );
      return this.available_balance >= 1000;
    }
    return false;
  }

  updateInformation = async () => {
    const is_valid = await this.validateIfHasSufficientBalanceAvailable();
    if (is_valid) {
      this.view.$emit('nextStep');
    } else {
      this.view.$emit('insufficientBalanceAvailable', this.available_balance);
    }
  }

  getMinYear = (fund_code: string) => {
    switch (fund_code) {
      case 'SWSRFP':
        return 1;
      case 'SWSMOD':
        return 10;
      default:
        return 1;
    }
  }

  seeMore = (fund_code: string) => {
    if (fund_code === 'SWSMOD') {
      this.see_more_moderate = true;
    } else if (fund_code === 'SWSRFP') {
      this.see_more_pesos = true;
    }
  }

  validateIfIsRecommended = (fund_code: string) => fund_code === 'SWSMOD'

  loadInvestmentProductFundTypes = async () => {
    try {
      const investment_product_fund_types = await this
        .search_investment_product_fund_type.execute();

      investment_product_fund_types.reverse().forEach((fund_type) => {
        this.strategy_options.push({
          ...fund_type,
          tooltip: this.translate(fund_type.label),
          min_year: this.getMinYear(fund_type.label),
          recommended: this.validateIfIsRecommended(fund_type.label),
          available: true,
        });
      });
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_investment_products_query'));
    }
  }

  initialize = async () => {
    this.state.is_loading = true;
    await this.loadInvestmentProductFundTypes();
    this.state.is_loading = false;
  }
}
