

























































































































































import { Component, Vue } from 'vue-property-decorator';
import FlagshipGoalsWizardCustomizeGoalModel
  from '@/vue-app/view-models/components/flagship/flagship-goals/flagship-goals-wizard-customize-goal-model';

@Component({
  name: 'FlagshipGoalsWizardCustomizeGoal',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class FlagshipGoalsWizardCustomizeGoal extends Vue {
  flagship_customize_goal_model = Vue.observable(new FlagshipGoalsWizardCustomizeGoalModel(this));

  created() {
    this.flagship_customize_goal_model.initialize();
  }
}
