var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"h-full primary--text"},[_c('div',{staticClass:"d-flex flex-column h-full justify-space-between"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"10","lg":"7"}},[_c('h4',[_vm._v(_vm._s(_vm.view_model.translate('title')))]),_c('p',{staticClass:"text-text mt-5"},[_vm._v(_vm._s(_vm.view_model.translate('description')))]),(!_vm.view_model.investor_goal_state.emergency_fund.already_created
                  || !_vm.view_model.investor_goal_state.retirement_fund.already_created)?_c('div',[_c('h5',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.view_model.translate('sowos_goals'))+" "),_c('custom-tooltip',{attrs:{"color":"accent","label":_vm.view_model
              .translate('sowos_goals_tooltip')},on:{"update:label":function($event){_vm.$set(_vm.view_model
              , "translate('sowos_goals_tooltip')", $event)}}})],1),_c('v-row',{staticClass:"my-5"},_vm._l((_vm.view_model.options),function(option,index){return _c('v-col',{key:option.id,attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"cursor-pointer mt-3 mt-md-0",class:option.id === _vm.view_model.investor_goal_state.investor_goal_type ?
                      ("active " + (option.active_class)):
                      'inactive',attrs:{"rounded":""},on:{"click":function($event){return _vm.view_model.selectOption(index)}}},[_c('v-row',[_c('v-col',{staticClass:"text-center primary--text",attrs:{"cols":"12"}},[_c('div',{staticClass:"mx-auto icon-style",class:option.id},[_c('v-img',{staticClass:"mx-auto",attrs:{"src":require(("@/assets/icons/custom-goal-icons/" + (_vm.view_model.getActiveIcon(
                        option.id,
                        option.icon
                       )))),"contain":"","width":"30px","height":"30px","alt":option.label}})],1),_c('h6',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(option.label)+" ")]),_c('v-img',{staticClass:"mx-auto mt-5",attrs:{"src":require(("@/assets/icons/" + (_vm.view_model.getActiveLogo(
                        option.id,
                        option.image_src
                      )))),"max-width":"73px","max-height":"20px"}})],1)],1)],1)],1)}),1)],1):_vm._e(),_c('div',[_c('h5',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.view_model.translate('custom_goals'))+" "),_c('custom-tooltip',{attrs:{"color":"accent","label":_vm.view_model
              .translate('custom_goals_tooltip')},on:{"update:label":function($event){_vm.$set(_vm.view_model
              , "translate('custom_goals_tooltip')", $event)}}})],1),_c('v-row',{staticClass:"my-5 px-5"},_vm._l((_vm.view_model.custom_goal_types),function(option){return _c('v-col',{key:option.id,staticClass:"align-center",attrs:{"cols":"12","md":"4"}},[_c('v-btn-toggle',{staticClass:"d-flex flex-column",on:{"change":function($event){return _vm.view_model.selectCustomGalType(option)}},model:{value:(_vm.view_model.personal_goal),callback:function ($$v) {_vm.$set(_vm.view_model, "personal_goal", $$v)},expression:"view_model.personal_goal"}},[_c('v-btn',{staticClass:"rounded-xl",attrs:{"min-width":"80","min-height":"85","value":option.name,"elevation":"4","active-color":"accent"}},[_c('v-img',{attrs:{"max-width":"40","src":require(("@/assets/icons/custom-goal-icons/" + (_vm.view_model.getActiveIconCustom(
                      option.icon_name
                     )))),"alt":option.name}})],1),_c('p',{staticClass:"text-h7 ma-0 text-center pt-3 primary--text"},[_vm._v(" "+_vm._s(option.label)+" ")])],1)],1)}),1),_c('v-row',{staticClass:"px-10",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"12"}},[_c('p',{staticClass:"caption-text dark-grey--text"},[_vm._v(" "+_vm._s(_vm.view_model.translate('find_other_goal'))+" ")]),_c('v-text-field',{staticClass:"mt-5 v-text-field--blue",attrs:{"dense":"","maxlength":"26","rules":_vm.view_model.input_rules.other_goal_name},on:{"keyup":_vm.view_model.changeOtherPersonalGoal},model:{value:(_vm.view_model.investor_goal_state.custom_investor_goal.other_personal_goal),callback:function ($$v) {_vm.$set(_vm.view_model.investor_goal_state.custom_investor_goal, "other_personal_goal", $$v)},expression:"view_model.investor_goal_state.custom_investor_goal.other_personal_goal"}})],1)],1)],1)])],1),_c('v-row',{attrs:{"align":"end","justify":"center"}},[_c('v-col',{attrs:{"md":"6"}},[_c('v-btn',{staticClass:"text-body px-16",attrs:{"color":"secondary","x-large":"","rounded":"","block":"","disabled":_vm.view_model.is_continue_btn_disabled},on:{"click":_vm.view_model.prevStep}},[_vm._v(" "+_vm._s(_vm.view_model.translate('back'))+" ")])],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-btn',{staticClass:"text-body px-16",attrs:{"color":"accent","x-large":"","rounded":"","block":"","disabled":_vm.view_model.is_continue_btn_disabled},on:{"click":_vm.view_model.nextStep}},[_vm._v(" "+_vm._s(_vm.view_model.translate('continue'))+" ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }