








































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import LinkedCustomInvestorGoalMenuCardViewModel from '@/vue-app/view-models/components/goals-dashboard/linked-goals/linked-custom-investor-goal-menu-card-view-model';
import {
  LinkedCustomInvestorGoalMenuCardDataDto,
} from '@/modules/flagship/investor-goal/linked-investor-goal/domain/dto/linked-custom-investor-goal-menu-card-data-dto';

@Component({
  name: 'LinkedCustomInvestorGoalMenuCard',
  components: {},
})
export default class LinkedCustomInvestorGoalMenuCard extends Vue {
  @PropSync('cardData', { type: Object, required: true })
  card_data!: LinkedCustomInvestorGoalMenuCardDataDto;

  view_model = Vue.observable(new LinkedCustomInvestorGoalMenuCardViewModel(this));
}
