import TYPES from '@/modules/shared/types';

// Domain
import {
  PauseTrackingReasonRepository,
} from '../../domain/repositories/pause-tracking-reason-repository';
import {
  PauseTrackingReasonEntity,
} from '../../domain/entities/pause-tracking-reason-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class PauseTrackingReasonsHttpRepository
implements PauseTrackingReasonRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/catalogs/pause-tracking-reason/`;

  all():
    Promise<Array<PauseTrackingReasonEntity>> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint)
        .then((response) => {
          response.json()
            .then(({ success, data, error }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(error));
              }
            });
        })
        .catch(reject);
    });
  }
}
