




















import {
  Component, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import CustomProgressBarViewModel
  from '@/vue-app/view-models/components/goals-dashboard/details-goals/custom-progress-bar-view-model';

// Domain
import {
  GoalTrackingEntity,
} from '@/modules/flagship/investor-goal/goal-tracking/domain/entities/goal-tracking-entity';

@Component({ name: 'CustomProgressBar' })
export default class CustomProgressBar extends Vue {
  @PropSync('goalTrackingEntity', { type: Object })
  goal_tracking_entity!: GoalTrackingEntity;

  @PropSync('isABucketGoal', { type: Boolean, default: false })
  is_a_bucket_goal!: boolean;

  view_model = Vue.observable(
    new CustomProgressBarViewModel(),
  );

  @Watch('goal_tracking_entity')
  handleSyncedSegmentsChange(new_goal_tracking_entity: GoalTrackingEntity) {
    this.view_model.setSegments(new_goal_tracking_entity, this.is_a_bucket_goal);
  }

  created() {
    this.view_model.setSegments(this.goal_tracking_entity, this.is_a_bucket_goal);
  }
}
