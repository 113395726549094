











































import { Component, Vue } from 'vue-property-decorator';
import MyInvestmentDashboardViewModelV2
  from '@/vue-app/view-models/views/my-investment-dashboard-view-model-v2';

@Component({
  name: 'MyInvestmentDashboardV2',
  components: {
    InviteFriendCard: () => import('@/vue-app/components/my-investment-dashboard-v2/InviteFriendCard.vue'),
    SowosWealthCard: () => import('@/vue-app/components/my-investment-dashboard-v2/SowosWealthCard.vue'),
    MyPersonalBalanceCard: () => import('@/vue-app/components/my-investment-dashboard-v2/MyPersonalBalanceCard.vue'),
    MembershipInformation: () => import('@/vue-app/components/my-investment-dashboard-v2/MembershipInformation.vue'),
    SowosPocketCard: () => import('@/vue-app/components/my-investment-dashboard-v2/SowosPocketCard.vue'),
    FlagshipProfilingCard: () => import('@/vue-app/components/my-investment-dashboard-v2/FlagshipProfilingCard.vue'),
    WealthPolicyInProcessOfCancellationBanner: () => import('@/vue-app/components/banners/WealthPolicyInProcessOfCancellationBanner.vue'),
    WealthPendingMovementsBanner: () => import('@/vue-app/components/banners/WealthPendingMovementsBanner.vue'),
  },
})
export default class MiInvestmentDashboard extends Vue {
  my_investment_dashboard_view_model = Vue.observable(new MyInvestmentDashboardViewModelV2());

  async created() {
    this.my_investment_dashboard_view_model.initialize();
  }
}
