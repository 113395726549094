import TYPES from '@/modules/shared/types';

// Domain
import { InvestorGoalPauseTrackingReasonRepository }
  from '../../domain/repositories/investor-goal-pause-tracking-reason-repository';
import { InvestorGoalPauseTrackingReasonDto }
  from '../../domain/dto/investor-goal-pause-tracking-reason-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class InvestorGoalPauseTrackingReasonHttpRepository implements
  InvestorGoalPauseTrackingReasonRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/investor-goal-pause-tracking-reason/`;

  create(payload: InvestorGoalPauseTrackingReasonDto):
    Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint, 'POST', payload)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
