import TYPES from '@/types';
import { v4 } from 'uuid';
import Vue from 'vue';

// Application
import GetCustomerInvestorGoalReminderQuery
  from '@/modules/flagship/customer-investor-goal-reminder/application/queries/get-customer-investor-goal-reminder-query';
import UpdateEmergencyFundInvestorGoalCommand
  from '@/modules/flagship/emergency-fund-investor-goal/application/command/update-emergency-fund-investor-goal-command';
import GetEmergencyFundInvestorGoalQuery from '@/modules/flagship/emergency-fund-investor-goal/application/query/get-emergency-fund-investor-goal-query';
import CreateCustomInvestorGoalCommand
  from '@/modules/flagship/custom-investor-goal/application/command/create-custom-investor-goal-command';
import CreateCustomerInvestorGoalReminderCommand
  from '@/modules/flagship/customer-investor-goal-reminder/application/commands/create-customer-investor-goal-reminder-command';
import UpdateCustomerInvestorGoalReminderCommand
  from '@/modules/flagship/customer-investor-goal-reminder/application/commands/update-customer-investor-goal-reminder-command';
import CreateLinkSowosPocketCommand
  from '@/modules/flagship/investor-goal/link-sowos-pocket/application/commands/create-link-sowos-pocket-command';

// Domain
import {
  CustomerInvestorGoalReminderEntity,
} from '@/modules/flagship/customer-investor-goal-reminder/domain/entities/customer-investor-goal-reminder-entity';
import {
  CreateInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/create-investor-goal-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class ScheduleReminderPocketViewModel {
  @Inject(TYPES.GET_CUSTOMER_INVESTOR_GOAL_REMINDER_QUERY)
  private readonly get_customer_investor_goal_reminder_query!: GetCustomerInvestorGoalReminderQuery;

  @Inject(TYPES.UPDATE_EMERGENCY_FUND_INVESTOR_GOAL_COMMAND)
  private readonly update_emergency_fund_goal!: UpdateEmergencyFundInvestorGoalCommand;

  @Inject(TYPES.CREATE_CUSTOM_INVESTOR_GOAL_COMMAND)
  private readonly create_custom_investor_goal!: CreateCustomInvestorGoalCommand;

  @Inject(TYPES.CREATE_CUSTOMER_INVESTOR_GOAL_REMINDER_COMMAND)
  private readonly create_investor_goal_reminder_cmd!: CreateCustomerInvestorGoalReminderCommand;

  @Inject(TYPES.UPDATE_CUSTOMER_INVESTOR_GOAL_REMINDER_COMMAND)
  private readonly update_investor_goal_reminder_cmd!: UpdateCustomerInvestorGoalReminderCommand;

  @Inject(TYPES.CREATE_LINK_SOWOS_POCKET_COMMAND)
  private readonly create_link_pocket_command!: CreateLinkSowosPocketCommand;

  @Inject(TYPES.GET_EMERGENCY_FUND_INVESTOR_GOAL_QUERY)
  private readonly get_emergency_fund_investor_goal_query!: GetEmergencyFundInvestorGoalQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
  readonly create_investor_goal_state_manager!: CreateInvestorGoalStateManager;

  private readonly view!: Vue

  readonly i18n_namespace = 'components.flagship.flagship-link-goals.step-schedule-reminder-pocket';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  AVAILABLE_DAYS = [...Array(28).keys()].map((i) => ({ item: this.translate('select_option', { option: i + 1 }), value: i + 1 }));

  associated_product_id = '';

  customer_investor_goal_id = '';

  day_to_remind = 1;

  is_subscribed = false;

  is_disabled = false;

  has_reminder = false;

  change_date = true;

  current_day_to_remind = 1;

  private customer_reminder_entity: CustomerInvestorGoalReminderEntity = {
    id: v4(),
    day_to_remind: 0,
    investment_product_id: '',
  }

  investor_goal_state = this.create_investor_goal_state_manager.state;

  public constructor(view: Vue) {
    this.view = view;
  }

  radio_class() {
    return this.is_subscribed ? 'grey--text' : 'sky-blue--text';
  }

  initialize = async () => {
    this.investor_goal_state.is_loading = true;
    this.associated_product_id = this.investor_goal_state.associated_product_id;
    const is_created = await this.getCustomerInvestorGoalReminder();
    if (!is_created) {
      await this.createCustomerInvestorGoalReminder();
    } else {
      this.has_reminder = true;
    }
    this.investor_goal_state.is_loading = false;
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  selectedDayToRemind = () => {
    this.is_disabled = false;
  }

  getCustomerInvestorGoalReminder = async () => {
    try {
      const customer_investor_goal_reminder = await this
        .get_customer_investor_goal_reminder_query
        .execute({ associated_product_id: this.associated_product_id });
      this.customer_investor_goal_id = customer_investor_goal_reminder.id;
      this.current_day_to_remind = customer_investor_goal_reminder.day_to_remind;
      this.day_to_remind = customer_investor_goal_reminder.day_to_remind;
      this.is_subscribed = !customer_investor_goal_reminder.is_subscribed!;
      return true;
    } catch {
      return false;
    }
  };

  createCustomerInvestorGoalReminder = async () => {
    try {
      this.customer_reminder_entity.investment_product_id = this.associated_product_id;
      this.customer_reminder_entity.day_to_remind = this.day_to_remind;

      await this.create_investor_goal_reminder_cmd.execute(this.customer_reminder_entity);
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.create_customer_investor_goal_reminder'));
    } finally {
      await this.getCustomerInvestorGoalReminder();
    }
  };

  createCustomInvestorGoal = async () => {
    const payload = {
      id: this.investor_goal_state.investor_goal.id,
      goal_name: this.investor_goal_state.investor_goal.goal_name,
      desired_amount: this.investor_goal_state.investor_goal.desired_amount,
      fixed_time_adjusted: this.investor_goal_state.investor_goal.fixed_time_adjusted.toFixed(2),
      investor_goal: {
        investor_profile_id: this.investor_goal_state.investor_goal.investor_profile_id,
        initial_amount: this.investor_goal_state.investor_goal.initial_amount,
        accumulated_amount: this.investor_goal_state.investor_goal.accumulated_amount,
        monthly_required_amount: this.investor_goal_state.investor_goal.monthly_required_amount,
        initial_investment_date: this.investor_goal_state.investor_goal.initial_investment_date,
        final_investment_date: this.investor_goal_state.investor_goal.final_investment_date,
      },
      custom_goal_type: this.investor_goal_state.custom_investor_goal.custom_goal_type,
    };
    if (!this.investor_goal_state.investor_goal.already_created) {
      await this.create_custom_investor_goal.execute(payload);
    }
  }

  setEmergencyInvestorGoalIdIfAlreadyExists = async () => {
    try {
      const emergency_fund_goal = await this.get_emergency_fund_investor_goal_query.execute();
      this.investor_goal_state.investor_goal.id = emergency_fund_goal.investor_goal.id;
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_emergency_fund_goal'));
      }
    }
  }

  updateEmergencyFund = async () => {
    try {
      await this.setEmergencyInvestorGoalIdIfAlreadyExists();
      const payload = {
        id: this.investor_goal_state.investor_goal.id,
        investor_goal: {
          investor_profile_id: this.investor_goal_state.investor_goal.investor_profile_id,
          initial_amount: this.investor_goal_state.investor_goal.initial_amount,
          accumulated_amount: this.investor_goal_state.investor_goal.accumulated_amount,
          monthly_required_amount: this.investor_goal_state.investor_goal.monthly_required_amount,
          initial_investment_date: this.investor_goal_state.investor_goal.initial_investment_date,
          final_investment_date: this.investor_goal_state.investor_goal.final_investment_date,
        },
        desired_amount: this.investor_goal_state.investor_goal.desired_amount,
        fixed_time_adjusted: this.investor_goal_state.investor_goal.fixed_time_adjusted.toFixed(2),
      };
      await this.update_emergency_fund_goal.execute(payload);
    } catch (error) {
      this.message_notifier.showErrorNotification(this.translate('errors.create'));
    }
  }

  updateCustomerInvestorGoalReminder = async () => {
    try {
      this.investor_goal_state.is_loading = true;
      if (this.change_date) {
        this.customer_reminder_entity.id = this.customer_investor_goal_id;
        this.customer_reminder_entity.investment_product_id = this.associated_product_id;
        this.customer_reminder_entity.day_to_remind = this.day_to_remind;
        this.customer_reminder_entity.is_subscribed = !this.is_subscribed;

        await this.update_investor_goal_reminder_cmd.execute(this.customer_reminder_entity);
      }
      if (this.investor_goal_state.investor_goal_type === 'emergency_fund') {
        await this.updateEmergencyFund();
      } else {
        await this.createCustomInvestorGoal();
      }
      await this.createLinkSowosPocket();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_customer_investor_goal_reminder'));
    } finally {
      this.investor_goal_state.is_loading = false;
    }
  };

  createLinkSowosPocket = async () => {
    try {
      const investor_goals_dto = {
        total_active_goals_balance: this.investor_goal_state.total_active_goals_balance.toString(),
        unassigned_balance: this.investor_goal_state.available_balance.toString(),
        investor_goals: [{
          investor_goal_id: this.investor_goal_state.investor_goal.id,
          active_goal_amount: '0',
          recurrent_assigned_amount: this.investor_goal_state.investor_goal.monthly_required_amount
            .toString(),
        }],
      };
      await this.create_link_pocket_command.execute(investor_goals_dto);
      if (this.investor_goal_state.available_balance > 1) {
        this.investor_goal_state.goal_created_and_linked = true;
      } else {
        this.investor_goal_state.goal_linked_successfully = true;
      }
      this.view.$emit('endProcess');
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.create_link_sowos_pocket'));
    }
  };

  modifyDate = () => {
    if (!this.change_date) {
      this.day_to_remind = this.current_day_to_remind;
    }
  }
}
