import Vue from 'vue';
import TYPES from '@/types';
import { getGoalIconPath } from '@/vue-app/utils/goal-icon-path';

// Application
import GetAssociatedProductQuery from '@/modules/flagship/investor-goal/associated-product/application/queries/get-associated-product-query';

// Domain
import {
  AssociatedProductSelectedEntity,
} from '@/modules/flagship/investor-goal/associated-product/domain/entities/associated-product-selected-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class StepChooseGoalsPocketViewModel {
  @Inject(TYPES.GET_ASSOCIATED_PRODUCT_QUERY)
  private readonly get_associated_product_query!: GetAssociatedProductQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly view!: Vue;

  readonly i18n_namespace = 'components.flagship.flagship-link-goals.step-choose-goals-pocket';

  is_disabled = true;

  associated_product: Array<AssociatedProductSelectedEntity> = [];

  associated_product_selected: Array<string> = [];

  associated_product_id = '';

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async (associated_product_id: string) => {
    this.associated_product_id = associated_product_id;

    this.view.$emit('loadingStep', true);
    await this.getAssociatedProduct();
    this.view.$emit('loadingStep', false);
  }

  getIconMaxWidth = (icon_goal_name: string) => ((icon_goal_name === 'icon-add.svg') ? '28px' : '25px');

  associatedProductChange = () => {
    this.is_disabled = !this.associated_product_selected.length;
  }

  nextStep = () => {
    this.view.$emit('createInvestorGoalsDto');
    this.view.$emit('nextStep');
  }

  closeModal = () => {
    this.view.$emit('closeModal');
  }

  getAssociatedProduct = async () => {
    try {
      // eslint-disable-next-line max-len
      const associated_products = await this.get_associated_product_query.execute(this.associated_product_id);

      associated_products.forEach((product) => {
        if (!product.is_active) {
          this.associated_product.push({
            investor_goal_id: product.investor_goal_id,
            label: (product.custom_investor_goal) ? product.custom_investor_goal.goal_name
              : product.investment_goal_type.label,
            // eslint-disable-next-line no-nested-ternary
            icon: (product.custom_investor_goal)
              ? product.custom_investor_goal.custom_goal_type.icon_name
              : (product.investment_goal_type.name === 'emergency_fund')
                ? 'icon-fund.svg' : `icon-${product.investment_goal_type.name}.svg`,
            icon_path: getGoalIconPath(
              product.investor_goal_id,
              product.custom_investor_goal?.custom_goal_type?.icon_name || 'icon-fund.svg',
              product.custom_investor_goal?.updated_at || '',
            ),
          });
        }
      });
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_associated_product'));
    }
  };
}
