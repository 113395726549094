export class GoalsDashboardProgressState {
  is_loading = false;

  loaded_complete = false;

  has_linked_goals = false;

  has_unlinked_goals = false;

  has_any_product_contracted = false;

  has_any_goal_linked_wealth = false;

  has_any_goal_linked_pocket = false;

  investment_product_pocket_id = '';

  show_progress_card = false;

  recurrent_assigned_amount = 0;
}
