




























































































import {
  Component, Vue,
} from 'vue-property-decorator';
import RetirementFundAgeViewModel
  from '@/vue-app/view-models/components/goals-dashboard/create-goal/retirement-fund/retirement-fund-age-view-model';

@Component({
  name: 'RetirementFundAge',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class RetirementFundAge extends Vue {
  view_model = Vue.observable(
    new RetirementFundAgeViewModel(this),
  );

  mounted() {
    this.view_model.initialize();
  }
}
