import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';
import { getGoalIconPath } from '@/vue-app/utils/goal-icon-path';

import Vue from 'vue';

// Application
import GetCustomerInvestorGoalBalanceQuery from '@/modules/flagship/customer-investor-goal-balance/application/query/get-customer-investor-goal-balance';
import GetSearchByCustomerQuery from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';
import DistributeUnassignedBalanceCommand
  from '@/modules/flagship/investor-goal/distribute-unassigned-balance/application/commands/distribute-unassigned-balance-command';

// Domain
import {
  InvestorGoal,
} from '@/modules/flagship/investor-goal/distribute-unassigned-balance/domain/entities/distribute-unassigned-balance-entity';
import {
  SearchByCustomerEntity,
} from '@/modules/flagship/investor-goal/search-by-customer/domain/entities/search-by-customer-entity';
import { SearchByCustomerDto } from '@/modules/flagship/investor-goal/search-by-customer/domain/dtos/search-by-customer-dto';
import {
  GoalsDashboardDistributeStateManager,
} from '@/modules/goals-dashboard/distribute-balance/domain/state/goals-dashboard-distribute-state-manager';
import {
  verifyIfInvestorGoalIsLinkedWithModerate,
} from '@/modules/flagship/customer-investment-product-fund-type/domain/services/verify-if-investor-goal-is-linked-with-an-investment-product';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';

type LinkedGoals = {
  investor_goal_id: string;
  name: string;
  label: string;
  icon: string;
  assigned_amount: number;
  recommend_amount: number;
  alt: string;
  assigned_amount_field: string;
  is_moderate: boolean;
  icon_path: string;
}

export default class DistributeBalanceUnallocatedWealthViewModel {
  @Inject(TYPES.GET_CUSTOMER_INVESTOR_GOAL_BALANCE_QUERY)
  private readonly get_customer_investor_goal_balance_query!: GetCustomerInvestorGoalBalanceQuery;

  @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.DISTRIBUTE_UNASSIGNED_BALANCE_COMMAND)
  private readonly distribute_unassigned_balance_command!: DistributeUnassignedBalanceCommand;

  @Inject(TYPES.GOALS_DASHBOARD_DISTRIBUTE_STATE_MANAGER)
  private readonly distribute_state_manager!: GoalsDashboardDistributeStateManager;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  readonly i18n_namespace = 'components.goals-dashboard.unallocated-balance.distribute-balance-unallocated';

  readonly view!: Vue;

  linked_goals: Array<LinkedGoals> = [];

  deposit_amount = 0;

  step = 50;

  is_disabled = true;

  is_checkbox_active = false;

  index_unassigned_amount = 0;

  is_loading = true;

  is_success_modal_open = false;

  private search_by_customer_dto: SearchByCustomerDto = {
    reload: true,
    associated_product_id: '',
    is_active: true,
  };

  private active_goals: Array<SearchByCustomerEntity> = [];

  distribute_state = this.distribute_state_manager.state;

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get deposit_amount_formatted() {
    return currencyFormat(this.deposit_amount);
  }

  getIconMaxWidth = (icon_goal_name: string) => ((icon_goal_name === 'icon-add.svg') ? '28px' : '21px');

  getAmountFormatted = (amount: number) => (currencyFormat(amount));

  getCustomizedModerateClassForMainVCol = (is_moderate: boolean) => ((is_moderate) ? 'white--text primary rounded-t-lg' : '');

  getCustomizedModerateBackgroundColorForVTextField = (is_moderate: boolean) => ((is_moderate) ? 'primary' : '');

  getCustomizedModerateClassForVTextField = (is_moderate: boolean) => ((is_moderate) ? 'white-font' : '');

  getGoalData = (goal: SearchByCustomerEntity) => {
    if (goal.custom_investor_goal) {
      return {
        name: goal.custom_investor_goal.goal_name,
        label: goal.custom_investor_goal.goal_name,
        icon: goal.custom_investor_goal.custom_goal_type.icon_name,
        alt: 'alts.custom',
      };
    }

    return {
      name: goal.investment_goal_type.name,
      label: goal.investment_goal_type.label,
      icon: 'icon-retirement.svg',
      alt: 'alts.img_fund',
    };
  }

  loadActiveGoals = async (investment_product_id: string) => {
    try {
      this.search_by_customer_dto.associated_product_id = investment_product_id;
      this.active_goals = await this.get_search_by_customer_query
        .execute(this.search_by_customer_dto);
      this.active_goals.forEach((goal: SearchByCustomerEntity) => {
        const goal_data = this.getGoalData(goal);
        this.linked_goals.push({
          investor_goal_id: goal.investor_goal_id,
          name: goal_data.name,
          label: goal_data.label,
          icon: goal_data.icon,
          assigned_amount: 0,
          recommend_amount: goal.monthly_required_amount || 0,
          alt: goal_data.alt,
          assigned_amount_field: '0.00',
          is_moderate: verifyIfInvestorGoalIsLinkedWithModerate(
            goal.investment_product_fund_types?.linked || [],
          ),
          icon_path: getGoalIconPath(
            goal.investor_goal_id,
            goal_data.icon,
            goal.custom_investor_goal?.updated_at || '',
          ),
        });
      });
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_search_by_customer_query'));
    }
  }

  loadCustomerInvestorGoalBalance = async () => {
    try {
      if (this.search_by_customer_dto.associated_product_id) {
        const { unassigned_balance } = await this
          .get_customer_investor_goal_balance_query.execute(
            { investment_product_id: this.search_by_customer_dto.associated_product_id },
          );
        this.deposit_amount = unassigned_balance || 0;
      }
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_unassigned_balance'));
      }
    }
  }

  addItemUnassignedAmount = () => {
    this.linked_goals.push({
      investor_goal_id: '',
      name: 'unassigned',
      label: 'Saldo sin asignar',
      icon: 'noun-coins.svg',
      assigned_amount: this.deposit_amount,
      recommend_amount: 0,
      alt: 'alts.unassigned',
      assigned_amount_field: '0.00',
      is_moderate: false,
      icon_path: getGoalIconPath('', 'noun-coins.svg', ''),
    });
    this.index_unassigned_amount = this.linked_goals.length - 1;
  }

  incrementAmount = (index: number) => {
    let current_amount = this.linked_goals[index].assigned_amount;
    current_amount += this.step;
    if (this.deposit_amount >= current_amount) {
      this.linked_goals[index].assigned_amount += this.step;
      this.linked_goals[index].assigned_amount_field = this.linked_goals[index]
        .assigned_amount.toFixed(2);
      this.validateTotalAmounts(false);
    }
  }

  decrementAmount = (index: number) => {
    let current_amount = this.linked_goals[index].assigned_amount;
    current_amount -= this.step;
    if (this.linked_goals[index].assigned_amount > 0 && current_amount >= 0) {
      this.linked_goals[index].assigned_amount -= this.step;
      this.linked_goals[index].assigned_amount_field = this.linked_goals[index]
        .assigned_amount.toFixed(2);
      this.validateTotalAmounts(true);
    }
  }

  addUnassignedAmount = (total_amount_goals: number) => {
    if (total_amount_goals < this.deposit_amount) {
      const res = this.deposit_amount - total_amount_goals;
      if (res >= this.step) {
        this.linked_goals[this.index_unassigned_amount].assigned_amount += this.step;
      }
    }
  }

  subtractUnassignedAmount = (total_amount_goals: number) => {
    if (total_amount_goals <= this.deposit_amount) {
      this.linked_goals[this.index_unassigned_amount].assigned_amount -= this.step;
    }
  }

  validateTotalAmounts = (is_add: boolean, amount_was_edited_field = false) => {
    const linked_goals_assigned_amount = this.linked_goals.map(
      (obj, index) => ((index !== this.index_unassigned_amount) ? obj.assigned_amount : 0),
    );
    let total_amount_goals = linked_goals_assigned_amount.reduce(
      (total, currentValue) => total + currentValue, 0,
    );
    if (!amount_was_edited_field) {
      if (is_add) {
        this.addUnassignedAmount(total_amount_goals);
      } else {
        this.subtractUnassignedAmount(total_amount_goals);
      }
    }
    total_amount_goals += this.linked_goals[this.index_unassigned_amount].assigned_amount;
    this.is_disabled = total_amount_goals !== this.deposit_amount
    || this.linked_goals[this.index_unassigned_amount].assigned_amount === this.deposit_amount;
  }

  changeAssignedAmountField = (index: number) => {
    const parsed_assigned_amount_field = (this.linked_goals[index].assigned_amount_field)
      ? Number(parseFloat(this.linked_goals[index].assigned_amount_field.replaceAll(/[^\d.-]/g, '')))
      : 0;
    const is_add = (parsed_assigned_amount_field > this.linked_goals[index].assigned_amount);
    // eslint-disable-next-line max-len
    if (parsed_assigned_amount_field >= 0 && this.deposit_amount >= parsed_assigned_amount_field) {
      this.linked_goals[index].assigned_amount_field = parsed_assigned_amount_field.toFixed(2);
      this.linked_goals[index].assigned_amount = parsed_assigned_amount_field;
    } else {
      this.linked_goals[index].assigned_amount_field = '0.00';
      this.linked_goals[index].assigned_amount = 0;
    }
    const linked_goals_assigned_amount = this.linked_goals.map(
      (obj, position) => ((position !== this.index_unassigned_amount) ? obj.assigned_amount : 0),
    );
    const total_amount_goals = linked_goals_assigned_amount.reduce(
      (total, currentValue) => total + currentValue, 0,
    );
    if (total_amount_goals <= this.deposit_amount) {
      this.linked_goals[this.index_unassigned_amount]
        .assigned_amount = this.deposit_amount - total_amount_goals;
    }
    this.validateTotalAmounts(!is_add, true);
  }

  distributeDepositAmountGoals = () => {
    if (this.is_checkbox_active) {
      let total_distribute_amount = 0;
      const size_linked_goals = this.linked_goals.length - 1;
      const amount_equally = this.deposit_amount / size_linked_goals;
      this.linked_goals.forEach((item, index) => {
        if (index !== this.index_unassigned_amount) {
          // eslint-disable-next-line no-param-reassign
          item.assigned_amount = Number(amount_equally.toFixed(4));
          // eslint-disable-next-line no-param-reassign
          item.assigned_amount_field = item.assigned_amount.toFixed(2);
          total_distribute_amount += item.assigned_amount;
        }
      });
      this.linked_goals[this.index_unassigned_amount]
        .assigned_amount = this.deposit_amount - total_distribute_amount;
      this.is_disabled = false;
    }
  }

  distributeBalance = async () => {
    try {
      const investor_goals: Array<InvestorGoal> = [];
      let unassigned_balance = 0;
      let total_distributed_balance = 0;
      this.linked_goals.forEach((goal) => {
        if (goal.investor_goal_id && goal.assigned_amount > 0) {
          investor_goals.push(
            {
              investor_goal_id: goal.investor_goal_id,
              amount_to_distribute: goal.assigned_amount.toString(),
            },
          );
          total_distributed_balance += goal.assigned_amount;
        } else {
          unassigned_balance = goal.assigned_amount;
        }
      });
      await this.distribute_unassigned_balance_command
        .execute({
          investment_product_id: this.search_by_customer_dto.associated_product_id,
          investor_goals,
          remaining_unassigned_balance: unassigned_balance.toFixed(6),
          total_distributed_balance: total_distributed_balance.toString(),
        });
      this.view.$emit('distributedBalance');
      this.distribute_state.distributed_goals = investor_goals;
      this.distribute_state.distributed = true;
      this.is_success_modal_open = true;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.distribute_balance'));
    }
  }

  initialize = async (product_id: string) => {
    this.is_loading = true;
    this.distribute_state.distributed = false;
    this.distribute_state.distributed_goals = [];
    await this.loadActiveGoals(product_id);
    await this.loadCustomerInvestorGoalBalance();
    this.addItemUnassignedAmount();
    this.is_loading = false;
  }
}
