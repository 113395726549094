













































import { Component, PropSync, Vue } from 'vue-property-decorator';

import InvestorGoalCreatedSuccessfullyViewModel from
  '@/vue-app/view-models/components/goals-dashboard/create-goal/investor-goal-created-successfully-view-model';

@Component({ name: 'InvestorGoalCreatedSuccessfully' })
export default class InvestorGoalCreatedSuccessfully extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  view_model = Vue.observable(
    new InvestorGoalCreatedSuccessfullyViewModel(this),
  );

  created() {
    this.view_model.initialize();
  }

  contractProduct() {
    this.synced_is_open = false;
    this.view_model.contractProduct();
  }
}
