import TYPES from '@/types';
import Vue from 'vue';
import { currencyFormat } from '@/vue-app/utils/currency';
import { getGoalIconPath } from '@/vue-app/utils/goal-icon-path';

// Application
import GetGoalTrackingQuery from '@/modules/flagship/investor-goal/goal-tracking/application/queries/get-goal-tracking-query';
import {
  GetCustomerKuspitPositionDetailsQuery,
} from '@/modules/my-investment/kuspit/customer-kuspit-position/application/queries';
import GetModerateIsContractedQuery
  from '@/modules/flagship/customer-investment-product-fund-type/application/queries/get-moderate-is-contracted-query';
import GetInvestorGoalDetailQuery
  from '@/modules/flagship/investor-goal/investor-goal-detail/application/queries/get-investor-goal-detail-query';

// Domain
import { GoalTrackingEntity } from '@/modules/flagship/investor-goal/goal-tracking/domain/entities/goal-tracking-entity';
import {
  InvestmentProductFundTypesEntity,
} from '@/modules/flagship/investor-goal/search-by-customer/domain/entities/search-by-customer-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

type CustomGoalInformation = {
  investor_goal_id: string;
  is_custom_goal_pocket: boolean;
  name: string;
  icon_name: string;
  strategy_moderate_is_available: boolean;
  initial_amount: number;
}
export default class DetailsCustomModalViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.GET_GOAL_TRACKING_QUERY)
  private readonly get_goal_tracking_query!: GetGoalTrackingQuery;

  @Inject(TYPES.GET_CUSTOMER_KUSPIT_POSITION_DETAILS_QUERY)
  private readonly get_customer_kuspit_position_details_query!:
    GetCustomerKuspitPositionDetailsQuery;

  @Inject(TYPES.GET_MODERATE_IS_CONTRACTED_QUERY)
  private readonly get_moderate_is_contracted_query!: GetModerateIsContractedQuery;

  @Inject(TYPES.GET_INVESTOR_GOAL_DETAIL_QUERY)
  private readonly get_investor_goal_detail!: GetInvestorGoalDetailQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.goals-dashboard.details-goals.details-custom-modal';

  is_loading = false;

  investor_goal_id = '';

  initial_amount = 0;

  goal_tracking: GoalTrackingEntity = {
    current_goal_balance: 0,
    expected_goal_balance: 0,
    goal_target_balance: 0,
    goal_tracking_percent: 0,
  };

  message = '';

  img = 'goals-dashboard/details-goals/be_careful.svg';

  should_have_percent = 0;

  is_custom_goal_pocket = false;

  correct_messages_pocket_wealth = {
    linked_with: '',
    strategy: '',
    tooltip: '',
    name: '',
    icon_name: '',
  };

  color_sws = {
    color_card: '',
    color_title: 'primary--text',
    color_subtitle: 'sky-blue--text',
    color_divider: '',
    color_icon: 'sky-blue',
    color_btn: 'white--text',
    color_progress: 'accent',
  };

  show_modify_strategy_moderate_btn = false;

  show_moderate_btn = false;

  show_contracting_status_moderate_dialog = false;

  show_transfer_status_moderate = false;

  show_transfer_status_dialog = false;

  disabled_show_moderate_btn = false;

  link_status = '';

  contracting_status_moderate = '';

  contracting_moderate_goal_id = '';

  contracting_moderate_goal_name = '';

  button_text_transfer = '';

  custom_icon_file_id = '';

  custom_goal_updated_at_time_stamp = 0;

  icon_path = '';

  display_tooltip = true;

  readonly view: Vue;

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async (
    custom_goal_information: CustomGoalInformation,
    strategy_option: string,
    investment_product_fund_types: InvestmentProductFundTypesEntity,
    custom_icon_file_id: string,
    custom_goal_updated_at_time_stamp: number,
  ) => {
    this.is_loading = true;
    this.custom_icon_file_id = custom_icon_file_id;
    this.custom_goal_updated_at_time_stamp = custom_goal_updated_at_time_stamp;
    await this.setCustomGoalInformation(custom_goal_information);
    if (this.investor_goal_id) {
      await this.loadProgressGoalInformation();
    }

    await this.changeStrategyOption(
      strategy_option, custom_goal_information.investor_goal_id,
    );
    this.is_loading = false;
  }

  get icon_max_width() {
    return (this.correct_messages_pocket_wealth.icon_name === 'icon-add.svg' && this.custom_icon_file_id) ? '50px' : '40px';
  }

  verifyContractStatusOfModerate = async (investor_goal_id: string) => {
    try {
      const { moderate_status, moderate_available } = await this.get_investor_goal_detail
        .execute(investor_goal_id);

      if (moderate_available) {
        const {
          status,
          opening_goal_id,
          opening_goal_name,
        } = await this
          .get_moderate_is_contracted_query.execute();

        if (status && status !== 'contracted') {
          this.button_text_transfer = this.translate('contract_status');
          this.show_transfer_status_moderate = true;
          this.contracting_status_moderate = status;
          this.contracting_moderate_goal_id = opening_goal_id;
          this.contracting_moderate_goal_name = opening_goal_name;
        } else if (moderate_status && moderate_status !== 'linked') {
          this.button_text_transfer = this.translate('transfer_status');
          this.show_transfer_status_moderate = true;
          this.link_status = moderate_status;
        } else {
          this.show_moderate_btn = true;
        }
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.verify_contract_status_moderate'));
    }
  }

  verifyLinkedStatus = async (investor_goal_id: string) => {
    try {
      const { moderate_status } = await this.get_investor_goal_detail
        .execute(investor_goal_id);

      if (moderate_status && moderate_status !== 'linked') {
        this.link_status = moderate_status;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.verify_contract_status_moderate'));
    }
  }

  changeStrategyOption = async (
    strategy_option: string,
    investor_goal_id: string,
  ) => {
    if (strategy_option === 'moderate') {
      this.changeColorSwsModerate();
      this.show_modify_strategy_moderate_btn = true;
      this.show_moderate_btn = false;
      this.correct_messages_pocket_wealth.strategy = this.translate('strategy_moderate');
      this.correct_messages_pocket_wealth.tooltip = this.translate('tooltip_strategy_moderate');
      await this.verifyLinkedStatus(investor_goal_id);
    }
    if (!this.is_custom_goal_pocket && strategy_option !== 'moderate') {
      await this.verifyContractStatusOfModerate(investor_goal_id);
    }
  }

  changeColorSwsModerate = () => {
    this.color_sws.color_card = 'primary';
    this.color_sws.color_title = 'white--text';
    this.color_sws.color_subtitle = 'white--text';
    this.color_sws.color_divider = 'white';
    this.color_sws.color_icon = 'white';
    this.color_sws.color_btn = 'accent--text';
    this.color_sws.color_progress = 'white';
  }

  getAmountFormatted(amount: number) {
    return `${currencyFormat(amount)} MXN`;
  }

  setPocketStrategy = async () => {
    try {
      const kuspit_position = await this.get_customer_kuspit_position_details_query.execute();
      if (kuspit_position && kuspit_position.funds) {
        const not_default_strategy = kuspit_position.funds.filter((fund) => fund.name !== 'Fondo Barrido');
        if (not_default_strategy.length) {
          this.correct_messages_pocket_wealth.tooltip = this.translate('tooltip_strategy_pocket_not_default');
          this.correct_messages_pocket_wealth.strategy = this.translate('other_strategy_pocket');
        } else {
          this.correct_messages_pocket_wealth.strategy = this.translate('strategy_pocket');
          this.correct_messages_pocket_wealth.tooltip = this.translate('tooltip_strategy_pocket');
        }
      }
    } catch (error) {
      this.correct_messages_pocket_wealth.strategy = this.translate('strategy_pocket');
      this.correct_messages_pocket_wealth.tooltip = this.translate('tooltip_strategy_pocket');
    }
  }

  setCustomGoalInformation = async (custom_goal_information: CustomGoalInformation) => {
    this.investor_goal_id = custom_goal_information.investor_goal_id;
    this.initial_amount = custom_goal_information.initial_amount;
    this.correct_messages_pocket_wealth.name = custom_goal_information.name;
    this.correct_messages_pocket_wealth.icon_name = custom_goal_information.icon_name;
    this.is_custom_goal_pocket = custom_goal_information.is_custom_goal_pocket;
    if (custom_goal_information.is_custom_goal_pocket) {
      this.correct_messages_pocket_wealth.linked_with = this.translate('linked_pocket');
      await this.setPocketStrategy();
    } else {
      this.correct_messages_pocket_wealth.linked_with = this.translate('linked_wealth');
      this.correct_messages_pocket_wealth.strategy = this.translate('strategy_wealth');
      this.correct_messages_pocket_wealth.tooltip = this.translate('tooltip_strategy_wealth');
    }
    this.icon_path = getGoalIconPath(
      this.custom_icon_file_id,
      this.correct_messages_pocket_wealth.icon_name,
      String(this.custom_goal_updated_at_time_stamp),
    );
  }

  loadProgressGoalInformation = async () => {
    try {
      this.goal_tracking = await this.get_goal_tracking_query.execute(this.investor_goal_id);
      this.setGoalProgress(this.goal_tracking);
      this.setGoalProgressStatus(this.goal_tracking.goal_tracking_percent);
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_progress_goal_information'));
      }
    }
  }

  calculateGoalTrackingPercent = (goal_tracking: GoalTrackingEntity) => {
    this.goal_tracking.goal_tracking_percent = (
      goal_tracking.goal_tracking_percent > 100
    ) ? 100 : Number(goal_tracking.goal_tracking_percent.toFixed(0));
  }

  calculateShouldHavePercent = (goal_tracking: GoalTrackingEntity) => {
    this.should_have_percent = ((goal_tracking.expected_goal_balance * 100) / goal_tracking
      .goal_target_balance);
  }

  setGoalProgress = (goal_tracking: GoalTrackingEntity) => {
    this.calculateGoalTrackingPercent(goal_tracking);
    this.calculateShouldHavePercent(goal_tracking);
  }

  setGoalProgressStatus = (percent: number) => {
    switch (true) {
      case percent >= 80:
        this.message = this.translate('keep_it_up');
        this.img = 'goals-dashboard/details-goals/keep_it_up.svg';
        break;
      case (percent >= 65 && percent < 80):
        this.message = this.translate('youre_doing_well');
        this.img = 'goals-dashboard/details-goals/youre_doing_well.svg';
        break;
      case (percent >= 50 && percent < 65):
        this.message = this.translate('you_can_do_better');
        this.img = 'goals-dashboard/details-goals/you_can_do_better.svg';
        break;
      default:
        this.message = this.translate('be_careful');
        this.img = 'goals-dashboard/details-goals/be_careful.svg';
        break;
    }
  }

  upgradeToModerate = () => {
    this.view.$emit('upgradeToModerate', {
      investor_goal_id: this.investor_goal_id,
      investor_goal_name: this.correct_messages_pocket_wealth.name,
    });
  }

  modifyStrategy = () => {
    this.view.$emit('modifyStrategy', this.link_status);
  }

  showTransferStatusModerate = () => {
    if (this.contracting_status_moderate) {
      this.show_contracting_status_moderate_dialog = true;
    }
    if (this.link_status) {
      this.show_transfer_status_dialog = true;
    }
  }

  pauseTracking = () => {
    this.view.$emit('pauseTracking');
  }

  resumeTracking = () => {
    this.view.$emit('resumeTracking');
  }
}
