
















































import { Component, PropSync, Vue } from 'vue-property-decorator';

import BucketShortTimeCreatedSuccessfullyViewModel from
  '@/vue-app/view-models/components/goals-dashboard/create-goal/bucket/bucket-short-time-created-successfully-view-model';

@Component({ name: 'BucketShortTimeCreatedSuccessfully' })
export default class BucketShortTimeCreatedSuccessfully extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  view_model = Vue.observable(
    new BucketShortTimeCreatedSuccessfullyViewModel(this),
  );
}
