import Vue from 'vue';
import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';
import { maxValueRule, minValueRule, requiredRule } from '@/vue-app/utils/form-rules';
import { parseCurrencyToNumber } from '@/vue-app/utils/parse-currency-to-number';

// Application
import CalculateCustomGoalMaximumMonthsQuery
  from '@/modules/flagship/custom-investor-goal-calculator/application/queries/calculate-custom-goal-maximum-months-query';
import GetEmergencyFundInvestorGoalCalculatorQuery
  from '@/modules/flagship/emergency-fund-investor-goal-calculator/application/queries/get-emergency-fund-investor-goal-calculator-query';
import UpdateInvestorProfileCommand
  from '@/modules/flagship/investor-profile/investor-profile/application/commands/update-investor-profile-command';

// Domain
import {
  CreateInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/create-investor-goal-state-manager';
import { EmergencyFundInvestorGoalCalculatorDto }
  from '@/modules/flagship/emergency-fund-investor-goal-calculator/domain/dtos/emergency-fund-investor-goal-calculator-dto';
import {
  EmergencyFundInvestorGoalCalculatorEntity,
} from '@/modules/flagship/emergency-fund-investor-goal-calculator/domain/entities/emergency-fund-investor-goal-calculator-entity';
import {
  CustomGoalMaximumMonthsCalculationDto,
} from '@/modules/flagship/custom-investor-goal-calculator/domain/dtos/custom-goal-maximum-months-dto';
import {
  InvestorProfileDto,
} from '@/modules/flagship/investor-profile/investor-profile/domain/dtos/investor-profile-dto';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class EmergencyFundContributionsViewModel {
  @Inject(TYPES.CALCULATE_CUSTOM_GOAL_MAXIMUM_MONTHS_QUERY)
  private readonly calculate_custom_goal_maximum_months_query!:
    CalculateCustomGoalMaximumMonthsQuery;

  @Inject(TYPES.GET_EMERGENCY_FUND_INVESTOR_GOAL_CALCULATOR_QUERY)
  private readonly get_emergency_fund_calculator_query!:
    GetEmergencyFundInvestorGoalCalculatorQuery;

  @Inject(TYPES.UPDATE_INVESTOR_PROFILE_COMMAND)
  private readonly update_investor_profile_command!: UpdateInvestorProfileCommand;

  @Inject(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
  readonly create_investor_goal_state_manager!: CreateInvestorGoalStateManager;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.flagship.flagship-goals.create-goal.emergency-fund.contributions';

  readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  investor_goal_state = this.create_investor_goal_state_manager.state;

  timer?: NodeJS.Timer;

  min_months_to_reach_goal = 1;

  max_months_to_reach_goal = 48;

  max_contribution = 0;

  min_contribution = 1;

  calculated_maximum_months = 0;

  is_valid_form = true;

  is_loading_monthly_payment = false;

  show_want_years = false;

  input_rules = {
    contribution: [
      requiredRule,
      (value: string) => minValueRule(
        value.replace(/[^0-9.-]/g, ''),
        `${this.min_contribution}.00 MXN`,
        this.min_contribution,
      ),
      (value: string) => maxValueRule(
        value.replace(/[^0-9.-]/g, ''),
        `${this.max_contribution} MXN`,
        this.max_contribution,
      ),
    ],
  };

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values)

  get is_continue_btn_disabled() {
    return !this.is_valid_form || this.is_loading_monthly_payment || this
      .investor_goal_state.is_loading;
  }

  get btn_years_text() {
    return this.translate(this.investor_goal_state.wants_years ? 'less_than_one_year' : 'more_than_one_year');
  }

  getCustomClassOpacity = (is_option_by_time: boolean) => {
    if (!this.investor_goal_state.is_defined_by_contribution && !is_option_by_time) return 'opacity';
    if (this.investor_goal_state.is_defined_by_contribution && is_option_by_time) return 'opacity';
    return '';
  }

  getAmountFormatted(amount: number) {
    return currencyFormat(amount);
  }

  updateInvestorProfile = async () => {
    try {
      const investor_profile_dto: InvestorProfileDto = {
        id: this.investor_goal_state.investor_goal.investor_profile_id,
        emergency_fund_investor_goal: {
          months_of_monthly_incomes: this.investor_goal_state.emergency_fund
            .months_of_monthly_incomes,
          initial_amount: this.investor_goal_state.investor_goal.initial_amount,
          desired_amount: this.investor_goal_state.investor_goal.desired_amount,
          monthly_required_amount: this.investor_goal_state.investor_goal.monthly_required_amount,
          fixed_time_adjusted: this.investor_goal_state.investor_goal.fixed_time_adjusted,
          accumulated_amount: this.investor_goal_state.investor_goal.accumulated_amount,
          defined_by: this.investor_goal_state.emergency_fund.defined_by,
          last_step: 'EmergencyFundContributions',
        },
      };
      await this.update_investor_profile_command.execute(investor_profile_dto);
      return true;
    } catch (e) {
      this.message_notifier.showErrorNotification(this.translate('errors.update_investor_profile'));
      return false;
    }
  };

  getFixedTimeAdjusted = () => {
    if (!this.investor_goal_state.is_defined_by_contribution) {
      return this.calculateMonths();
    }
    return this.investor_goal_state.emergency_fund.months_to_reach_goal;
  }

  setState = () => {
    this.investor_goal_state.investor_goal.fixed_time_adjusted = this.getFixedTimeAdjusted();
    this.investor_goal_state.investor_goal.monthly_required_amount = parseCurrencyToNumber(
      this.investor_goal_state.emergency_fund.contribution,
    );
    this.investor_goal_state.investor_goal.accumulated_amount = this
      .investor_goal_state.emergency_fund.accumulated_amount;
    return this.updateInvestorProfile();
  };

  setInitialValues = async () => {
    await this.monthlyPaymentCalculation();
  }

  calculateMonths = () => (this.investor_goal_state.wants_years ? this
    .investor_goal_state.emergency_fund.months_to_reach_goal * 12
    : this.investor_goal_state.emergency_fund.months_to_reach_goal);

  getDefinedBy = () => (this.investor_goal_state.is_defined_by_contribution
    ? 'contribution' : 'time');

  getMonthlyRequiredAmount = () => (this.investor_goal_state.is_defined_by_contribution
    ? parseCurrencyToNumber(this.investor_goal_state.emergency_fund.contribution) : 0);

  setInputs = (calculation: EmergencyFundInvestorGoalCalculatorEntity) => {
    const { emergency_fund_calculation } = calculation;
    const {
      accumulated_amount, monthly_required_amount, fixed_time_adjusted,
    } = emergency_fund_calculation;
    if (this.investor_goal_state.is_defined_by_contribution) {
      this.investor_goal_state.emergency_fund.months_to_reach_goal = Math.ceil(fixed_time_adjusted);
    } else {
      this.investor_goal_state.emergency_fund.contribution = Math.ceil(monthly_required_amount)
        .toString();
    }
    this.investor_goal_state.emergency_fund.accumulated_amount = Math.round(accumulated_amount);
  }

  monthlyPaymentCalculation = async () => {
    try {
      this.is_loading_monthly_payment = true;
      const months = this.calculateMonths();
      if (this.is_valid_form) {
        const payload: EmergencyFundInvestorGoalCalculatorDto = {
          associated_product_id: this.investor_goal_state.associated_product_id,
          defined_by: this.getDefinedBy(),
          desired_amount: this.investor_goal_state.investor_goal.desired_amount,
          initial_amount: this.investor_goal_state.investor_goal.initial_amount,
          monthly_required_amount: this.getMonthlyRequiredAmount(),
          fixed_time_adjusted: this.investor_goal_state.is_defined_by_contribution ? 0 : months,
        };
        const calculated_data = await this.get_emergency_fund_calculator_query.execute(payload);
        this.setInputs(calculated_data);
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_monthly_payment'));
    } finally {
      this.is_loading_monthly_payment = false;
    }
  }

  calculateMaximumMonths = async () => {
    try {
      this.investor_goal_state.is_loading = true;
      const payload: CustomGoalMaximumMonthsCalculationDto = {
        monthly_required_amount: 1,
        initial_amount: this.investor_goal_state.investor_goal.initial_amount,
        desired_amount: this.investor_goal_state.investor_goal.desired_amount,
        interest_rate: this.investor_goal_state.associated_product_interest_rate / 100,
      };
      const calculation = await this
        .calculate_custom_goal_maximum_months_query.internalExecute(payload);
      const maximum_months_calculated = Math.ceil(parseFloat(calculation.maximum_months));
      this.calculated_maximum_months = maximum_months_calculated;
      this.max_months_to_reach_goal = maximum_months_calculated > 11
        ? 11 : maximum_months_calculated;
      this.show_want_years = maximum_months_calculated > 11;
      if (this.view.$refs.form) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.view.$refs.form.validate();
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_minimum_accumulated_amount'));
    } finally {
      this.investor_goal_state.is_loading = false;
    }
  }

  calculateMinimumMonthlyAmount = async () => {
    try {
      this.is_loading_monthly_payment = true;
      const payload: EmergencyFundInvestorGoalCalculatorDto = {
        associated_product_id: this.investor_goal_state.associated_product_id,
        defined_by: 'time',
        desired_amount: this.investor_goal_state.investor_goal.desired_amount,
        initial_amount: this.investor_goal_state.investor_goal.initial_amount,
        monthly_required_amount: 0,
        fixed_time_adjusted: 48,
      };
      const calculated_data = await this
        .get_emergency_fund_calculator_query.execute(payload);
      this.min_contribution = Math.ceil(
        calculated_data.emergency_fund_calculation.monthly_required_amount,
      );
      if (this.view.$refs.form) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.view.$refs.form.validate();
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_monthly_payment'));
    } finally {
      this.is_loading_monthly_payment = false;
    }
  }

  calculateMaximumMonthlyAmount = async () => {
    try {
      this.is_loading_monthly_payment = true;
      const payload: EmergencyFundInvestorGoalCalculatorDto = {
        associated_product_id: this.investor_goal_state.associated_product_id,
        defined_by: 'time',
        desired_amount: this.investor_goal_state.investor_goal.desired_amount,
        initial_amount: this.investor_goal_state.investor_goal.initial_amount,
        monthly_required_amount: 1,
        fixed_time_adjusted: 1,
      };
      const calculated_data = await this
        .get_emergency_fund_calculator_query.execute(payload);
      this.max_contribution = Math.ceil(
        calculated_data.emergency_fund_calculation.monthly_required_amount,
      );
      if (this.view.$refs.form) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.view.$refs.form.validate();
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_monthly_payment'));
    } finally {
      this.is_loading_monthly_payment = false;
    }
  }

  delay = () => {
    this.is_loading_monthly_payment = true;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.timer = setTimeout(async () => {
      await this.monthlyPaymentCalculation();
    }, 2000);
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  toggleWantsYears = async () => {
    this.investor_goal_state.emergency_fund.months_to_reach_goal = 1;
    this.investor_goal_state.wants_years = !this.investor_goal_state.wants_years;
    this.investor_goal_state.investor_goal.fixed_time_adjusted = this.calculateMonths();
    await this.monthlyPaymentCalculation();
  }

  updateDefinedBy = async () => {
    this.investor_goal_state.emergency_fund.defined_by = this.getDefinedBy();
    if (!this.investor_goal_state.is_defined_by_contribution) {
      if (this.investor_goal_state.emergency_fund.months_to_reach_goal > 11) {
        this.investor_goal_state.wants_years = true;
        this.investor_goal_state.emergency_fund.months_to_reach_goal = Math.floor(
          this.investor_goal_state.emergency_fund.months_to_reach_goal / 12,
        );
      } else {
        this.investor_goal_state.wants_years = false;
      }
    }
    await this.monthlyPaymentCalculation();
  }

  nextStep = async () => {
    this.investor_goal_state.is_loading = true;
    if (await this.setState()) {
      this.view.$emit('nextStep');
    }
    this.investor_goal_state.is_loading = false;
  }

  initialize = async () => {
    await this.calculateMaximumMonths();
    await this.calculateMaximumMonthlyAmount();
    await this.calculateMinimumMonthlyAmount();
    await this.setInitialValues();
  }
}
