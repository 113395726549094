import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';
import { currencyFormat } from '@/vue-app/utils/currency';

// Infrastructure
import CustomerContractedInvestmentProductsVueStateManager
  from '@/modules/flagship/customer-contracted-investment-products/infraestructure/state/customer-contracted-investment-products-vue-state-manager';

// Application
import GetEmergencyFundInvestorGoalQuery from '@/modules/flagship/emergency-fund-investor-goal/application/query/get-emergency-fund-investor-goal-query';

// Domain
import {
  CreateInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/create-investor-goal-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class EmergencyFundCardViewModel {
  @Inject(TYPES.CUSTOMER_CONTRACTED_INVESTMENT_PRODUCTS_STATE_MANAGER)
  // eslint-disable-next-line max-len
  private readonly customer_contracted_products_state!: CustomerContractedInvestmentProductsVueStateManager;

  @Inject(TYPES.GET_EMERGENCY_FUND_INVESTOR_GOAL_QUERY)
  private readonly get_emergency_fund_goal_query!: GetEmergencyFundInvestorGoalQuery;

  @Inject(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
  readonly create_investor_goal_state_manager!: CreateInvestorGoalStateManager;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.goals-dashboard.emergency-fund-card';

  is_loading = false;

  is_active = false;

  associated_contract = false;

  associated_product_id = '';

  fixed_time_adjusted = 0;

  accumulated_amount = '';

  monthly_required_amount = '';

  final_investment_date = '';

  show_flagship_link_pocket_wizard = false;

  show_step_success_pocket = false;

  modal_prepare_documents_pocket = {
    is_open: false,
  };

  bg_image = {
    backgroundImage: '',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }

  investor_goal_state = this.create_investor_goal_state_manager.state;

  translate = (value: string, options?: Record<string, string | number>) => {
    if (options) {
      return i18n.tc(`${this.i18n_namespace}.${value}`, 0, options) as string;
    }
    return i18n.t(`${this.i18n_namespace}.${value}`) as string;
  }

  initialize = async () => {
    this.is_loading = true;
    await this.loadEmergencyFundInvestorGoalInformation();
    await this.loadCustomerContractedProductsInformation();
    this.is_loading = false;
  }

  showFlagshipLinkPocketWizard = () => {
    this.show_flagship_link_pocket_wizard = true;
  }

  showModalPrepareDocumentsPocket = () => {
    this.modal_prepare_documents_pocket.is_open = true;
  }

  loadEmergencyFundInvestorGoalInformation = async () => {
    try {
      const {
        investor_goal,
        fixed_time_adjusted,
      } = await this.get_emergency_fund_goal_query.execute();

      if (investor_goal) {
        this.is_active = investor_goal.is_active;
        this.monthly_required_amount = `${currencyFormat(investor_goal.monthly_required_amount)} MXN`;
        this.accumulated_amount = `${currencyFormat(investor_goal.accumulated_amount)} MXN`;
        this.associated_product_id = investor_goal.associated_product_id;
        this.final_investment_date = investor_goal.final_investment_date;
        if (investor_goal.final_investment_date) {
          this.investor_goal_state.emergency_fund.already_created = true;
        }
      }

      this.fixed_time_adjusted = fixed_time_adjusted;
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_emergency_fund_information'));
      }
    }
  }

  loadCustomerContractedProductsInformation = () => {
    try {
      const { has_sowos_pocket_contracted } = this.customer_contracted_products_state.state.item;
      this.associated_contract = has_sowos_pocket_contracted;
      if (!this.associated_contract) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
        const bgImage = require('@/assets/images/goals-dashboard/goal_pocket.png');
        this.bg_image.backgroundImage = `url(${bgImage})`;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_customer_contracted_products'));
    }
  }

  goalLinkedToPocket = () => {
    this.show_step_success_pocket = true;
  }
}
