import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import PauseTrackingReasonsHttpRepository
  from './infrastructure/repositories/pause-tracking-reasons-http-repository';
import PauseTrackingReasonsVueStateManager
  from './infrastructure/state/pause-tracking-reasons-vue-state-manager';

// Application
import GetPauseTrackingReasonsQuery
  from './application/queries/get-pause-tracking-reasons-query';

// Domain
import {
  PauseTrackingReasonRepository,
} from './domain/repositories/pause-tracking-reason-repository';
import {
  PauseTrackingReasonsStateManager,
} from './domain/state/pause-tracking-reasons-state-manager';

export default function setupRiskToleranceQuestions(container: Container) {
  container
    .bind<PauseTrackingReasonsStateManager>(TYPES.PAUSE_TRACKING_REASONS_STATE_MANAGER)
    .to(PauseTrackingReasonsVueStateManager)
    .inSingletonScope();

  container
    .bind<PauseTrackingReasonRepository>(
      TYPES.PAUSE_TRACKING_REASONS_REPOSITORY,
    ).to(PauseTrackingReasonsHttpRepository)
    .inSingletonScope();

  container
    .bind<GetPauseTrackingReasonsQuery>(TYPES.GET_PAUSE_TRACKING_REASONS_QUERY)
    .to(GetPauseTrackingReasonsQuery)
    .inSingletonScope();
}
