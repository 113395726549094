// Domain
import {
  InvestorProfileEntity,
} from '@/modules/flagship/investor-profile/investor-profile/domain/entities/investor-profile-entity';

export default class InvestorProfileState {
  loaded = false;

  investor_profile: InvestorProfileEntity = {
    id: '',
    person_id: '',
    employment_situation_id: '',
    customer_id: '',
    recommended_investment_product_id: '',
    emergency_fund_availability_id: '',
    has_emergency_fund: false,
    net_monthly_expenses: 0,
    is_showed: false,
    elder_dependent_count: 0,
    is_completed: false,
    child_dependent_count: 0,
    custom_goals: {},
    pet_dependent_count: 0,
    net_monthly_income: 0,
    active_goals_count: 0,
    goals_completed: false,
    emergency_fund_locations: {},
    retirement_fund_locations: {},
    exit_pool: {},
    finance_related_job: false,
    income_behavior_in_following_year_id: '',
    emergency_fund_amount: 0,
    has_retirement_fund: null,
    emergency_fund_saved: '',
    emergency_fund_investor_goal: {},
    retirement_investor_goal: {},
    has_knowledge_of_retirement: null,
  };
}
