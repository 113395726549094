
































































































import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';
import ChooseActionEditGoalDialogViewModel
  from '@/vue-app/view-models/components/goals-dashboard/edit-goals/choose-action-edit-goal-dialog-view-model';

@Component({ name: 'ChooseActionEditGoalDialog' })
export default class ChooseActionEditGoalDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  @PropSync('customGoalIcon', { type: String, required: true })
  custom_goal_icon!: string;

  @PropSync('customIconFileId', { type: String, required: true })
  custom_icon_file_id!: string;

  @PropSync('isABucket', { type: Boolean, required: true })
  is_a_bucket!: boolean;

  @PropSync('updatedAt', { type: String, required: true })
  updated_at!: string;

  choose_action_edit_goal_view_model = Vue.observable(
    new ChooseActionEditGoalDialogViewModel(this),
  );

  mounted() {
    this.choose_action_edit_goal_view_model
      .initialize(
        this.custom_goal_icon, this.custom_icon_file_id, this.updated_at, this.is_a_bucket,
      );
  }

  closeModal() {
    this.synced_is_open = false;
  }
}
