




































































































































































































































































































import { Component, PropSync, Vue } from 'vue-property-decorator';

import StepConfigureContributionsWealthViewModel
  from '@/vue-app/view-models/components/flagship/flagship-link-goals/steps-wealth/step-configure-contributions-wealth-view-model';

@Component({
  name: 'StepConfigureContributionsWealth',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class StepConfigureContributionsWealth extends Vue {
  @PropSync('associatedProductId', { type: String, required: true })
  associated_product_id!: string;

  @PropSync('goalsSelected', { type: Array, required: true })
  goals_selected!: Array<string>;

  @PropSync('goalsAmountInformation', { type: Array, required: true })
  goals_amount_information!: Array<object>;

  @PropSync('isLoading', { type: Boolean, required: true, default: false })
  is_loading!: boolean;

  step_configure_contributions_wealth_model = Vue.observable(
    new StepConfigureContributionsWealthViewModel(this),
  );

  created() {
    this.step_configure_contributions_wealth_model.initialize(
      this.associated_product_id, this.goals_selected, this.goals_amount_information,
    );
  }
}
