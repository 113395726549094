import TYPES from '@/types';

// Application
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';
import GetInvestmentProductsQuery from '@/modules/flagship/catalogs/investment-products/application/queries/get-investment-products-query';
import GetSearchByCustomerQuery from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';
import GetCustomInvestorGoalSearchByCustomerQuery from '@/modules/flagship/custom-investor-goal/application/query/get-custom-investor-goal-search-by-customer-query';
import GetCustomerContractedInvestmentProductsQuery
  from '@/modules/flagship/customer-contracted-investment-products/application/queries/get-customer-contracted-investment-products-query';
import GetOverallGoalsStatusQuery
  from '@/modules/flagship/investor-profile/overall-goals-status/application/queries/get-overall-goals-status-query';
import GetModerateIsContractedQuery
  from '@/modules/flagship/customer-investment-product-fund-type/application/queries/get-moderate-is-contracted-query';
import GetGoalDefinitionStepQuery
  from '@/modules/flagship/goal-definition-step/application/queries/get-goal-definition-step-query';
import AccessMoreStrategiesStateManagerService
  from '@/modules/my-investment/allianz/access-more-strategies/application/services/access-more-strategies-state-manager-service';

// Domain
import {
  CustomInvestorGoalEntity,
} from '@/modules/flagship/custom-investor-goal/domain/entities/custom-investor-goal-entity';
import {
  InvestmentProductsEntity,
} from '@/modules/flagship/catalogs/investment-products/domain/entities/investment-products-entity';
import {
  CreateInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/create-investor-goal-state-manager';
import {
  GoalsDashboardProgressStateManager,
} from '@/modules/goals-dashboard/progress/domain/state/goals-dashboard-progress-state-manager';
import {
  GoalsDashboardDistributeStateManager,
} from '@/modules/goals-dashboard/distribute-balance/domain/state/goals-dashboard-distribute-state-manager';
import {
  ManageTrackingInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/manage-tracking-investor-goal-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { Router } from '@/modules/shared/domain/router';

type InvestorGoalSelected = {
  investor_goal_id: string;
  investor_goal_name: string;
  status: string;
}

export default class GoalsDashboardViewModel {
  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.GET_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_investment_products_query!: GetInvestmentProductsQuery;

  @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.GET_CUSTOM_INVESTOR_GOAL_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_custom_investor_goal_search_by_customer!:
    GetCustomInvestorGoalSearchByCustomerQuery;

  @Inject(TYPES.GET_CUSTOMER_CONTRACTED_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_contracted_products_query!: GetCustomerContractedInvestmentProductsQuery;

  @Inject(TYPES.GET_OVERALL_GOALS_STATUS_QUERY)
  private readonly get_overall_goals_status_query!: GetOverallGoalsStatusQuery;

  @Inject(TYPES.GET_MODERATE_IS_CONTRACTED_QUERY)
  private readonly get_moderate_is_contracted_query!: GetModerateIsContractedQuery;

  @Inject(TYPES.GET_GOAL_DEFINITION_STEP_QUERY)
  private readonly get_goal_definition_step_query!: GetGoalDefinitionStepQuery;

  @Inject(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
  readonly create_investor_goal_state_manager!: CreateInvestorGoalStateManager;

  @Inject(TYPES.ACCESS_MORE_STRATEGIES_STATE_MANAGER_SERVICE)
  private readonly manager_service!: AccessMoreStrategiesStateManagerService;

  @Inject(TYPES.GOALS_DASHBOARD_PROGRESS_STATE_MANAGER)
  private readonly progress_state_manager!: GoalsDashboardProgressStateManager;

  @Inject(TYPES.GOALS_DASHBOARD_DISTRIBUTE_STATE_MANAGER)
  private readonly distribute_state_manager!: GoalsDashboardDistributeStateManager;

  @Inject(TYPES.MANAGE_TRACKING_INVESTOR_GOAL_STATE_MANAGER)
  readonly manage_tracking_investor_goal_state_manager!: ManageTrackingInvestorGoalStateManager;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.ROUTER)
  private readonly router!: Router;

  readonly i18n_namespace = 'components.goals-dashboard';

  readonly contracting_status = ['contracting_started', 'endorsement_sent_to_sign', 'uploaded_endorsement_on_sftp', 'balance_transfer_in_process'];

  is_linked = false;

  is_unlinked = false;

  custom_investor_goal: Array<CustomInvestorGoalEntity> = [];

  custom_investor_goal_linked: Array<CustomInvestorGoalEntity> = [];

  products_information_complete = false;

  emergency_fund_goal_linked = false;

  retirement_goal_linked = false;

  show_modify_moderate_dialog = false;

  show_unlink_status_dialog = false;

  unlink_status = '';

  show_link_moderate_dialog = false;

  show_contract_moderate_dialog = false;

  show_hiring_flow_dialog = false;

  show_exit_poll_dialog = false;

  show_contracting_status_moderate_dialog = false;

  moderate_is_contracted = false;

  contracting_status_moderate = '';

  investor_goal_selected = {
    investor_goal_id: '',
    investor_goal_name: '',
  }

  expansion_panel = 1;

  goals_loaded = false;

  total_goals = 0;

  has_contracted_products = {
    any: false,
    wealth: false,
    pocket: false,
  }

  investment_products: Array<InvestmentProductsEntity> = [];

  creation_goal_type = '';

  service = '';

  show_create_goal = false;

  display_contract_savings = false;

  display_contract_wealth_warning = false;

  show_investor_goal_linked = false;

  show_support_you_flow = false;

  show_support_you_goals_wizard = false;

  show_wealth_contribute_dialog = false;

  show_recommendation = {
    pocket: false,
    wealth: false,
    any: false,
  }

  investor_goal_state = this.create_investor_goal_state_manager.state;

  more_strategies_state = this.manager_service.getAccessMoreStrategiesState();

  progress_state = this.progress_state_manager.state;

  distribute_state = this.distribute_state_manager.state;

  manage_tracking_investor_goal_state = this
    .manage_tracking_investor_goal_state_manager.state;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get is_linked_to_moderate() {
    return this.more_strategies_state.strategy.label === 'SWSMOD'
      && (!this.contracting_status_moderate
      || (this.contracting_status_moderate === 'endorsement_sent_to_sign'
        || this.contracting_status_moderate === 'contracting_started'));
  }

  initialize = async (
    recommended_investment_product: string,
    contract_investment_product: string,
    create_goals: boolean,
  ) => {
    this.progress_state.is_loading = true;
    this.progress_state.recurrent_assigned_amount = 0;
    await this.obtainInvestorProfile();
    await this.loadCustomerContractedProductsInformation(
      recommended_investment_product,
      contract_investment_product,
    );
    await this.loadGoalsByInvestmentProductProduct();
    this.setProgressState();
    await this.loadAllCustomInvestorGoalInformation();
    this.displayUnlinkedGoalsByDefaultIfUserHasNotActiveGoals();
    this.showCreateGoals(create_goals);
  }

  setProgressState = () => {
    this.progress_state.show_progress_card = this.progress_state.has_any_product_contracted
      || this.progress_state.has_linked_goals;
    this.progress_state.loaded_complete = true;
    this.progress_state.is_loading = false;
  }

  increaseRecurrentAssignedAmount = (recurrent_assigned_amount: number) => {
    this.progress_state.recurrent_assigned_amount += recurrent_assigned_amount;
  }

  validateIfHasLinkedGoalToProduct = (associated_product_id: string) => {
    const associated_product = this.investment_products
      .find((product) => product.id === associated_product_id);
    if (associated_product?.name === 'sowos_pocket') {
      this.progress_state.has_any_goal_linked_pocket = true;
      this.progress_state.investment_product_pocket_id = associated_product_id;
    } else if (associated_product?.name === 'sowos_wealth') {
      this.progress_state.has_any_goal_linked_wealth = true;
    }
  }

  obtainInvestorProfile = async () => {
    try {
      await this.get_investor_profile_query.execute();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.obtain_investor_profile'));
    }
  }

  loadActiveGoals = async (associated_product_id: string) => {
    const search_by_customer_dto = {
      reload: true,
      associated_product_id,
      is_active: true,
    };

    const goals = await this.get_search_by_customer_query.execute(
      search_by_customer_dto,
    );
    if (goals.length > 0) {
      this.is_linked = true;
      this.progress_state.has_linked_goals = true;
      this.validateIfHasLinkedGoalToProduct(associated_product_id);
      goals.forEach((goal) => {
        this.increaseRecurrentAssignedAmount(goal.monthly_required_amount);
        if (goal.emergency_fund_investor_goal) {
          this.emergency_fund_goal_linked = true;
        }
        if (goal.retirement_investor_goal) {
          this.retirement_goal_linked = true;
        }
      });
    } else {
      this.total_goals = goals.length;
    }
  }

  loadInactiveGoals = async (associated_product_id: string) => {
    const search_by_customer_dto = {
      reload: true,
      associated_product_id,
      is_active: false,
    };

    const goals = await this.get_search_by_customer_query.execute(
      search_by_customer_dto,
    );

    goals.forEach((goal) => {
      if (goal.retirement_investor_goal && goal.final_investment_date) {
        this.investor_goal_state.retirement_fund.already_created = true;
      }
      if (goal.emergency_fund_investor_goal && goal.final_investment_date) {
        this.investor_goal_state.emergency_fund.already_created = true;
      }
    });

    this.is_unlinked = goals.length > 0 || this.is_unlinked;
    this.progress_state.has_unlinked_goals = goals.length > 0;
  }

  loadGoalsByInvestmentProductProduct = async () => {
    this.investment_products = await this.get_investment_products_query.execute();
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const associated_product of this.investment_products) {
      // eslint-disable-next-line no-await-in-loop
      await this.loadActiveGoals(associated_product.id);
      // eslint-disable-next-line no-await-in-loop
      await this.loadInactiveGoals(associated_product.id);
    }
    this.goals_loaded = true;
  }

  loadAllCustomInvestorGoalInformation = async () => {
    try {
      const custom_investor_goals = await
      this.get_custom_investor_goal_search_by_customer.execute(true);

      this.investor_goal_state.custom_investor_goal.already_created = custom_investor_goals
        .length > 0;

      this.custom_investor_goal_linked = custom_investor_goals.filter(
        (goal) => goal.investor_goal.is_active,
      );

      this.custom_investor_goal = custom_investor_goals.filter(
        (goal) => !goal.investor_goal.is_active,
      );

      this.is_linked = this.is_linked || this.custom_investor_goal_linked.length > 0;

      this.custom_investor_goal_linked = this.custom_investor_goal_linked.map(
        (custom_investor_goal) => (
          {
            ...custom_investor_goal,
            on_upgrade_process: custom_investor_goal
              .investor_goal_id === this.investor_goal_selected.investor_goal_id,
          }),
      );
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_all_custom_investor_goal_information'));
      }
    }
  }

  loadRecommendedInvestmentProduct = (
    recommended_investment_product: string,
    contract_investment_product: string,
    pocket_contracted: boolean,
    wealth_contracted: boolean,
  ) => {
    if (recommended_investment_product) {
      if (recommended_investment_product === 'sowos_wealth') {
        if (!wealth_contracted) {
          this.show_recommendation.wealth = true;
        } else if (!pocket_contracted) {
          this.show_recommendation.pocket = true;
        } else {
          this.show_recommendation.any = true;
        }
      }
      if (recommended_investment_product === 'sowos_pocket') {
        if (!pocket_contracted) {
          this.show_recommendation.pocket = true;
        } else if (!wealth_contracted) {
          this.show_recommendation.wealth = true;
        } else {
          this.show_recommendation.any = true;
        }
      }
    }
    if (contract_investment_product) {
      if (contract_investment_product === 'sowos_wealth') {
        this.contractWealth();
      }
      if (contract_investment_product === 'sowos_pocket') {
        this.contractPocket();
      }
    }
  }

  loadCustomerContractedProductsInformation = async (
    recommended_investment_product: string,
    contract_investment_product: string,
  ) => {
    try {
      const {
        has_sowos_wealth_contracted,
        has_sowos_pocket_contracted,
        has_any_product_contracted,
      } = await this.get_contracted_products_query.execute();
      this.has_contracted_products.any = has_any_product_contracted;
      this.has_contracted_products.pocket = has_sowos_pocket_contracted;
      this.has_contracted_products.wealth = has_sowos_wealth_contracted;
      this.progress_state.has_any_product_contracted = has_any_product_contracted;
      this.loadRecommendedInvestmentProduct(
        recommended_investment_product,
        contract_investment_product,
        has_sowos_pocket_contracted,
        has_sowos_wealth_contracted,
      );

      if (has_sowos_wealth_contracted) {
        await this.validateIfHasModerateContracted();
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_customer_contracted_products'));
    } finally {
      this.products_information_complete = true;
    }
  }

  validateIfHasModerateContracted = async () => {
    const {
      moderate_is_contracted, status, opening_goal_id, opening_goal_name,
    } = await this.get_moderate_is_contracted_query.execute();
    this.moderate_is_contracted = moderate_is_contracted;
    this.contracting_status_moderate = status;
    if (!moderate_is_contracted && status && this.contracting_status.indexOf(status) > -1) {
      this.show_contracting_status_moderate_dialog = true;
      this.investor_goal_selected = {
        investor_goal_id: opening_goal_id,
        investor_goal_name: opening_goal_name,
      };
    }
    return moderate_is_contracted;
  }

  modifyStrategy = (investor_goal_selected: InvestorGoalSelected) => {
    this.investor_goal_selected = investor_goal_selected;
    if (investor_goal_selected.status) {
      this.show_unlink_status_dialog = true;
      this.unlink_status = investor_goal_selected.status;
    }
    if (!investor_goal_selected.status) {
      this.show_modify_moderate_dialog = true;
    }
  }

  upgradeToModerate = async (investor_goal_selected: InvestorGoalSelected) => {
    try {
      this.investor_goal_selected = investor_goal_selected;
      const moderate_is_contracted = await this.validateIfHasModerateContracted();
      if (moderate_is_contracted) {
        this.show_link_moderate_dialog = true;
      } else {
        this.show_contract_moderate_dialog = true;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_is_moderate_contracted'));
    }
  }

  showTransferStatusModerate = (investor_goal_selected: InvestorGoalSelected) => {
    this.investor_goal_selected = investor_goal_selected;
    this.show_contracting_status_moderate_dialog = true;
  }

  showExitPollDialog = () => {
    this.show_exit_poll_dialog = true;
  }

  showHiringFlowDialog = () => {
    this.show_hiring_flow_dialog = true;
  }

  backToProductInformation = () => {
    this.show_hiring_flow_dialog = false;
    this.show_contract_moderate_dialog = true;
  }

  showUnlinkStatus = () => {
    this.unlink_status = 'unlinked_in_progress';
    this.show_unlink_status_dialog = true;
  }

  displayUnlinkedGoalsByDefaultIfUserHasNotActiveGoals = () => {
    if (!this.is_linked) this.expansion_panel = 0;
  }

  goToMainDashboard = () => {
    this.router.navigate('/');
  }

  addURLParamsAndReload = (value: string) => {
    const url = new URL(window.location.href);
    url.searchParams.set('contract_investment_product', value);
    window.location.href = url.toString();
  }

  contractPocket = (reload = false) => {
    if (reload) {
      this.addURLParamsAndReload('sowos_pocket');
    }
    this.creation_goal_type = '';
    this.display_contract_savings = true;
    this.service = 'kuspit';
  }

  contractWealth = (reload = false) => {
    if (reload) {
      this.addURLParamsAndReload('sowos_wealth');
    }
    this.creation_goal_type = '';
    this.display_contract_savings = true;
    this.service = 'allianz';
  }

  createdAndLinkedGoal = () => {
    this.creation_goal_type = '';
    this.show_investor_goal_linked = true;
  }

  contractWealthWarning = () => {
    this.show_create_goal = false;
    this.display_contract_wealth_warning = true;
  }

  endProcessWealthContribution = () => {
    this.show_wealth_contribute_dialog = false;
  }

  contributeToMyAccount = () => {
    this.show_create_goal = false;
    this.show_wealth_contribute_dialog = true;
  }

  loadGoalDefinitionStep = async () => {
    try {
      const goal_definition_step = await this.get_goal_definition_step_query.execute(false);
      if (goal_definition_step.main_item === 'informative' || !goal_definition_step) {
        this.show_support_you_flow = true;
      } else {
        this.showDefineGoalsWizard();
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_goal_definition_step'));
    }
  };

  supportYouSelected = async () => {
    this.show_create_goal = false;
    await this.loadGoalDefinitionStep();
  }

  showDefineGoalsWizard = () => {
    this.show_support_you_goals_wizard = true;
  }

  showCreateGoals = (create_goals: boolean) => {
    this.show_create_goal = create_goals;
  }

  handleDistributeChange = async () => {
    this.custom_investor_goal_linked = [];
    await this.loadAllCustomInvestorGoalInformation();
  }

  trackingPaused = () => {
    this.manage_tracking_investor_goal_state.show_pause_tracking_dialog = false;
    this.manage_tracking_investor_goal_state.show_pause_tracking_reason_dialog = true;
  }

  pauseTrackingReasonSend = () => {
    this.manage_tracking_investor_goal_state.show_pause_tracking_reason_dialog = false;
  }
}
