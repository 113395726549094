import { Container } from 'inversify-props';
import setupEmergencyFundLocation from '@/modules/flagship/catalogs/emergency-fund-location/setup';
import setupEmploymentSituation from '@/modules/flagship/catalogs/employment-situation/setup';
import setupExitPollOptions from '@/modules/flagship/catalogs/exit-poll-options/setup';
import setupCustomGoalTypes from '@/modules/flagship/catalogs/custom-goal-types/setup';
import setupEmergencyFundAvailability from '@/modules/flagship/catalogs/emergency-fund-availability/setup';
import setupInvestmentGoals from '@/modules/flagship/catalogs/investment-goals/setup';
import setupInvestmentProducts from '@/modules/flagship/catalogs/investment-products/setup';
import setupRetirementFundLocation from '@/modules/flagship/catalogs/retirement-fund-location/setup';
import setupIncomeBehaviorInFollowingYear
  from '@/modules/flagship/catalogs/income-behavior-in-following-year/setup';
import setupInvestmentProductFundType from '@/modules/flagship/catalogs/investment-product-fund-type/setup';
import setupLevelOfEducation from '@/modules/flagship/catalogs/level-of-education/setup';
import setupPauseTrackingReasons from '@/modules/flagship/catalogs/pause-tracking-reason/setup';

export default function setupCatalogsModule(container: Container) {
  setupEmergencyFundLocation(container);
  setupEmploymentSituation(container);
  setupExitPollOptions(container);
  setupCustomGoalTypes(container);
  setupEmergencyFundAvailability(container);
  setupInvestmentGoals(container);
  setupInvestmentProducts(container);
  setupRetirementFundLocation(container);
  setupIncomeBehaviorInFollowingYear(container);
  setupInvestmentProductFundType(container);
  setupLevelOfEducation(container);
  setupPauseTrackingReasons(container);
}
