import TYPES from '@/types';
import Vue from 'vue';
import { currencyFormat } from '@/vue-app/utils/currency';
import { getGoalIconPath } from '@/vue-app/utils/goal-icon-path';

// Application
import { GetAllianzAccountQuery }
  from '@/modules/my-investment/allianz/allianz-account/application/queries';
import GetInvestmentProductsQuery
  from '@/modules/flagship/catalogs/investment-products/application/queries/get-investment-products-query';
import GetSearchByCustomerQuery
  from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';

// Domain
import { SearchByCustomerDto }
  from '@/modules/flagship/investor-goal/search-by-customer/domain/dtos/search-by-customer-dto';
import {
  verifyIfInvestorGoalIsLinkedWithModerate,
} from '@/modules/flagship/customer-investment-product-fund-type/domain/services/verify-if-investor-goal-is-linked-with-an-investment-product';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

type LinkedGoals = {
  label: string;
  icon: string;
  recurrent_assigned_amount: number;
  alt: string;
  is_moderate: boolean;
  icon_path: string;
}

export default class RecurrentContributionAmongLinkedGoalsDialogViewModel {
  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.GET_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_investment_products_query!: GetInvestmentProductsQuery;

  @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  private readonly customer_id = sessionStorage.getItem('user_id');

  readonly i18n_namespace = 'components.allianz-dashboard.recurrent_contribution_dialog';

  private readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  private search_by_customer_dto: SearchByCustomerDto = {
    reload: true,
    associated_product_id: '',
    is_active: true,
  };

  linked_goals: Array<LinkedGoals> = [];

  monthly_collection_day = 0;

  recurrent_contribution_amount = 0;

  recurrent_contribution_unassigned_amount = 0;

  show_recurring_contribution_dialog = false;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async () => {
    await this.loadRecurrentContributionInformation();
    await this.loadInvestmentProducts();
    await this.loadActiveGoals();
    this.setRecurrentUnassignedAmount();
  }

  get is_btn_x_large_size_active() {
    return (!this.view.$vuetify.breakpoint.smAndDown);
  }

  getFormattedAmount = (amount: number) => (currencyFormat(amount));

  getIconMaxWidth = (icon_goal_name: string) => ((icon_goal_name === 'icon-add.svg') ? '30px' : '21px');

  getCustomizedModerateClassForMainVCol = (is_moderate: boolean) => ((is_moderate) ? 'white--text primary' : 'mb-3');

  loadRecurrentContributionInformation = async () => {
    try {
      const {
        monthly_collection_day, amount,
      } = await this.get_allianz_account_query.execute({ customer_id: this.customer_id });
      this.monthly_collection_day = monthly_collection_day!;
      this.recurrent_contribution_amount = amount || 0;
      this.recurrent_contribution_unassigned_amount = amount || 0;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_allianz_account_query'));
    }
  }

  loadInvestmentProducts = async () => {
    try {
      const investment_products = await this.get_investment_products_query.execute();
      const product = investment_products.find((item) => item.name === 'sowos_wealth');
      this.search_by_customer_dto.associated_product_id = product!.id;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_investment_products_query'));
    }
  }

  loadActiveGoals = async () => {
    try {
      const active_goals = await this.get_search_by_customer_query
        .execute(this.search_by_customer_dto);
      let is_moderate = false;
      active_goals.forEach((goal) => {
        is_moderate = false;
        if (goal.investment_product_fund_types && goal.investment_product_fund_types.linked) {
          is_moderate = verifyIfInvestorGoalIsLinkedWithModerate(
            goal.investment_product_fund_types.linked,
          );
        }
        this.linked_goals.push({
          // eslint-disable-next-line max-len
          label: (goal.custom_investor_goal) ? goal.custom_investor_goal.goal_name : goal.investment_goal_type.label,
          icon: (goal.custom_investor_goal) ? goal.custom_investor_goal.custom_goal_type.icon_name : 'icon-retirement.svg',
          recurrent_assigned_amount: goal.recurrent_assigned_amount,
          alt: (goal.custom_investor_goal) ? 'alts.custom' : 'alts.img_fund',
          is_moderate,
          icon_path: getGoalIconPath(
            goal.investor_goal_id,
            goal.custom_investor_goal?.custom_goal_type?.icon_name || 'icon-retirement.svg',
            goal.custom_investor_goal?.updated_at || '',
          ),
        });
        this.recurrent_contribution_unassigned_amount -= goal.recurrent_assigned_amount;
      });
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_search_by_customer_query'));
    }
  }

  setRecurrentUnassignedAmount = () => {
    if (this.recurrent_contribution_unassigned_amount > 0) {
      this.linked_goals.push({
        label: this.translate('recurrent_unassigned_balance'),
        icon: 'noun-coins.svg',
        recurrent_assigned_amount: this.recurrent_contribution_unassigned_amount,
        alt: 'alts.unassigned',
        is_moderate: false,
        icon_path: getGoalIconPath('', 'noun-coins.svg', ''),
      });
    }
  }

  showRecurringDepositDialog = () => {
    this.view.$emit('showEditRecurringContribution');
  }
}
