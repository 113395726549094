





















import { Component, PropSync, Vue } from 'vue-property-decorator';
import AccessMoreStrategiesSeeMoreModerateViewModel
  from '@/vue-app/view-models/allianz-dashboard/access-more-strategies/access-more-strategies-see-more-moderate-view-model';

@Component({
  name: 'StrategyModerateHiringFlowDialog',
  components: {
    StrategyInformationAndVideo: () => import('@/vue-app/components/strategy-moderate-wealth/hiring-flow/StrategyInformationAndVideo.vue'),
    StrategyComparisonPesosAndModerate: () => import('@/vue-app/components/strategy-moderate-wealth/hiring-flow/StrategyComparisonPesosAndModerate.vue'),
  },
})
export default class AccessMoreStrategiesSeeMoreModerateDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  strategy_moderate_activation_dialog_model = Vue.observable(
    new AccessMoreStrategiesSeeMoreModerateViewModel(this),
  );

  prevStep() {
    if (this.strategy_moderate_activation_dialog_model.current_step === 0) {
      this.closeModal();
    }
    this.strategy_moderate_activation_dialog_model.prevStep();
  }

  nextStep() {
    if (this.strategy_moderate_activation_dialog_model.current_step === this
      .strategy_moderate_activation_dialog_model.final_step) {
      this.closeModal();
    }
    this.strategy_moderate_activation_dialog_model.nextStep();
  }

  closeModal() {
    this.synced_is_open = false;
  }
}
