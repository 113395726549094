import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import GetGlobalTrackingQuery from '@/modules/flagship/investor-goal/global-tracking/application/queries/get-global-tracking-query';
import GetCustomerInvestorGoalReminderQuery
  from '@/modules/flagship/customer-investor-goal-reminder/application/queries/get-customer-investor-goal-reminder-query';
import {
  GetAllianzAccountQuery,
} from '@/modules/my-investment/allianz/allianz-account/application/queries';

// Domain
import {
  GoalsDashboardProgressStateManager,
} from '@/modules/goals-dashboard/progress/domain/state/goals-dashboard-progress-state-manager';
import {
  GoalsDashboardDistributeStateManager,
} from '@/modules/goals-dashboard/distribute-balance/domain/state/goals-dashboard-distribute-state-manager';
import {
  GlobalTrackingStateManager,
} from '@/modules/flagship/investor-goal/global-tracking/domain/state/global-tracking-state-manager';
import {
  ManageTrackingInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/manage-tracking-investor-goal-state-manager';
import { GlobalTrackingEntity } from '@/modules/flagship/investor-goal/global-tracking/domain/entities/global-tracking-entity';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class ProgressCardViewModel {
  @Inject(TYPES.GET_GLOBAL_TRACKING_QUERY)
  private readonly get_global_tracking_query!: GetGlobalTrackingQuery;

  @Inject(TYPES.GET_CUSTOMER_INVESTOR_GOAL_REMINDER_QUERY)
  private readonly get_customer_investor_goal_reminder_query!: GetCustomerInvestorGoalReminderQuery;

  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.GOALS_DASHBOARD_PROGRESS_STATE_MANAGER)
  private readonly progress_state_manager!: GoalsDashboardProgressStateManager;

  @Inject(TYPES.GOALS_DASHBOARD_DISTRIBUTE_STATE_MANAGER)
  private readonly distribute_state_manager!: GoalsDashboardDistributeStateManager;

  @Inject(TYPES.GLOBAL_TRACKING_STATE_MANAGER)
  private readonly global_tracking_state_manager!: GlobalTrackingStateManager;

  @Inject(TYPES.MANAGE_TRACKING_INVESTOR_GOAL_STATE_MANAGER)
  readonly manage_tracking_investor_goal_state_manager!: ManageTrackingInvestorGoalStateManager;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.goals-dashboard.progress-card';

  private readonly customer_id = sessionStorage.getItem('user_id');

  private readonly error_allianz_account_not_exists = `Error customer_id:${this.customer_id}, not exists`;

  global_tracking: GlobalTrackingEntity = {
    global_tracking_percent: 0,
    current_global_balance: 0,
    expected_global_balance: 0,
    total_goals_with_tracking_enabled: 0,
  };

  is_loading = false;

  main_message = '';

  secondary_message_class = '';

  secondary_message = '';

  img = 'goals-dashboard/details-goals/be_careful.svg';

  img_message = '';

  window_number = 0;

  maximum_number_windows = 2;

  day_to_remind = 0;

  is_subscribed = false;

  associated_product_id_pocket = '';

  recurrent_amount = 0;

  progress_state = this.progress_state_manager.state;

  distribute_state = this.distribute_state_manager.state;

  global_tracking_state = this.global_tracking_state_manager.state;

  manage_tracking_investor_goal_state = this
    .manage_tracking_investor_goal_state_manager.state;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async () => {
    this.is_loading = true;
    if (this.progress_state.has_linked_goals) {
      await this.loadGlobalTracking();
    }
    this.is_loading = false;
  }

  get show_recurrent_contributions() {
    return this.progress_state.has_any_goal_linked_wealth && this.recurrent_amount;
  }

  get total_amount_recurrent_contributions() {
    return currencyFormat(this.recurrent_amount);
  }

  get total_monthly_required_amount_formatted() {
    return currencyFormat(this.progress_state.recurrent_assigned_amount);
  }

  loadAmounts = async () => {
    try {
      if (this.progress_state.has_any_goal_linked_wealth) {
        await this.loadRecurrentAssignedAmount();
      }

      if (this.progress_state.has_any_goal_linked_pocket) {
        await this.loadCustomerInvestorGoalReminder(
          this.progress_state.investment_product_pocket_id,
        );
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_active_goals'));
    }
  }

  loadGlobalTracking = async () => {
    try {
      this.global_tracking = await this.get_global_tracking_query.execute();
      // eslint-disable-next-line max-len
      this.global_tracking.global_tracking_percent = (this.global_tracking.global_tracking_percent > 100) ? 100 : Number(this.global_tracking.global_tracking_percent.toFixed(0));
      this.setGoalProgressStatus(this.global_tracking.global_tracking_percent);
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_global_tracking_query'));
    }
  }

  setGoalProgressStatus = (percent: number) => {
    switch (true) {
      case percent >= 80:
        this.main_message = this.translate('keep_it_up');
        this.secondary_message = this.translate('keep_it_up_message');
        this.secondary_message_class = 'secondary--text';
        this.img = 'goals-dashboard/details-goals/keep_it_up.svg';
        this.img_message = this.translate('keep_it_up_message');
        break;
      case (percent >= 65 && percent < 80):
        this.main_message = this.translate('youre_doing_well_message');
        this.secondary_message = this.translate('youre_doing_well');
        this.secondary_message_class = 'sky-blue--text';
        this.img = 'goals-dashboard/details-goals/youre_doing_well.svg';
        this.img_message = this.translate('youre_doing_well');
        break;
      case (percent >= 50 && percent < 65):
        this.main_message = this.translate('you_can_do_better');
        this.secondary_message = this.translate('you_can_do_better_message');
        this.secondary_message_class = 'progress-blue--text';
        this.img = 'goals-dashboard/details-goals/you_can_do_better.svg';
        this.img_message = this.translate('you_can_do_better_img');
        break;
      default:
        this.main_message = this.translate('be_careful_message');
        this.secondary_message = this.translate('be_careful');
        this.secondary_message_class = 'red--text';
        this.img = 'goals-dashboard/details-goals/be_careful.svg';
        this.img_message = this.translate('be_careful');
        break;
    }
  }

  loadCustomerInvestorGoalReminder = async (associated_product_id: string) => {
    try {
      // eslint-disable-next-line max-len
      const customer_investor_goal_reminder = await this.get_customer_investor_goal_reminder_query.execute({
        associated_product_id,
      });
      if (customer_investor_goal_reminder) {
        this.day_to_remind = customer_investor_goal_reminder.day_to_remind;
        this.is_subscribed = customer_investor_goal_reminder.is_subscribed || false;
      }
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_customer_investor_goal_reminder'));
      }
    }
  };

  loadRecurrentAssignedAmount = async () => {
    try {
      // eslint-disable-next-line max-len
      const { confirm_periodicity, amount } = await this.get_allianz_account_query.execute({ customer_id: this.customer_id });
      if (confirm_periodicity) {
        this.recurrent_amount = amount || 0;
      }
    } catch (error) {
      if (error.message !== this.error_allianz_account_not_exists) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_recurrent_assigned_amount'));
      }
    }
  }

  windowChange = async () => {
    if (this.window_number === 1) {
      await this.loadAmounts();
    }
  }

  handleDistributeChange = async () => {
    this.global_tracking_state.loaded = false;
    await this.loadGlobalTracking();
  }

  handleShowPauseTrackingReasonDialog = async () => {
    this.global_tracking_state.loaded = false;
    await this.loadGlobalTracking();
  }

  handleShowTrackingResumedDialog = async () => {
    this.global_tracking_state.loaded = false;
    await this.loadGlobalTracking();
  }
}
