var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.mdAndDown,"width":"45%","persistent":""},model:{value:(_vm.synced_is_open),callback:function ($$v) {_vm.synced_is_open=$$v},expression:"synced_is_open"}},[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(!_vm.view_model.is_success_modal_open),expression:"!view_model.is_success_modal_open"}],staticClass:"py-12",attrs:{"rounded":"lg","loading":_vm.view_model.is_loading}},[_c('v-btn',{attrs:{"icon":"","absolute":"","top":"","right":""},on:{"click":_vm.closeModal}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('div',{staticClass:"h-full d-flex justify-space-between flex-column px-lg-15 px-md-5"},[_c('v-col',{staticClass:"pt-10 pt-xl-0 pt-md-0 pt-gl-0",attrs:{"justify":"center"}},[_c('div',{staticClass:"text-center mb-10 mb-md-6"},[_c('h3',{staticClass:"primary--text mb-4"},[_vm._v(" "+_vm._s(_vm.view_model.translate('title', { product_name: _vm.synced_product_name }))+" ")]),_c('h2',{staticClass:"text-center accent--text mb-2"},[_vm._v(" "+_vm._s(_vm.view_model.translate( 'balance', { amount: _vm.view_model .deposit_amount_formatted } ))+" ")]),_c('p',{staticClass:"primary--text text-center mt-8 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.view_model .translate('description', { product_name: _vm.synced_product_name }))+" ")])]),_c('div',_vm._l((_vm.view_model.linked_goals),function(item,index){return _c('v-row',{key:index,staticClass:"justify-center mt-3 mb-4",attrs:{"no-gutters":""}},[_c('v-col',{class:_vm.view_model
                       .getCustomizedModerateClassForMainVCol(item.is_moderate),attrs:{"cols":"12","md":"9"}},[_c('v-row',{staticClass:"justify-center mt-3",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-none d-md-block d-sm-block",attrs:{"cols":"1"}},[_c('v-img',{attrs:{"src":item.icon_path,"contain":"","max-width":_vm.view_model
                      .getIconMaxWidth(item.icon),"alt":item.alt}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('span',{staticClass:"caption-text"},[_vm._v(_vm._s(item.label))])]),(item.name === 'unassigned')?_c('v-col',{attrs:{"cols":"5","md":"4"}},[_c('span',{staticClass:"text-amount"},[_vm._v(" "+_vm._s(_vm.view_model.translate('balance', { amount: _vm.view_model .getAmountFormatted(item.assigned_amount) } ))+" ")])]):_c('v-col',{attrs:{"cols":"5","md":"4"}},[_c('v-row',[_c('v-icon',{staticClass:"cursor-pointer",attrs:{"color":"inactive-blue"},on:{"click":function($event){_vm.view_model
                          .decrementAmount(index)}}},[_vm._v(" mdi-minus ")]),_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:({ prefix: '$', precision: 2 }),expression:"{ prefix: '$', precision: 2 }"}],staticClass:"text-amount centered-input",class:_vm.view_model
                        .getCustomizedModerateClassForVTextField(item.is_moderate),attrs:{"spellcheck":"false","flat":"","solo":"","hide-details":"","background-color":_vm.view_model
                        .getCustomizedModerateBackgroundColorForVTextField(
                          item.is_moderate)},on:{"change":function($event){_vm.view_model
                            .changeAssignedAmountField(index)}},model:{value:(item.assigned_amount_field),callback:function ($$v) {_vm.$set(item, "assigned_amount_field", $$v)},expression:"item.assigned_amount_field"}}),_c('v-icon',{staticClass:"cursor-pointer mb-1",attrs:{"color":"inactive-blue"},on:{"click":function($event){_vm.view_model
                              .incrementAmount(index)}}},[_vm._v("mdi-plus ")])],1)],1)],1),(item.is_moderate)?_c('v-row',{staticClass:"justify-center pb-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"offset-md":"1","cols":"10","md":"9"}},[_c('label',{staticClass:"sky-blue--text"},[_vm._v(" "+_vm._s(_vm.view_model.translate('sws_moderate'))+" "),_c('custom-tooltip',{attrs:{"small-tooltip":true,"label":_vm.view_model
                            .translate('tooltip_moderate')}})],1)])],1):_c('v-divider',{staticClass:"mt-4"})],1)],1)}),1),_c('v-row',{staticClass:"mt-2 mt-md-8 mb-md-2",attrs:{"justify":"center"}},[_c('v-checkbox',{attrs:{"color":"sky-blue"},on:{"change":_vm.view_model.distributeDepositAmountGoals},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"primary--text ml-1"},[_vm._v(" "+_vm._s(_vm.view_model .translate('distribute_equally'))+" ")])]},proxy:true}]),model:{value:(_vm.view_model.is_checkbox_active),callback:function ($$v) {_vm.$set(_vm.view_model, "is_checkbox_active", $$v)},expression:"view_model.is_checkbox_active"}})],1),_c('v-row',{staticClass:"mt-2",attrs:{"justify":"center","justify-md":"space-between"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"text-body",attrs:{"rounded":"","color":"secondary","large":"","block":"","height":"3.5em","disabled":_vm.view_model.is_loading},on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.view_model.translate('cancel'))+" ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"text-body",attrs:{"rounded":"","color":"accent","large":"","block":"","height":"3.5em","disabled":_vm.view_model.is_disabled ||
                _vm.view_model.is_loading},on:{"click":_vm.view_model.distributeBalance}},[_vm._v(" "+_vm._s(_vm.view_model .translate('continue'))+" ")])],1)],1)],1)],1)],1),(_vm.view_model.is_success_modal_open)?_c('distribute-unallocated-balance-success',{attrs:{"product-name":this.synced_product_name},on:{"update:productName":function($event){return _vm.$set(this, "synced_product_name", $event)},"update:product-name":function($event){return _vm.$set(this, "synced_product_name", $event)},"accept":_vm.closeModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }