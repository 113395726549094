import TYPES from '@/types';
import { v4 } from 'uuid';
import Vue from 'vue';

// Application
import GetCustomerInvestorGoalReminderQuery
  from '@/modules/flagship/customer-investor-goal-reminder/application/queries/get-customer-investor-goal-reminder-query';
import CreateCustomerInvestorGoalReminderCommand
  from '@/modules/flagship/customer-investor-goal-reminder/application/commands/create-customer-investor-goal-reminder-command';
import UpdateCustomerInvestorGoalReminderCommand
  from '@/modules/flagship/customer-investor-goal-reminder/application/commands/update-customer-investor-goal-reminder-command';
import CreateLinkSowosPocketCommand
  from '@/modules/flagship/investor-goal/link-sowos-pocket/application/commands/create-link-sowos-pocket-command';

// Domain
import {
  CustomerInvestorGoalReminderEntity,
} from '@/modules/flagship/customer-investor-goal-reminder/domain/entities/customer-investor-goal-reminder-entity';
import {
  InvestorGoalsDto,
} from '@/modules/flagship/investor-goal/link-sowos-pocket/domain/dtos/investor-goals-dto';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class StepScheduleReminderPocketViewModel {
  @Inject(TYPES.GET_CUSTOMER_INVESTOR_GOAL_REMINDER_QUERY)
  private readonly get_customer_investor_goal_reminder_query!: GetCustomerInvestorGoalReminderQuery;

  @Inject(TYPES.CREATE_CUSTOMER_INVESTOR_GOAL_REMINDER_COMMAND)
  private readonly create_investor_goal_reminder_cmd!: CreateCustomerInvestorGoalReminderCommand;

  @Inject(TYPES.UPDATE_CUSTOMER_INVESTOR_GOAL_REMINDER_COMMAND)
  private readonly update_investor_goal_reminder_cmd!: UpdateCustomerInvestorGoalReminderCommand;

  @Inject(TYPES.CREATE_LINK_SOWOS_POCKET_COMMAND)
  private readonly create_link_pocket_command!: CreateLinkSowosPocketCommand;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly view!: Vue

  readonly i18n_namespace = 'components.flagship.flagship-link-goals.step-schedule-reminder-pocket';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  AVAILABLE_DAYS = [...Array(28).keys()].map((i) => ({ item: this.translate('select_option', { option: i + 1 }), value: i + 1 }));

  associated_product_id = '';

  customer_investor_goal_id = '';

  current_day_to_remind = 1;

  day_to_remind = 1;

  is_subscribed = false;

  is_disabled = false;

  change_date = true;

  has_reminder = false;

  investor_goals: InvestorGoalsDto = {
    total_active_goals_balance: '',
    unassigned_balance: '',
    investor_goals: [],
  };

  private customer_reminder_entity: CustomerInvestorGoalReminderEntity = {
    id: v4(),
    day_to_remind: 0,
    investment_product_id: '',
  }

  public constructor(view: Vue) {
    this.view = view;
  }

  radio_class() {
    return this.is_subscribed ? 'grey--text' : 'sky-blue--text';
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  selectedDayToRemind = () => {
    this.is_disabled = false;
  }

  getCustomerInvestorGoalReminder = async () => {
    try {
      const customer_investor_goal_reminder = await this
        .get_customer_investor_goal_reminder_query
        .execute({ associated_product_id: this.associated_product_id });
      this.customer_investor_goal_id = customer_investor_goal_reminder.id;
      this.day_to_remind = customer_investor_goal_reminder.day_to_remind;
      this.current_day_to_remind = customer_investor_goal_reminder.day_to_remind;
      this.is_subscribed = !customer_investor_goal_reminder.is_subscribed!;
      this.has_reminder = true;
      return true;
    } catch {
      return false;
    }
  };

  createCustomerInvestorGoalReminder = async () => {
    try {
      this.customer_reminder_entity.investment_product_id = this.associated_product_id;
      this.customer_reminder_entity.day_to_remind = this.day_to_remind;
      await this.create_investor_goal_reminder_cmd.execute(this.customer_reminder_entity);
      this.customer_investor_goal_id = this.customer_reminder_entity.id;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.create_customer_investor_goal_reminder'));
    }
  };

  updateCustomerInvestorGoalReminder = async () => {
    try {
      this.view.$emit('loadingStep', true);
      this.customer_reminder_entity.id = this.customer_investor_goal_id;
      this.customer_reminder_entity.investment_product_id = this.associated_product_id;
      this.customer_reminder_entity.day_to_remind = this.day_to_remind;
      this.customer_reminder_entity.is_subscribed = !this.is_subscribed;

      await this.update_investor_goal_reminder_cmd.execute(this.customer_reminder_entity);
      await this.createLinkSowosPocket();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_customer_investor_goal_reminder'));
    } finally {
      this.view.$emit('loadingStep', false);
    }
  };

  createLinkSowosPocket = async () => {
    try {
      await this.create_link_pocket_command.execute(this.investor_goals);
      const linked_goals_pocket_event = new CustomEvent('pocket.linked.goal', { detail: { } });
      window.dispatchEvent(linked_goals_pocket_event);
      this.view.$emit('endProcess');
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.create_link_sowos_pocket'));
    }
  };

  initialize = async (associated_product_id: string, investor_goal: any) => {
    this.view.$emit('loadingStep', true);
    this.associated_product_id = associated_product_id;
    this.investor_goals = { ...investor_goal };
    const is_created = await this.getCustomerInvestorGoalReminder();
    if (!is_created) {
      await this.createCustomerInvestorGoalReminder();
    }
    this.view.$emit('loadingStep', false);
  }
}
