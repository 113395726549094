import TYPES from '@/types';
import { parseCurrencyToNumber } from '@/vue-app/utils/parse-currency-to-number';

import Vue from 'vue';

// Application
import CreateHabitsAvailableBudgetCalculationCommand
  from '@/modules/flagship/habits-available-budget-calculation/application/commands/create-habits-available-budget-calculation-command';
import CreateHabitsRecommendedBudgetCalculationCommand
  from '@/modules/flagship/habits-recommended-budget-calculation/application/commands/create-habits-recommended-budget-calculation-command';
import UpdateInvestorProfileOnFinancialHabitsCommand
  from '@/modules/my-investment/allianz/access-more-strategies/application/commands/update-investor-profile-on-financial-habits-command';
import GetIncomeBehaviorInFollowingYearQuery
  from '@/modules/flagship/catalogs/income-behavior-in-following-year/application/queries/get-income-behavior-in-following-year-query';
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';

// Domain
import {
  FinancialHabitsDto,
} from '@/modules/my-investment/allianz/access-more-strategies/domain/dtos/financial-habits-dtos';
import {
  IncomeBehaviorInFollowingYearEntity,
} from '@/modules/flagship/catalogs/income-behavior-in-following-year/domain/entities/income-behavior-in-following-year-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class FlagshipProfilingStepFinancialHabitsViewModel {
  @Inject(TYPES.CREATE_HABITS_AVAILABLE_BUDGET_CALCULATION_COMMAND)
  private readonly create_available_calculation!: CreateHabitsAvailableBudgetCalculationCommand;

  @Inject(TYPES.CREATE_HABITS_RECOMMENDED_BUDGET_CALCULATION_COMMAND)
  private readonly create_recommended_calculation!: CreateHabitsRecommendedBudgetCalculationCommand;

  @Inject(TYPES.UPDATE_INVESTOR_PROFILE_ON_FINANCIAL_HABITS_COMMAND)
  private readonly update_profile_financial_habits!: UpdateInvestorProfileOnFinancialHabitsCommand;

  @Inject(TYPES.GET_INCOME_BEHAVIOR_IN_FOLLOWING_YEAR_QUERY)
  // eslint-disable-next-line max-len
  private readonly get_income_behavior_in_following_year_query!: GetIncomeBehaviorInFollowingYearQuery;

  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.flagship.flagship-profiling.flagship_profiling_step_financial_habits';

  private readonly view!: Vue;

  net_monthly_income = '';

  net_monthly_expenses = '';

  monthly_required_amount = ''

  is_negative = false;

  is_disable = false;

  is_valid_form = false;

  income_behavior_next_year_options: Array<IncomeBehaviorInFollowingYearEntity> = [];

  income_behavior_in_following_year_id = '';

  investor_profile_id = '';

  readonly minimum_available_amount_percentage = 0.10;

  rules = {
    net_monthly_expenses: [
      (value: string) => (
        (parseFloat(value.replace(/[^0-9.]/g, '')) <= parseFloat(this.net_monthly_income.replace(/[^0-9.]/g, '')))
        || this.translate('negative_message')
      ),
    ],
  };

  public constructor(view: Vue) {
    this.view = view;
  }

  get can_continue() {
    return !this.is_disable && this.income_behavior_in_following_year_id
      && this.net_monthly_income !== '$0 MXN' && this.net_monthly_expenses !== '$0 MXN';
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  createAvailableCalculation = async () => {
    this.view.$emit('loadingStep', true);
    const net_monthly_income = parseCurrencyToNumber(this.net_monthly_income);
    const net_monthly_expenses = parseCurrencyToNumber(this.net_monthly_expenses);

    const available_monthly_amount = net_monthly_income - net_monthly_expenses;
    const ten_percent_of_net_monthly_income = net_monthly_income * this
      .minimum_available_amount_percentage;
    this.is_negative = available_monthly_amount < ten_percent_of_net_monthly_income;
    if (this.is_negative) {
      this.monthly_required_amount = ten_percent_of_net_monthly_income.toString();
    } else {
      this.monthly_required_amount = available_monthly_amount.toString();
    }
    this.view.$emit('loadingStep', false);
    return true;
  }

  netMonthlyChange = async () => {
    if (this.net_monthly_expenses !== '$0 MXN'
      && this.net_monthly_income !== '$0 MXN') {
      await this.createAvailableCalculation();
    } else {
      this.monthly_required_amount = '$0 MXN';
    }
  }

  updateInformation = async () => {
    try {
      this.view.$emit('loadingStep', true);

      const payload: FinancialHabitsDto = {
        investor_profile_id: this.investor_profile_id,
        net_monthly_income: Number(parseFloat(this.net_monthly_income.replaceAll(/[^\d.-]/g, '')).toFixed(2)),
        net_monthly_expenses: Number(parseFloat(this.net_monthly_expenses.replaceAll(/[^\d.-]/g, '')).toFixed(2)),
        income_behavior_in_following_year_id: this.income_behavior_in_following_year_id,
        is_completed: false,
      };

      await this.update_profile_financial_habits.internalExecute(payload);

      this.view.$emit('nextStep');

      return true;
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.messageNotifier.showErrorNotification(
          this.translate('errors.update_profile'),
        );
      }

      return false;
    } finally {
      this.view.$emit('loadingStep', false);
    }
  }

  loadIncomeBehaviorNextYearOptions = async () => {
    try {
      this.income_behavior_next_year_options = await this
        .get_income_behavior_in_following_year_query.execute();
    } catch {
      this.messageNotifier.showErrorNotification(
        this.translate('errors.load_income_behavior_next_year_options'),
      );
    }
  }

  loadInvestorProfile = async () => {
    try {
      const investor_profile = await this.get_investor_profile_query.execute();
      this.investor_profile_id = investor_profile.id;
      this.net_monthly_income = investor_profile.net_monthly_income.toString();
      this.net_monthly_expenses = investor_profile.net_monthly_expenses.toString();
      this.income_behavior_in_following_year_id = investor_profile
        .income_behavior_in_following_year_id;
      await this.netMonthlyChange();
    } catch {
      this.messageNotifier.showErrorNotification(
        this.translate('errors.load_investor_profile'),
      );
    }
  }

  initialize = async () => {
    this.view.$emit('loadingStep', true);
    await this.loadIncomeBehaviorNextYearOptions();
    await this.loadInvestorProfile();
    this.view.$emit('loadingStep', false);
  }
}
