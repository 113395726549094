import Vue from 'vue';
import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';
import { maxValueRule, minValueRule, requiredRule } from '@/vue-app/utils/form-rules';
import { parseCurrencyToNumber } from '@/vue-app/utils/parse-currency-to-number';

// Application
import GetRetirementInvestorGoalCalculatorQuery
  from '@/modules/flagship/retirement-investor-goal-calculator/application/queries/get-retirement-investor-goal-calculator-query';
import CalculateRetirementGoalMinimumMonthlyPensionQuery
  from '@/modules/flagship/retirement-investor-goal-calculator/application/queries/calculate-retirement-goal-minimum-monthly-pension-query';

// Domain
import { RetirementInvestorGoalCalculatorDto }
  from '@/modules/flagship/retirement-investor-goal-calculator/domain/dtos/retirement-investor-goal-calculator-dto';
import {
  RetirementGoalMinimumMonthlyPensionCalculationDto,
} from '@/modules/flagship/retirement-investor-goal-calculator/domain/dtos/retirement-goal-minimum-monthly-pension-dto';
import {
  CreateInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/create-investor-goal-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class RetirementFundContributionsViewModel {
  @Inject(TYPES.GET_RETIREMENT_INVESTOR_GOAL_CALCULATOR_QUERY)
  private readonly get_retirement_investor_goal_calculator_query!:
    GetRetirementInvestorGoalCalculatorQuery;

  @Inject(TYPES.CALCULATE_RETIREMENT_GOAL_MINIMUM_MONTHLY_PENSION_QUERY)
  private readonly calculate_minimum_monthly_pension!:
    CalculateRetirementGoalMinimumMonthlyPensionQuery;

  @Inject(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
  readonly create_investor_goal_state_manager!: CreateInvestorGoalStateManager;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.flagship.flagship-goals.create-goal.retirement-fund.contributions';

  readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  timer?: NodeJS.Timer;

  readonly min_contribution = 500;

  is_valid_form = false;

  is_loading = false;

  retirement_goal_calculator_dto: RetirementInvestorGoalCalculatorDto = {
    associated_product_id: '',
    defined_by: '',
    issued_age: 0,
    initial_amount: 0,
    retirement_age_adjusted: 0,
    monthly_pension_adjusted: 0,
    monthly_required_amount: 0,
  };

  min_pension = 1;

  max_pension = 1000000;

  max_contribution = 105726;

  input_rules = {
    contribution: [
      requiredRule,
      (value: string) => minValueRule(value.replace(/[^0-9.-]/g, ''), '$500.00 MXN', this.min_contribution),
      (value: string) => maxValueRule(value.replace(/[^0-9.-]/g, ''), '$105726.00 MXN', this.max_contribution),
    ],
    pension: [
      requiredRule,
      (value: string) => minValueRule(value.replace(/[^0-9.-]/g, ''),
        this.getAmountFormatted(this.min_pension), this.min_pension),
      (value: string) => maxValueRule(value.replace(/[^0-9.-]/g, ''),
        this.getAmountFormatted(this.max_pension), this.max_pension),
    ],
  };

  investor_goal_state = this.create_investor_goal_state_manager.state;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async () => {
    await this.setInitialValues();
    await this.calculateMinimumAccumulatedAmount();
    this.delay();
  }

  get target_amount_formatted() {
    return this.getAmountFormatted(this.investor_goal_state.retirement_fund.accumulated_amount);
  }

  get current_amount_formatted() {
    return this.getAmountFormatted(this.investor_goal_state.investor_goal.initial_amount);
  }

  get you_must_save() {
    const you_must_save = this.investor_goal_state.is_defined_by_contribution
      ? this.investor_goal_state.retirement_fund.monthly_desired_pension
      : this.investor_goal_state.retirement_fund.contribution;
    return this.getAmountFormatted(parseFloat(you_must_save));
  }

  get amount_you_must_save() {
    return parseCurrencyToNumber(this.investor_goal_state.retirement_fund.contribution);
  }

  get is_continue_btn_disabled() {
    return !this.is_valid_form || this.is_loading;
  }

  getCustomClassOpacity = (is_option_by_time: boolean) => {
    if ((this.investor_goal_state.is_defined_by_contribution && is_option_by_time) || (!this
      .investor_goal_state.is_defined_by_contribution && !is_option_by_time)) return 'opacity';
    return '';
  }

  getAmountFormatted(amount: number) {
    return currencyFormat(amount);
  }

  delay = () => {
    this.is_loading = true;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.timer = setTimeout(async () => {
      if (this.is_valid_form) {
        await this.loadMonthlyRequiredAmount();
      } else {
        this.is_loading = false;
      }
    }, 2000);
  }

  setInitialValues = async () => {
    try {
      this.retirement_goal_calculator_dto = {
        associated_product_id: this.investor_goal_state.associated_product_id,
        defined_by: this.investor_goal_state.retirement_fund.defined_by,
        issued_age: this.investor_goal_state.retirement_fund.issued_age,
        retirement_age_adjusted: this.investor_goal_state.retirement_fund.retirement_age_adjusted,
        initial_amount: this.investor_goal_state.investor_goal.initial_amount_adjusted,
        monthly_pension_adjusted: this.investor_goal_state.retirement_fund.monthly_pension_adjusted,
        monthly_required_amount: this.investor_goal_state.investor_goal.monthly_required_amount,
      };
      await this.loadMonthlyRequiredAmount();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_retirement_fund_information'));
    }
  }

  definedByChange = async () => {
    this.investor_goal_state.retirement_fund.defined_by = this.investor_goal_state
      .is_defined_by_contribution ? 'contribution' : 'time';
    await this.loadMonthlyRequiredAmount();
  }

  calculateMinimumAccumulatedAmount = async () => {
    try {
      if (this.investor_goal_state.investor_goal.initial_amount_adjusted > 0) {
        const interest_rate = this.investor_goal_state
          .associated_product_interest_rate / 100;
        const payload: RetirementGoalMinimumMonthlyPensionCalculationDto = {
          initial_amount: this.investor_goal_state.investor_goal.initial_amount_adjusted,
          monthly_required_amount: 0,
          retirement_range_adjusted: this.investor_goal_state.retirement_fund
            .retirement_range_adjusted * 12,
          pension_range_adjusted: this.investor_goal_state
            .retirement_fund.pension_range_adjusted * 12,
          interest_rate,
        };
        const {
          minimum_monthly_pension,
        } = await this.calculate_minimum_monthly_pension.execute(payload);
        this.min_pension = Math.ceil(parseFloat(minimum_monthly_pension));
      } else {
        this.min_pension = 1;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_minimum_accumulated_amount'));
    } finally {
      this.is_loading = false;
    }
  }

  loadMonthlyRequiredAmount = async () => {
    try {
      this.retirement_goal_calculator_dto.defined_by = this.investor_goal_state
        .is_defined_by_contribution ? 'contribution' : 'pension';
      if (this.investor_goal_state.is_defined_by_contribution) {
        this.retirement_goal_calculator_dto.monthly_pension_adjusted = 0;
        this.retirement_goal_calculator_dto.monthly_required_amount = parseCurrencyToNumber(
          this.investor_goal_state.retirement_fund.contribution,
        );
      } else {
        this.retirement_goal_calculator_dto.monthly_pension_adjusted = parseCurrencyToNumber(
          this.investor_goal_state.retirement_fund.monthly_desired_pension,
        );
        this.retirement_goal_calculator_dto.monthly_required_amount = 0;
      }
      const { retirement_calculation_data } = await this
        .get_retirement_investor_goal_calculator_query.execute(this.retirement_goal_calculator_dto);

      const {
        monthly_pension_adjusted,
        monthly_required_amount,
        accumulated_amount,
      } = retirement_calculation_data;

      this.investor_goal_state.retirement_fund.contribution = Math.ceil(monthly_required_amount)
        .toString();
      this.investor_goal_state.retirement_fund.monthly_desired_pension = Math
        .ceil(monthly_pension_adjusted).toString();
      this.investor_goal_state.retirement_fund.accumulated_amount = accumulated_amount;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_monthly_required_amount'));
    } finally {
      this.is_loading = false;
    }
  };

  setState = async () => {
    this.investor_goal_state.investor_goal.monthly_required_amount = parseCurrencyToNumber(this
      .investor_goal_state.retirement_fund.contribution);
    this.investor_goal_state.retirement_fund.monthly_pension_adjusted = parseCurrencyToNumber(this
      .investor_goal_state.retirement_fund.monthly_desired_pension);
    this.investor_goal_state.investor_goal.accumulated_amount = this.investor_goal_state
      .retirement_fund.accumulated_amount;
    this.investor_goal_state.retirement_fund.calculated_pension = parseCurrencyToNumber(this
      .investor_goal_state.retirement_fund.monthly_desired_pension);
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = async () => {
    try {
      await this.setState();
      this.view.$emit('nextStep');
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.save'));
    }
  }
}
