import TYPES from '../../types';

// Domain
import {
  SetInvestorGoalTrackingDto,
} from '@/modules/flagship/investor-goal/goal-tracking/domain/dtos/set-investor-goal-tracking-dto';
import {
  GoalTrackingRepository,
} from '@/modules/flagship/investor-goal/goal-tracking/domain/repositories/goal-tracking-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class SetGoalTrackingCommand
  extends Command<SetInvestorGoalTrackingDto> {
  @Inject(TYPES.GOAL_TRACKING_REPOSITORY)
  private readonly repository!: GoalTrackingRepository;

  internalExecute(payload: SetInvestorGoalTrackingDto): Promise<void> {
    return this.repository.setTracking(payload);
  }
}
