import pauseTracking from './pause-tracking.json';
import pauseTrackingReason from './pause-tracking-reason.json';
import resumeTracking from './resume-tracking.json';
import trackingResumed from './tracking-resumed.json';

export default {
  'linked-goals': {
    ...pauseTracking,
    ...pauseTrackingReason,
    ...resumeTracking,
    ...trackingResumed,
  },
};
