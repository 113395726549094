



































































import {
  Component, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import CreateInvestorGoalWizardViewModel
  from '@/vue-app/view-models/components/goals-dashboard/create-goal/create-investor-goal-wizard-view-model';

@Component({
  name: 'CreateInvestorGoalWizardModal',
  components: {
    GoalFlowSelector: () => import('@/vue-app/components/goals-dashboard/create-goal/GoalFlowSelector.vue'),
    GoalTypeSelector: () => import('@/vue-app/components/goals-dashboard/create-goal/GoalTypeSelector.vue'),
    // emergency fund steps
    EmergencyFundVideo: () => import('@/vue-app/components/goals-dashboard/create-goal/emergency-fund/EmergencyFundVideo.vue'),
    EmergencyFundDefinePlan: () => import('@/vue-app/components/goals-dashboard/create-goal/emergency-fund/EmergencyFundDefinePlan.vue'),
    EmergencyFundContributions: () => import('@/vue-app/components/goals-dashboard/create-goal/emergency-fund/EmergencyFundContributions.vue'),
    EmergencyFundSummary: () => import('@/vue-app/components/goals-dashboard/create-goal/emergency-fund/EmergencyFundSummary.vue'),
    // retirement fund steps
    RetirementFundVideo: () => import('@/vue-app/components/goals-dashboard/create-goal/retirement-fund/RetirementFundVideo.vue'),
    RetirementFundDefinePlan: () => import('@/vue-app/components/goals-dashboard/create-goal/retirement-fund/RetirementFundDefinePlan.vue'),
    RetirementFundAge: () => import('@/vue-app/components/goals-dashboard/create-goal/retirement-fund/RetirementFundAge.vue'),
    RetirementFundContributions: () => import('@/vue-app/components/goals-dashboard/create-goal/retirement-fund/RetirementFundContributions.vue'),
    RetirementFundSummary: () => import('@/vue-app/components/goals-dashboard/create-goal/retirement-fund/RetirementFundSummary.vue'),
    // Customer Investor Goal steps
    CustomDefinePlan: () => import('@/vue-app/components/goals-dashboard/create-goal/custom/CustomDefinePlan.vue'),
    CustomContributions: () => import('@/vue-app/components/goals-dashboard/create-goal/custom/CustomContributions.vue'),
    CustomSummary: () => import('@/vue-app/components/goals-dashboard/create-goal/custom/CustomSummary.vue'),
    DistributeBalanceUnallocated: () => import('@/vue-app/components/goals-dashboard/create-goal/unallocated-balance/DistributeBalanceUnallocated.vue'),
    // More strategies steps
    DefineInvestmentTerm: () => import('@/vue-app/components/allianz-dashboard/access-more-strategies/DefineInvestmentTerm.vue'),
    BucketInsufficientBalance: () => import('@/vue-app/components/goals-dashboard/create-goal/bucket/BucketInsufficientBalance.vue'),
    AccessMoreStrategiesRiskTolerance: () => import('@/vue-app/components/allianz-dashboard/access-more-strategies/AccessMoreStrategiesRiskTolerance.vue'),
    InvestmentStrategyResult: () => import('@/vue-app/components//allianz-dashboard/access-more-strategies/InvestmentStrategyResult.vue'),
    InvestmentStrategyInitialBalance: () => import('@/vue-app/components/allianz-dashboard/access-more-strategies/InvestmentStrategyInitialBalance.vue'),
    // Link Pocket
    ScheduleReminderPocket: () => import('@/vue-app/components/goals-dashboard/create-goal/link-goal/ScheduleReminderPocket.vue'),
    // Link Wealth
    ConfigureContributionsWealth: () => import('@/vue-app/components/goals-dashboard/create-goal/link-goal/ConfigureContributionsWealth.vue'),
    SchedulePaymentDateWealth: () => import('@/vue-app/components/goals-dashboard/create-goal/link-goal/SchedulePaymentDateWealth.vue'),
  },
})
export default class CreateInvestorGoalWizardModal extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  view_model = Vue.observable(
    new CreateInvestorGoalWizardViewModel(this),
  );

  @Watch('view_model.investor_goal_state.investor_goal_type')
  onInvestorGoalTypeChange(investor_goal_type: string) {
    this.view_model.investorGoalTypeChange(investor_goal_type);
  }

  @Watch('view_model.investor_goal_state.investor_goal.fixed_time_adjusted')
  onFixedTimeAdjusted() {
    this.view_model.fixedTimeChange();
  }

  @Watch('view_model.more_strategies_state.strategy.label')
  onStrategyAdjusted(strategy: string) {
    this.view_model.strategyChange(strategy);
  }

  async endProcess() {
    this.synced_is_open = false;
  }

  contractPocket() {
    this.synced_is_open = false;
    this.view_model.contractPocket();
  }

  contractWealth() {
    this.synced_is_open = false;
    this.view_model.contractWealth();
  }

  contributeToMyAccount() {
    this.synced_is_open = false;
    this.view_model.contributeToMyAccount();
  }

  created() {
    this.view_model.initialize();
  }

  closeModal() {
    this.synced_is_open = false;
  }
}
