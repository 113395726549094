import { Container } from 'inversify';
import TYPES from './types';

// Infrastructure
import InvestorGoalPauseTrackingReasonHttpRepository
  from './infrastructure/repositories/investor-goal-pause-tracking-reason-http-repository';

// Application
import CreateInvestorGoalPauseTrackingReasonCommand
  from './application/commands/create-investor-goal-pause-tracking-reason-command';

// Domain
import {
  InvestorGoalPauseTrackingReasonRepository,
} from './domain/repositories/investor-goal-pause-tracking-reason-repository';

export default function setupInvestorGoalPauseTrackingReason(container: Container) {
  container
    .bind<InvestorGoalPauseTrackingReasonRepository>(
      TYPES.INVESTOR_GOAL_PAUSE_TRACKING_REASON_REPOSITORY,
    )
    .to(InvestorGoalPauseTrackingReasonHttpRepository)
    .inSingletonScope();

  container
    .bind<CreateInvestorGoalPauseTrackingReasonCommand>(
      TYPES.CREATE_INVESTOR_GOAL_PAUSE_TRACKING_REASON_COMMAND,
    )
    .to(CreateInvestorGoalPauseTrackingReasonCommand)
    .inSingletonScope();
}
